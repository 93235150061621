import { Component, LOCALE_ID, ViewChild, Inject, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterOutlet, UrlSerializer } from '@angular/router';

import { environment } from '../environments/environment';

import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { FirebaseService } from './shared/services/firebase.service';
import { User } from './models/user';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import en from '@angular/common/locales/en';
// registerLocaleData(en);
registerLocaleData(localeFr);


// import firebase from 'firebase/compat/app';
// firebase.firestore.setLogLevel('debug');

import * as moment from 'moment-timezone';
import { Angulartics2, Angulartics2GoogleTagManager } from 'angulartics2';
import { UntypedFormGroup } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivityMonitorService } from './activity-monitor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],

  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.3s ease-out', style({ opacity: 0.4 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 0.4 }),
            animate('0.3s ease-in', style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class AppComponent {
  @ViewChild('sidenavRouter') sidenavRouter: RouterOutlet;
  hideTopbar: boolean = true;
  fullScreen: boolean = false;
  showBackground: boolean = false;
  title: string;
  currentUser$: Observable<User.Object>;
  formGroupTest: UntypedFormGroup;
  public userId$ = new BehaviorSubject<string>(null);
  public ticketId$ = new BehaviorSubject<string>(null);

  constructor(@Inject(LOCALE_ID) protected localeId: string,
    private fbs: FirebaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlSerializer: UrlSerializer,
    private angulartics2: Angulartics2,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,

  ) {
    this.angulartics2GoogleTagManager.startTracking();
    moment.locale(localeId);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      switchMap(route => route.data)
    ).subscribe((data) => {
      this.hideTopbar = data.hideTopbar || false;
      this.fullScreen = data.fullScreen || false;
      this.showBackground = data.showBackground || false;
      this.title = data.title || "The Assistant";
    });
    this.currentUser$ = this.fbs.currentUser();

    window.addEventListener('scroll', (e: any) => {
      if (e.target.classList?.contains("on-scrollbar") === false) {
        e.target.classList.add("on-scrollbar");
      }
    }, true);

    // textToCron

    // this.fbs.currentUserId().pipe(pairwise()).subscribe(([oldId, newId]) => {
    //   if (oldId && !newId) {
    //     this.fbs.
    //   }
    //   if (!newId && oldId) {

    //   }
    // });
    // Force token to refresh when the role changes
    combineLatest([this.fbs.currentUserCustomClaims$(), this.fbs.currentUserRole()]).subscribe(async ([r1, r2]) => {
      try {
        const compare = Object.keys(r2 || {}).some((key) => {
          const v1 = r1 && r1[key];
          // console.log('claims:', r1, 'role:', r2)
          const v2 = r2 && r2[key];
          if (v1 instanceof Array && v2 instanceof Array) {
            return v1.join() !== v2.join();
          } else {
            return (r1 && r1[key]) !== (r2 && r2[key])
          }
        });
        if (compare) {
          await this.fbs.refreshToken();
        }
      } catch (e) {
        console.error("Failed to compare roles", e);
      }
    });
    this.currentUser$.subscribe(async (u) => {
      if (u) {
        //Full Story
        if (environment.production) {
          this.angulartics2.setUsername.next(u.computed_id);
          this.angulartics2.eventTrack.next({
            action: 'isAuthentified',
            properties: {
              event: 'state',
              gtmCustom: {
                userId: u.computed_id,
                fullname: u.fullName,
                firstname: u.firstName,
                lastname: u.lastName,
                email: u.emails && u.emails[0]
              }
            }
          });
        }
      }
    });
  }

  sidebarCollapsed = true;
  sidebarDocked = this.navbarShouldBeDocked();
  sidebarForceOpened = false;
  private toggleSidebar() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  private navbarShouldBeDocked() {
    return !(window.innerWidth < 991)
  }

  ngAfterViewChecked() {
    this.hideSplashScreen();
  }

  @HostListener('window:resize', ['$event'])
  private _onResize() {
    if (this.navbarShouldBeDocked()) {
      !this.sidebarDocked && (this.sidebarDocked = true);
    } else {
      this.sidebarDocked && (this.sidebarDocked = false);
    }
  }

  @ViewChild('ticketModalRouter') ticketModalRouter: RouterOutlet;
  @ViewChild('userModalRouter') userModalRouter: RouterOutlet;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    if (this.userModalRouter?.isActivated) {
      this.closeUserModal();
    } else if (this.ticketModalRouter?.isActivated) {
      this.closeTicketModal();
    }
  }

  logout() {
    this.router.navigate(['/connect']);
  }

  private hideSplashScreen() {
    const splashScreen: HTMLElement = document.getElementById('app-splash-screen');
    if (splashScreen) {
      splashScreen.style.opacity = "0";
    }
  }

  public closeUserModal() {
    this.router.navigate([{ outlets: { 'user-modal': null } }]);
  }

  public closeTicketModal() {
    this.router.navigate([{ outlets: { 'ticket-modal': null } }]);
  }

  public navigateToTicket(ticketId: string) {
    this.router.navigateByUrl(`/ticket/${ticketId}`);
  }

  navigateTo(route: 'ticket' | 'user' | string) {
    const modalPath = route.concat('-', 'modal')
    const url = this.router.routerState.snapshot.url;
    const parsedUrl = this.urlSerializer.parse(url);
    const urlSegmentGroup = parsedUrl?.root?.children?.[modalPath] || parsedUrl?.root?.children?.primary?.children?.[modalPath];
    const navigationCommand = (urlSegmentGroup?.segments || []).map(group => {
      if (group?.path === modalPath) {
        return route;
      }
      return group?.path;
    });
    this.router.navigateByUrl((navigationCommand || []).join('/'));
  }

}
