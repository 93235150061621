import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusInputComponent } from './status-input/status-input.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';



@NgModule({
  declarations: [
    StatusInputComponent
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NzDropDownModule,
    NzIconModule,
    NzBadgeModule,
    NzTypographyModule,
    NzMenuModule,
    EmojiModule
  ],
  exports: [
    StatusInputComponent
  ]
})
export class ActivityMonitorModule { }
