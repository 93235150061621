import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from "../common";
import { PaymentDetails } from "./payment-details";

export namespace Payment {

  export enum METERED_PLAN_TYPE {
    DEFAULT = "DEFAULT",
    PACK = "PACK",
};

  export interface Common {
    clientId?: string,
    updatedAt?: allDateTypes,
    billedUserId?: string,
    ticketId?: string,
    stripe?: {
      stripeCustomerId?: string,
      /* Deprecated */
      stripeMburId?: string,
      stripeInvoiceId?: string,
    },
    quantity?: number,
    task?: { //This informations are provided from 01/01/2021
      id: string,
      efficiency?: number, //This naming will replace "rating" in the TimelineItemTaskValue model 
      duration?: number,
      performer?: { 
        id: string,
        efficiency?: number,
        hourlyRate?: number
      },
      owner?: {
        id: string
      }
    },
    details?: {
      [METERED_PLAN_TYPE.DEFAULT]?: PaymentDetails.Common,
      [METERED_PLAN_TYPE.PACK]?: PaymentDetails.Common,
    }
    billed?: allDateTypes|null
  }

  export interface DBObject extends Common {
    updatedAt?: dbDateTypes,
    stripe?: {
      [METERED_PLAN_TYPE.DEFAULT]?: PaymentDetails.DBObject,
      [METERED_PLAN_TYPE.PACK]?: PaymentDetails.DBObject,
      stripeCustomerId?: string,
      /* Deprecated */
      stripeMburId?: string,
      stripeInvoiceId?: string,
    },
    billed?: dbDateTypes|null
  }

  export interface Object extends Common {
    computed_id: string,
    stripe?: {
      [METERED_PLAN_TYPE.DEFAULT]?: PaymentDetails.Object,
      [METERED_PLAN_TYPE.PACK]?: PaymentDetails.Object,
      stripeCustomerId?: string,
      /* Deprecated */
      stripeMburId?: string,
      stripeInvoiceId?: string,
    },
    updatedAt?: Date,
    billed?: Date|null
  }

  export interface DBUpdate extends Common {
    updatedAt?: dbDateUpdateTypes,
    stripe?: {
      [METERED_PLAN_TYPE.DEFAULT]?: PaymentDetails.DBUpdate,
      [METERED_PLAN_TYPE.PACK]?: PaymentDetails.DBUpdate,
      stripeCustomerId?: string,
      /* Deprecated */
      stripeMburId?: string,
      stripeInvoiceId?: string,
    },
    billed?: dbDateUpdateTypes|null
  }

  export const helper = {
    toObject: (id: string, dbObject: DBObject): Object => {
      const object = {
        ...dbObject,
        stripe: {
          ...dbObject?.stripe,
          [METERED_PLAN_TYPE.DEFAULT]: dbObject?.stripe?.DEFAULT && PaymentDetails.helper.toObject(dbObject?.stripe?.DEFAULT),
          [METERED_PLAN_TYPE.PACK]: dbObject?.stripe?.PACK && PaymentDetails.helper.toObject(dbObject?.stripe?.PACK)
        },
        updatedAt: dbObject?.updatedAt?.toDate && dbObject?.updatedAt?.toDate(),
        billed: dbObject?.billed?.toDate && dbObject?.billed?.toDate(),
        computed_id: id,
      }
      return object
    },
  }
}