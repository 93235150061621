import { Procedure } from ".";

export const TEMPLATES_BY_PROCEDURE_CATEGORY_FR: Record<Procedure.PROCEDURE_CATEGORY, Procedure.EditorJSData> = {
  [Procedure.PROCEDURE_CATEGORY.ACCOUNTING]: {
    blocks: [
      {
          type: "header",
          data: {
              "text": "Précomptabilité",
              "level": 2
          }
      },
      {
        type: 'yesNo',
        data: {
          questionKey: 'send-acknowledgement-of-receipt-to-client-question',
          yesKey: 'send-acknowledgement-of-receipt-to-client-yes',
          noKey: 'send-acknowledgement-of-receipt-to-client-no',
        }
      },
      {
        type: "paragraph",
        data: {
            "text": "<div style=\"text-decoration: underline;\" class=\"underline-container\">Préférences particulières</div>"
        }
      },
      {
        type: "paragraph",
        data: {
            "text": ""
        }
      },
      {
          type: "paragraph",
          data: {
              "text": "<div style=\"text-decoration: underline;\" class=\"underline-container\">Récupération des factures</div>"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Les factures se trouvent à l'endroit suivant :"
          }
      },
      {
        type: "paragraph",
        data: {
            "text": ""
        }
      },
      {
          type: "paragraph",
          data: {
              "text": "Pour les récupérer, vous aurez besoin des identifiants suivants :"
          }
      },
      {
        type: "paragraph",
        data: {
            "text": ""
        }
      },
      {
          type: "paragraph",
          data: {
              "text": "<div style=\"text-decoration: underline;\" class=\"underline-container\">Enregistrement des factures</div>"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Si les factures doivent être modifiées au préalable, voici les étapes à suivre: (Sinon supprimez cette partie)"
          }
      },
      {
          type: "list",
          data: {
              "style": "ordered",
              "items": []
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Site pour enregistrer les factures/comptable à qui les envoyer :"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Et réaliser les étapes suivantes"
          }
      },
      {
          type: "list",
          data: {
              "style": "ordered",
              "items": []
          }
      },
      {
        type: 'yesNo',
        data: {
          questionKey: 'send-confirmation-to-client-question',
          yesKey: 'send-confirmation-to-client-yes',
          noKey: 'send-confirmation-to-client-no',
        }
      }
    ]
  },
  [Procedure.PROCEDURE_CATEGORY.TRAVEL]: {
    blocks: [
        {
            type: "header",
            data: {
                "text": "Déplacements professionnels",
                "level": 2
            }
        },
        {
          type: 'yesNo',
          data: {
            questionKey: 'send-acknowledgement-of-receipt-to-client-question',
            yesKey: 'send-acknowledgement-of-receipt-to-client-yes',
            noKey: 'send-acknowledgement-of-receipt-to-client-no',
          }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Préférences générales</span>"
            }
        },
        {
          type: 'yesNo',
          data: {
            questionKey: 'travel-first-class-question',
            yesKey: 'travel-first-class-yes',
            noKey: 'travel-first-class-no',
          }
        },
        {
            type: "paragraph",
            data: {
                "text": "Moyen de transport à privilégier : "
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Option remboursable/annulable : "
            }
        },
        {
          type: "paragraph",
          data: {
              "text": "Moyen de paiement à utiliser pour la réservation : "
          }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Train</span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Identifiants sur le site de la compagnie ferroviaire : "
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Préférences de siège : "
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Gare(s) de prédilection : "
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Avion</span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Identifiants sur le site de la compagnie aérienne : "
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Préférences de siège : "
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Aéroport(s) de prédilection : "
            }
        },
        {
          type: "paragraph",
          data: {
              "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Réservation d'hôtel</span>"
          }
        },
        {
          type: "paragraph",
          data: {
              "text": "Type d'hôtel à privilégier : "
          }
        },
        {
          type: "paragraph",
          data: {
              "text": "Identifiants sur les sites de réservation d'hôtel/appartement : "
          }
        },
        {
          type: 'yesNo',
          data: {
            questionKey: 'send-travel-taxi-question',
            yesKey: 'send-travel-taxi-yes',
            noKey: 'send-travel-taxi-no',
          }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Transmission d'information</span>"
            }
        },
        {
          type: 'yesNo',
          data: {
            questionKey: 'send-confirmation-before-travel-booking-question',
            yesKey: 'send-confirmation-before-travel-booking-yes',
            noKey: 'send-confirmation-before-travel-booking-no',
          }
        },
        {
          type: 'yesNo',
          data: {
            questionKey: 'book-travel-in-calendar-question',
            yesKey: 'book-travel-in-calendar-yes',
            noKey: 'book-travel-in-calendar-no',
          }
        },
        {
          type: 'yesNo',
          data: {
            questionKey: 'send-travel-tickets-by-email-question',
            yesKey: 'send-travel-tickets-by-email-yes',
            noKey: 'send-travel-tickets-by-email-no',
          }
        },
    ]
  },
  [Procedure.PROCEDURE_CATEGORY.BILLING_EDITION]: {
    blocks: [
      {
          type: "header",
          data: {
              "text": "Création de factures/devis",
              "level": 2
          }
      },
      {
          type: "yesNo",
          data: {
              "selectedValue": null,
              "questionKey": "send-acknowledgement-of-receipt-to-client-question",
              "noKey": "send-acknowledgement-of-receipt-to-client-no",
              "yesKey": "send-acknowledgement-of-receipt-to-client-yes"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Type de document à éditer :"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Préférences particulières</span><span style=\"text-decoration: underline;\" class=\"underline-container\"><br></span>"
          }
      },
      {
        type: "paragraph",
        data: {
            "text": ""
        }
      },
      {
          type: "paragraph",
          data: {
              "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Récupération des documents</span>"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Les informations sur les documents à éditer se trouvent à l'endroit suivant :"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Pour les récupérer, vous aurez besoin des identifiants suivants :"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Gabarit à respecter</span>"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Gabarits à appliquer lors de la création du document :"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Pour les récupérer, vous aurez besoin des identifiants suivants :"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Édition de documents</span>"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Pour chaque document, procéder comme suit :"
          }
      },
      {
          type: "list",
          data: {
              "style": "ordered",
              "items": []
          }
      },
      {
        type: "yesNo",
        data: {
            "selectedValue": null,
            "questionKey": "billing-edition-post-edition-confirmation-question",
            "noKey": "billing-edition-post-edition-confirmation-no",
            "yesKey": "billing-edition-post-edition-confirmation-yes"
        }
      },
      {
          type: "paragraph",
          data: {
              "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Enregistrement des documents</span>"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Site pour enregistrer les documents/comptable à qui les envoyer :"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Relance</span>"
          }
      },
      {
          type: "paragraph",
          data: {
              "text": "Relancer les clients après envoi du document sous les conditions suivantes :"
          }
      },
      {
          type: "list",
          data: {
              "style": "unordered",
              "items": []
          }
      },
      {
          type: "yesNo",
          data: {
              "selectedValue": null,
              "questionKey": "send-confirmation-to-client-question",
              "noKey": "send-confirmation-to-client-no",
              "yesKey": "send-confirmation-to-client-yes"
          }
      }
  ]
  },
  [Procedure.PROCEDURE_CATEGORY.BILLING_PAYMENT]: {
    blocks: [
        {
            type: "header",
            data: {
                "text": "Règlement de factures",
                "level": 2
            }
        },
        {
            type: "yesNo",
            data: {
                "selectedValue": null,
                "questionKey": "send-acknowledgement-of-receipt-to-client-question",
                "noKey": "send-acknowledgement-of-receipt-to-client-no",
                "yesKey": "send-acknowledgement-of-receipt-to-client-yes"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Préférences particulières</span><span style=\"text-decoration: underline;\" class=\"underline-container\"><br></span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": ""
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Récupération des factures</span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Les factures à régler se trouvent à l'endroit suivant :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Pour les récupérer, vous aurez besoin des identifiants suivants :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Virements</span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Application bancaire et identifiants :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Étapes pour effectuer le virement :"
            }
        },
        {
            type: "list",
            data: {
                "style": "ordered",
                "items": []
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Informations à ajouter sur le virement :"
            }
        },
        {
            type: "list",
            data: {
                "style": "unordered",
                "items": []
            }
        },
        {
            type: "yesNo",
            data: {
                "selectedValue": null,
                "questionKey": "attach-invoice-to-transfer-question",
                "noKey": "attach-invoice-to-transfer-no",
                "yesKey": "attach-invoice-to-transfer-yes"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Enregistrement des factures</span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Site pour enregistrer les factures/comptable à qui les envoyer :"
            }
        },
        {
            type: "yesNo",
            data: {
                "selectedValue": null,
                "questionKey": "send-confirmation-to-client-question",
                "noKey": "send-confirmation-to-client-no",
                "yesKey": "send-confirmation-to-client-yes"
            }
        }
    ]
  },
  [Procedure.PROCEDURE_CATEGORY.HUMAN_RESOURCES]: {
    blocks: [
        {
            type: "header",
            data: {
                "text": "Procédure RH",
                "level": 2
            }
        },
        {
            type: "yesNo",
            data: {
                "selectedValue": null,
                "questionKey": "send-acknowledgement-of-receipt-to-client-question",
                "noKey": "send-acknowledgement-of-receipt-to-client-no",
                "yesKey": "send-acknowledgement-of-receipt-to-client-yes"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Préférences particulières</span><span style=\"text-decoration: underline;\" class=\"underline-container\"><br></span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": ""
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Gestion des absences</span><span style=\"text-decoration: underline;\" class=\"underline-container\"><br></span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Les informations sur les absences se trouvent à l'endroit suivant :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Pour les récupérer, vous aurez besoin des identifiants suivants :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Étapes à suivre pour la gestion des absences:"
            }
        },
        {
            type: "list",
            data: {
                "style": "ordered",
                "items": []
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Remboursement de frais professionnels</span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Les informations sur les remboursements de frais se trouvent à l'endroit suivant :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Pour les récupérer, vous aurez besoin des identifiants suivants :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Étapes à suivre pour le remboursement des frais:"
            }
        },
        {
            type: "list",
            data: {
                "style": "ordered",
                "items": []
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Versements des indémnités et primes</span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Les informations sur les indémnités et primes se trouvent à l'endroit suivant :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Pour les récupérer, vous aurez besoin des identifiants suivants :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Étapes à suivre pour le versement des indémnités et primes:"
            }
        },
        {
            type: "list",
            data: {
                "style": "ordered",
                "items": []
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Versements des salaires</span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Les informations sur les salaires se trouvent à l'endroit suivant :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Pour les récupérer, vous aurez besoin des identifiants suivants :"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Étapes à suivre pour le versement des salaires :"
            }
        },
        {
            type: "list",
            data: {
                "style": "ordered",
                "items": []
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Étapes à suivre pour la création des fiches de paies:"
            }
        },
        {
            type: "list",
            data: {
                "style": "ordered",
                "items": []
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Étapes de transmission des fiches de paies aux employés :"
            }
        },
        {
            type: "list",
            data: {
                "style": "ordered",
                "items": []
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "Où classer/À qui envoyer la totalité des fiches de paies :"
            }
        },
        {
            type: "yesNo",
            data: {
                "selectedValue": null,
                "questionKey": "send-confirmation-to-client-question",
                "noKey": "send-confirmation-to-client-no",
                "yesKey": "send-confirmation-to-client-yes"
            }
        }
    ]
  },
  [Procedure.PROCEDURE_CATEGORY.CUSTOM]: {
    blocks: [
        {
            type: "header",
            data: {
                "text": "Procédure",
                "level": 2
            }
        },
        {
            type: "yesNo",
            data: {
                "selectedValue": null,
                "questionKey": "send-acknowledgement-of-receipt-to-client-question",
                "noKey": "send-acknowledgement-of-receipt-to-client-no",
                "yesKey": "send-acknowledgement-of-receipt-to-client-yes"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Préférences particulières</span><span style=\"text-decoration: underline;\" class=\"underline-container\"><br></span>"
            }
        },
        {
            type: "paragraph",
            data: {
                "text": ""
            }
        },
        {
            type: "paragraph",
            data: {
                "text": "<span style=\"text-decoration: underline;\" class=\"underline-container\">Étapes à suivre pour l'éxecution de la procédure</span><span style=\"text-decoration: underline;\" class=\"underline-container\"><br></span>"
            }
        },
        {
            type: "list",
            data: {
                "style": "ordered",
                "items": []
            }
        },
        {
            type: "yesNo",
            data: {
                "selectedValue": null,
                "questionKey": "send-confirmation-to-client-question",
                "noKey": "send-confirmation-to-client-no",
                "yesKey": "send-confirmation-to-client-yes"
            }
        }
    ]
  },
}