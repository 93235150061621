import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from "../common"
import { UserAccount } from "../user"

export namespace RawDeltaItem {
    export interface Common {
        createdAt?: allDateTypes,
        proceedAt?: allDateTypes,
        provider?: UserAccount.THIRD_PARTY_PROVIDER,
        value?: any,
        userAccountUid?: string,
        status?: any
    }

    export interface DBObject extends Common {
        createdAt?: dbDateTypes,
        proceedAt?: dbDateTypes,
    }

    export interface Object extends Common {
        computed_id?: string,
        createdAt?: Date,
        proceedAt?: Date,
    }

    export interface DBUpdate extends Common {
        createdAt?: dbDateUpdateTypes,
        proceedAt?: dbDateUpdateTypes,
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...dbObject,
                createdAt: dbObject.createdAt?.toDate(),
                proceedAt: dbObject.proceedAt?.toDate(),
                computed_id: id,
            }
        }
    }
}