import { dbDateUpdateTypes, dbDateTypes, allDateTypes } from "../../common"
import * as FirebaseFirestore from '@firebase/firestore-types';

export namespace UserAccount {

    export interface Common {
        addedAt?: allDateTypes,
        updatedAt?: allDateTypes,
        category?: USER_ACCOUNT_CATEGORY,
        provider?: THIRD_PARTY_PROVIDER,
        emailAddress?: string,
        data?: {
            account_id?: string,
            email_address?: string,
            access_token?: string,
        } | any,
        scopes?: string[],
        uid?: string,
        isPrimary?: boolean,
        _needToUpdate?: boolean
    }

    export interface DBObject extends Common {
        addedAt?: dbDateTypes,
        updatedAt?: dbDateTypes
    }

    export interface Object extends Common {
        computed_id?: string,
        addedAt?: Date,
        updatedAt?: Date,
        computed_userId?: string,
        computed_ref?: FirebaseFirestore.DocumentReference<DBObject>
    }

    export interface DBUpdate extends Common {
        addedAt?: dbDateUpdateTypes,
        updatedAt?: dbDateUpdateTypes
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject, userId: string, computedRef: FirebaseFirestore.DocumentReference<DBObject>): Object => {
            const object: Object = {
                ...dbObject,
                addedAt: dbObject?.addedAt?.toDate && dbObject?.addedAt?.toDate(),
                updatedAt: dbObject?.updatedAt?.toDate && dbObject?.updatedAt?.toDate(),
                computed_id: id,
                computed_ref: computedRef,
                computed_userId: userId
            };
            return object;
        }
    }

    export enum USER_ACCOUNT_CATEGORY {
        WHITE_LABEL_EMAIL = 'WHITE_LABEL_EMAIL',
        SWITCHBOARD = 'SWITCHBOARD',
        IN_APP_NOTIFICATION = 'IN_APP_NOTIFICATION',
        PAYMENT = 'PAYMENT',
        CUSTOM_NOTIFICATION = 'CUSTOM_NOTIFICATION',
        CALENDAR = 'CALENDAR',
        IN_APP = 'IN_APP',
        API = 'API'
    }

    export enum THIRD_PARTY_PROVIDER {
        NYLAS = "nylas",
        NYLAS_EU = "nylas-eu",
        RINGOVER = "ringover",
        SLACK = "slack",
        FIREBASE_CLOUD_MESSAGING = "firebaseCloudMessaging",
        STRIPE = "stripe",
        CRONOFY = "cronofy",
        GMAIL = "gmail",
        OUTLOOK = "outlook",
        IMAP = "imap",
        MAILGUN = "mailgun",
        IN_APP = "inApp",
        API = "api"
    }
}