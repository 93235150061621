import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from "../../../common"

export namespace Operation {

    export interface Common {
        addedAt?: allDateTypes,
        type?: OPERATION_TYPE,
        uid?: string,
        ticketId?: string,
        category?: OPERATION_CATEGORY, 
        description?: string,
    }

    export interface DBObject extends Common {
        addedAt?: dbDateTypes,
    }

    export interface Object extends Common {
        addedAt?: Date,
        computed_id: string
    }

    export interface DBUpdate extends Common {
        addedAt?: dbDateUpdateTypes,
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...dbObject,
                computed_id: id,
                addedAt: dbObject?.addedAt?.toDate && dbObject?.addedAt?.toDate(),
            }
        },
    }

    export enum OPERATION_CATEGORY {
        VAULT= 'VAULT',
        TIMELINE_ITEM = 'TIMELINE_ITEM',
        MESSAGE = 'MESSAGE'
    }

    export enum OPERATION_TYPE {
        //Vault
        CREATE = "CREATE",
        READ = "READ",
        UPDATE = "UPDATE",
        DELETE = "DELETE",
        //Timeline Item
        APPROVED = "APPROVED",
        DECLINED = "DECLINED",
        TO_VALIDATE = "TO_VALIDATE",
        REJECTED = "REJECTED",
        CANCELED = "CANCELED",
        //Message
        FORKED = "FORKED"
    };
}