import { Component } from '@angular/core';

@Component({
  selector: 'app-status-input',
  templateUrl: './status-input.component.html',
  styleUrls: ['./status-input.component.scss']
})
export class StatusInputComponent {
  public status : 'active'|'away'|'mute' = 'away';
}
