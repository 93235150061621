import { allDateTypes, dbDateTypes } from "../common";
import { TimelineItemTask } from "../ticket";
import { Template } from "../template";

export namespace Service {

  export interface Common {
    name?: string,
    isLive?: boolean,
    isExportable?: boolean,
    displayInRequests?: boolean,
    desc?: string,
    synonyms?: string,
    procedure?: Procedure,
    icon?: string,
    action?: string
    createdAt?: allDateTypes;
    updatedAt?: allDateTypes;
  }

  export interface DBObject extends Common {
    createdAt?: dbDateTypes;
    updatedAt?: dbDateTypes;
  }
  
  export interface Object extends Common {
    computed_id?: string,
    createdAt?: Date;
    updatedAt?: Date;
  }

  export const helper = {
    toObject: (id: string, dbObject: DBObject): Object => {
        return {
            ...dbObject,
            createdAt: dbObject?.createdAt?.toDate && dbObject?.createdAt?.toDate(),
            updatedAt: dbObject?.updatedAt?.toDate && dbObject?.updatedAt?.toDate(),
            computed_id: id,
        }
    },
  }

  export interface Procedure {
      desc?: string,
      vaultItems?: string[],
      services?: string[],
      tasks?: {
          task: TimelineItemTask.DBObject,
          template?: Template.DBObject,
          multiple: boolean,
      }[]
  }
}
