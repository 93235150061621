import { TimelineItemCommon } from "../common"
import { TimelineItemTaskValue } from "./value"
export { TimelineItemTaskValue } from "./value"

export namespace TimelineItemTask {

    export interface Common extends TimelineItemCommon.Common {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.TASK,
        value?: TimelineItemTaskValue.Common,
    }

    export interface DBObject extends TimelineItemCommon.DBObject {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.TASK,
        value?: TimelineItemTaskValue.DBObject,
    }

    export interface Object extends TimelineItemCommon.Object {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.TASK,
        value?: TimelineItemTaskValue.Object,
    }

    export interface DBUpdate extends TimelineItemCommon.DBUpdate {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.TASK,
        value?: TimelineItemTaskValue.DBUpdate,
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...TimelineItemCommon.helper.__toObject(id, dbObject),
                type: TimelineItemCommon.TIMELINE_ITEM_TYPE.TASK,
                value: dbObject?.value && TimelineItemTaskValue.helper.toObject(dbObject?.value)
            }
        }
    }

    export enum TICKET_TASK_TYPE {
      CLOSED = "CLOSED",
      OPEN = "OPEN",
      /** Waiting for a client/someone else to reply */
      PENDING = "PENDING",
      SNOOZED = "SNOOZED",
      ARCHIVED = "ARCHIVED",
      HELP_NEEDED = "HELP_NEEDED",
      CUSTOM = "CUSTOM",
      PAYMENT = "PAYMENT",
    };
}