<nz-input-group nzSize="large" [nzPrefix]="prefixIcon" [nzSuffix]="suffixTemplate" nzSearch>
    <input #input i18n-placeholder="@@shared-search" placeholder="Search" nz-input [ngModel]="query$.value"
        (ngModelChange)="query$.next($event)" [nzAutocomplete]="auto" />
</nz-input-group>
<ng-template #suffixTemplate>
    <i nz-icon nzType="close-circle" nzTheme="fill" class="clear-button" *ngIf="query$.value"
        (click)="query$.next('')"></i>
</ng-template>
<ng-template #prefixIcon>
    <i (click)="input.focus()" nz-icon nzType="search"></i>
</ng-template>
<nz-autocomplete #auto>
    <nz-auto-optgroup *ngFor="let group of optionGroups$ | async" [nzLabel]="groupTitle">
        <ng-template #groupTitle>
            <span>
                <span [ngSwitch]="group.title">
                    <span *ngSwitchCase="'users'" i18n="@@shared-users">Users</span>
                    <span *ngSwitchCase="'templates'" i18n="@@shared-templates">Templates</span>
                    <span *ngSwitchCase="'tickets'" i18n="@@shared-tickets">Tickets</span>
                    <span *ngSwitchCase="'procedures'" i18n="@@shared-procedures">Procedures</span>
                    <span *ngSwitchCase="'vault'" i18n="@@shared-vault">Vault</span>
                </span>
                <a class="more-link" nz-button nzSize="small" nzType="text" (click)="goto(group, $event)">
                    <i nz-icon nzType="search" nzTheme="outline"></i>
                    <span i18n="@@searchbar-fullsearch">Full seach</span>
                </a>
            </span>
        </ng-template>
        <nz-auto-option (click)="goto(option, $event)" *ngFor="let option of group.children" [nzValue]="query$.value"
            [nzValue]="query$.value">
            <div nz-row>
                <div nz-col nzXs="24" nzMd="12">
                    {{ option?.title }}
                </div>
                <div nz-col nzXs="24" nzMd="12">
                    <span class="search-item-desc">{{ option?.desc }}</span>
                </div>
            </div>
        </nz-auto-option>
        <nz-auto-option (click)="goto(group, $event)" *ngIf="!group.children?.length && query$.value">
            <span>😔 </span>
            <span class="no-result-placeholder" i18n="@@searchbar-noresults">No results, click here for full search</span>
        </nz-auto-option>
    </nz-auto-optgroup>
</nz-autocomplete>