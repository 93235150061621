import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from "../common";

export namespace Team {
    export interface Common {
        createdAt?: allDateTypes,
        name?: string,
        desc?: string,
        members?: { [userId: string]: TEAM_RIGHT },
        memberRequestRight?: { [userId: string]: TEAM_REQUEST_RIGHT | string },
        memberFeatureRights?: { [userId: string]: TEAM_FEATURE_RIGHT[] }
    }

    export interface DBObject extends Common {
        createdAt?: dbDateTypes
    }

    export interface Object extends Common {
        computed_id: string,
        createdAt?: Date,
    }

    export interface DBUpdate extends Common {
        createdAt?: dbDateUpdateTypes,
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...dbObject,
                computed_id: id,
                createdAt: dbObject?.createdAt?.toDate && dbObject?.createdAt?.toDate()
            }
        },
        getTeamOwner(team: Common) {
            const members = helper.getTeamMemberIds(team);
            return members.find(k => team.members && team.members[k] === TEAM_RIGHT.OWNER);
        },
        getTeamAdmins(team: Common) {
            const members = helper.getTeamMemberIds(team);
            return members.filter(k => team.members && team.members[k] === TEAM_RIGHT.ADMIN);
        },
        getTeamMemberIds(team: Common) {
            return team.members && Object.keys(team.members) || [];
        }
    }

    export enum TEAM_RIGHT {
        OWNER = 0,
        ADMIN = 1,
        MEMBER = 2,
    }

    export enum TEAM_REQUEST_RIGHT {
        ALL = 0,
        PRO = 1,
        PERSO = 2,
        NONE = 3
    }

    export enum TEAM_FEATURE_RIGHT {
        ALL = 'ALL',
        EDIT_ASSISTANT = 'EDIT_ASSISTANT',
        ADD_CALENDAR = 'ADD_CALENDAR',
        NONE = "NONE"
    }
}