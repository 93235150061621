import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './auth/auth-guard.service';
import { IsAgentOrAdminGuardService } from './main-guard.service';

const fullScreenData = {
  hideTopbar: true,
  hideSidenav: true,
  fullScreen: true,
  showBackground: true
};

const routes: Routes = [
  { path: '', redirectTo: '/tickets/', pathMatch: 'full' },
  {
    path: '', canActivate: [AuthGuardService, IsAgentOrAdminGuardService], children: [
      { path: '', outlet: 'sidenav', loadChildren: () => import('./ticket-navbar/ticket-navbar.module').then(m => m.TicketNavbarModule) },
      { path: 'tickets', loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule) },
      { path: 'ticket-list', loadChildren: () => import('./ticket-list/ticket-list.module').then(m => m.TicketListModule) },
      { path: 'ticket', loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule) },
      { path: 'templates', loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule) },
      { path: 'vault', loadChildren: () => import('./vault/vault.module').then(m => m.VaultModule) },
      { path: 'procedures', loadChildren: () => import('./procedures/procedures.module').then(m => m.ProceduresModule) },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
      { path: 'control-panel', loadChildren: () => import('./control-panel/control-panel.module').then(m => m.ControlPanelModule) },
    ]
  },
  { path: 'user-modal', canActivate: [AuthGuardService], loadChildren: () => import('./user/user.module').then(m => m.UserModule), outlet: 'user-modal' },
  { path: 'ticket-modal', canActivate: [AuthGuardService], loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule), outlet: 'ticket-modal' },
  { path: 'client', loadChildren: () => import('./client/client.module').then(m => m.ClientModule) },
  { path: 'file', loadChildren: () => import('./file/file.module').then(m => m.FileModule) },
  { path: 'connect', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: '404', data: fullScreenData, loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes /*, {enableTracing: true}*/, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
