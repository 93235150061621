export const environment = {
  production: false,
  externalUrlBase: "http://localhost:4200",
  internalUrlBase: "http://localhost:4200",
  firebaseCloudFunctionURL: "https://us-central1-the-assistant-dev.cloudfunctions.net",
  emailDomain: 'dev.theassistant.com',
  phoneDomain: 'phone.dev.theassistant.com',
  url: 'http://localhost:4200',
  assistant: {
    phone: '+33644644438',
    hiddenPhone: '+33 (6) 64 46 ** **',
    firstName: 'Alex',
    email: 'alex',
    title: 'Personal assistant',
    organization: 'The Assistant',
    vcardBaseUrl: "https://us-central1-assistant-email.cloudfunctions.net/getVCard?user=",
    defautAvatar: "https://firebasestorage.googleapis.com/v0/b/assistant-email.appspot.com/o/assets%2Favatars%2Favatar-signature.jpg?alt=media&token=37aff43c-c0d2-4ec1-a309-9b3de5d41da9"
  },
  agentEmail: 'bertrand@team.theassistant.com',
  assistantReviewEmail: 'assistant@team.theassistant.com',
  ticketStatusURL: "/client/ticket-status/",
  ticketURL: "/ticket/"
};