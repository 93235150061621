import { TimelineItemCommon } from '../common';
import { TimelineItemCommentValue } from './value';
export { TimelineItemCommentValue } from './value';

export namespace TimelineItemComment {

    export interface Common extends TimelineItemCommon.Common {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.COMMENT,
        value?: TimelineItemCommentValue.Common,
    }

    export interface DBObject extends TimelineItemCommon.DBObject {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.COMMENT,
        value?: TimelineItemCommentValue.DBObject,
    }

    export interface Object extends TimelineItemCommon.Object {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.COMMENT,
        value?: TimelineItemCommentValue.Object,
    }

    export interface DBUpdate extends TimelineItemCommon.DBUpdate {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.COMMENT,
        value?: TimelineItemCommentValue.DBUpdate,
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...TimelineItemCommon.helper.__toObject(id, dbObject),
                type: TimelineItemCommon.TIMELINE_ITEM_TYPE.COMMENT,
                value: dbObject?.value
            }
        }
    }
}