
export namespace UserPermissions {

  export enum USER_PERMISSIONS {
    /** Can access search for all elements (top bar) */
    SEARCH_ACCESS_ALL = "searchAccessAll",
    /** Can access search for tickets (side bar on tickets) */
    SEARCH_ACCESS_TICKET = "searchAccessTicket",
    /** Can access search for users (side bar on users) */
    SEARCH_ACCESS_USER = "searchAccessUser",
    /** Can access inbox page*/
    INBOX_ACCESS = "inboxAccess",
    /** Can access review page*/
    REVIEW_ACCESS = "reviewAccess",
    /** Can read all tickets not attributed in the inbox */
    INBOX_ACCESS_ALL = "inboxAccessAll",
    /** Can access to procedure page */
    PROCEDURES_ACCESS = "proceduresAccess",
    /** Can access to vault page */
    VAULT_ACCESS = "vaultAccess",
    /** Can access to template page */
    TEMPLATES_ACCESS = "templatesAccess",
    /** Can access to control Panel */
    CONTROL_PANEL_ACCESS = "controlPanelAccess",

    //TICKET
    /** Can access to its own tickets */
    TICKET_ACCESS_ATTRIBUTED_TO = "ticketAccessAttributedTo",
    /** Can access to tickets previous requests of a client, if an ongoing request of this client is assigned */
    TICKET_ACCESS_CLIENT_ATTRIBUTED_TO = "ticketAccessClientAttributedTo",
    /** Can update ticket fields*/
    TICKET_EDIT_FIELDS = "ticketEditFields",
    /** Can access header timeline extra*/
    TICKET_TIMELINE_EXTRA = "ticketTimelineExtra",
    /** Can update ticket vaultItems */
    TICKET_EDIT_VAULT_ITEMS = "ticketEditVaultItems",
    /** Can update ticket procedures */
    TICKET_EDIT_PROCEDURES = "ticketEditProcedures",
    /** Can reead ticket vaultItems */
    TICKET_ACCESS_VAULT_ITEMS = "ticketAccessVaultItems",
    /** Can read ticket procedures */
    TICKET_ACCESS_PROCEDURES = "ticketAccessProcedures",
    /** Can close a ticket */
    TICKET_CLOSE = "ticketClose",
    /** Can archive a ticket */
    TICKET_ARCHIVE = "ticketArchive",
    /** [Not used] Can set a ticket as snoozed or pending */
    TICKET_SNOOZE_OR_PENDING = "ticketSnoozeOrPending",
    /** Can delete tasks or comment */
    TICKET_DELETE_TASK_OR_COMMENT = "ticketDeleteTaskOrComment",
    /** Can edit a task duration */
    TICKET_EDIT_TASK_DURATION = "ticketEditTaskDuration",
    /** Can edit a task rating */
    TICKET_EDIT_TASK_RATING = "ticketEditTaskRating",
    /** Can read the total amount of actions of the ticket */
    TICKET_ACCESS_TOTAL_ACTIONS = "ticketAccessTotalActions",
    /** Can fork or hide a message */
    TICKET_FORK_OR_HIDE_MESSAGE = "ticketForkOrHideMessage",
    /** Can create dedicated ticket email */
    TICKET_CREATE_DEDICATED_EMAIL = "ticketCreateDedicatedEmail",
    /** Can edit ticket category */
    TICKET_EDIT_CATEGORY = "ticketEditCategory",
    /** Can set ticket category as internal */
    TICKET_SET_CATEGORY_AS_INTERNAL = "ticketSetCategoryAsInternal",
    /** Can send messages to customers */
    TICKET_SEND_MESSAGE = "ticketSendMessage",
    /** Can access to any ticket */
    TICKET_ACCESS_ALL = "ticketAccessAll",
    /** Can access to ticket status */
    TICKET_STATUS_ACCESS = "ticketStatusAccess",

    //USER
    /** Can access to any user profiles */
    USER_ACCESS_OTHER_PROFILE = "userAccessOtherProfile",
    /** Can access to it's own profile */
    USER_ACCESS_OWN_PROFILE = "userAccessOwnProfile",
    /** Can update user profile */
    USER_EDIT_PROFILE = "userEditProfile",
    /** Can edit user assistant */
    USER_EDIT_ASSISTANT = "userEditAssistant",
    /** Can edit user custom assistant (white label email & signature)*/
    USER_EDIT_CUSTOM_ASSISTANT = "userEditCustomAssistant",
    /** Can edit client or partner groups*/
    USER_EDIT_GROUPS = "userEditGroups",
    /** Can edit user roles */
    USER_EDIT_ROLES = "userEditRoles",
    /** Can access user calendar */
    USER_ACCESS_CALENDAR = "userAccessCalendar",
    /** Can access to user sider*/
    USER_ACCESS_SIDER = "userAccessSider",
    /** Can access to subscription page*/
    USER_ACCESS_SUBSCRIPTION = "userAccessSubscription",
    /** Can access to invoices page*/
    USER_ACCESS_INVOICES = "userAccessInvoices",
    /** Can access to vault page*/
    USER_ACCESS_VAULT = "userAccessVault",
    /** Can access to user schedule page*/
    USER_ACCESS_SCHEDULES = "userAccessSchedules",
    /** Can access to user general page*/
    USER_ACCESS_GENERAL = "userAccessGeneral",
    /** Can access to user internal note*/
    USER_ACCESS_INTERNAL_NOTE = "userAccessInternalNote",
    /** Can read user previous and upcoming invoices*/
    USER_ACCESS_INVOICES_REPORT = "userAccessInvoicesReport",
    /** Can access to user payment methods*/
    USER_ACCESS_PAYMENT_METHODS = "userAccessPaymentMethods",
    /** Can add a Sepa payment Method*/
    USER_ADD_SEPA_PAYMENT_METHOD = "userAddSepaPaymentMethod",
    /** Can access to user requests page*/
    USER_ACCESS_REQUESTS = "userAccessRequests",
    /** Can access to user procedures page*/
    USER_ACCESS_PROCEDURES = "userAccessProcedures",
    /** Can access to user team page*/
    USER_ACCESS_TEAM = "userAccessTeam",
    /** Can access add calendar */
    USER_ADD_CALENDAR = "userAddCalendar",
    /** Can access to stripe dashboard */
    USER_ACCESS_STRIPE_DASHBOARD = "userAccessStripeDashboard",
    /** Can contact PM */
    USER_ACCESS_PROJECT_MANAGER = "userAccessProjectManager",
    /** Can add user from users page */
    USER_ADD = "userAdd",
    /** Can access to user api page*/
    USER_ACCESS_API = "userAccessApi"
  };

  export type Common = {
    [key in USER_PERMISSIONS]?: boolean
  };

  export interface DBObject extends Common {
  }

  export interface Object extends Common {
  }

  export interface DBUpdate extends Common {
  }

  export const helper = {
    toObject: (dbObject: DBObject): Object => {
      return {
        ...dbObject
      }
    }
  }


  export const ROLE_PERMISSIONS: { [key: string]: USER_PERMISSIONS[] } = {
    isAuthentified: [] as USER_PERMISSIONS[],
    isAdmin: Object.values(USER_PERMISSIONS) as USER_PERMISSIONS[],
    isCustomer: [
      USER_PERMISSIONS.USER_ACCESS_OWN_PROFILE,
      USER_PERMISSIONS.USER_ACCESS_SUBSCRIPTION,
      USER_PERMISSIONS.USER_ACCESS_REQUESTS,
      USER_PERMISSIONS.USER_EDIT_ASSISTANT,
      USER_PERMISSIONS.USER_EDIT_PROFILE,
      USER_PERMISSIONS.USER_ACCESS_SIDER,
      USER_PERMISSIONS.USER_ACCESS_INVOICES,
      USER_PERMISSIONS.USER_ACCESS_VAULT,
      USER_PERMISSIONS.USER_ACCESS_GENERAL,
      USER_PERMISSIONS.USER_ACCESS_INVOICES_REPORT,
      USER_PERMISSIONS.USER_ACCESS_PAYMENT_METHODS,
      USER_PERMISSIONS.TICKET_STATUS_ACCESS,
    ],
    isTeamMember: [
      USER_PERMISSIONS.USER_ACCESS_OWN_PROFILE,
      USER_PERMISSIONS.USER_ACCESS_SIDER,
      USER_PERMISSIONS.USER_ACCESS_REQUESTS,
      USER_PERMISSIONS.USER_ACCESS_TEAM,
      USER_PERMISSIONS.USER_EDIT_PROFILE,
      USER_PERMISSIONS.USER_ACCESS_VAULT,
      USER_PERMISSIONS.USER_ACCESS_GENERAL,
      USER_PERMISSIONS.TICKET_STATUS_ACCESS,
      USER_PERMISSIONS.USER_ACCESS_PAYMENT_METHODS,
    ],
    isAgent: [
      USER_PERMISSIONS.INBOX_ACCESS,
      USER_PERMISSIONS.REVIEW_ACCESS,
      USER_PERMISSIONS.SEARCH_ACCESS_TICKET,
      USER_PERMISSIONS.TICKET_ACCESS_ATTRIBUTED_TO,
      USER_PERMISSIONS.TICKET_ACCESS_CLIENT_ATTRIBUTED_TO,
      USER_PERMISSIONS.TICKET_SNOOZE_OR_PENDING,
      USER_PERMISSIONS.TICKET_CREATE_DEDICATED_EMAIL,
      USER_PERMISSIONS.TICKET_ACCESS_VAULT_ITEMS,
      USER_PERMISSIONS.TICKET_ACCESS_PROCEDURES,
      USER_PERMISSIONS.TICKET_EDIT_VAULT_ITEMS,
      USER_PERMISSIONS.TICKET_EDIT_PROCEDURES,
      USER_PERMISSIONS.TICKET_FORK_OR_HIDE_MESSAGE,
      USER_PERMISSIONS.TICKET_TIMELINE_EXTRA,
      USER_PERMISSIONS.USER_ACCESS_INTERNAL_NOTE,
      USER_PERMISSIONS.USER_ACCESS_OTHER_PROFILE,
      USER_PERMISSIONS.USER_ACCESS_CALENDAR,
      USER_PERMISSIONS.USER_ACCESS_PAYMENT_METHODS,
    ]
  };
}