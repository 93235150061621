import { Injectable, OnDestroy } from '@angular/core';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { ObjectWithObjectID } from '@algolia/client-search';
import { Observable, ReplaySubject, Subscription, interval, from, of, BehaviorSubject } from 'rxjs';
import { startWith, map, switchMap, catchError, take } from 'rxjs/operators';

import { FirebaseService } from './firebase.service';
import { AlgoliaService as AlgoliaServiceModel, AlgoliaTemplate, AlgoliaTicket, AlgoliaUsers, AlgoliaProcedure } from 'src/app/models/third-parties/algolia';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';

const ALGOLIA_VALID_UTIL_SEC = 1000 * 60 * 60 * 60;

export const TICKETS_ALGOLIA_INDEX = "tickets";
export const USERS_ALGOLIA_INDEX = "users";
export const SERVICES_ALGOLIA_INDEX = "services";
export const TEMPLATES_ALGOLIA_INDEX = "templates";
export const VAULT_ALGOLIA_INDEX = "vault";
export const PROCEDURES_ALGOLIA_INDEX = "procedures";

export type AlgoliaUnifiedModel = AlgoliaServiceModel | AlgoliaTemplate | AlgoliaTicket | AlgoliaUsers;

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService implements OnDestroy {
  public readonly client$ = new ReplaySubject<SearchClient>(1);
  private subs: Subscription;

  ngOnDestroy() {
    this.subs && this.subs.unsubscribe();
  }

  constructor(private fbs: FirebaseService) {
    this.subs = interval(ALGOLIA_VALID_UTIL_SEC).pipe(
      startWith(0),
      switchMap(() => {
        return this.fbs.getHttpWithAuth('algolia-getKey');
      }),
      map((o: { app_id: string, key: string }) => {
        this.client$.next(algoliasearch(o.app_id, o.key));
      })
    ).subscribe();
  }

  private action<T>(func: (client: SearchClient) => Readonly<Promise<T>>): Observable<T> {
    return this.client$.pipe(
      switchMap((client) => from(func(client))),
      take(1),
      catchError(error => {
        console.error(error);
        return of({} as T);
      })
    );
  }

  search<T>(index: string, query: string, filter?: string, hitsPerPage?: number, page?: number) {
    return this.action((client) => client.initIndex(index).search<T>(query || '', {
      filters: filter || undefined,
      hitsPerPage: hitsPerPage || undefined,
      page: page || undefined
    }));
  }

  searchMultiple(query: string, indexes: string[] = [USERS_ALGOLIA_INDEX, TICKETS_ALGOLIA_INDEX, PROCEDURES_ALGOLIA_INDEX, TEMPLATES_ALGOLIA_INDEX, VAULT_ALGOLIA_INDEX], hitsPerPage?: number) {
    const queries = indexes.map(i => {
      const filters = (i === PROCEDURES_ALGOLIA_INDEX || i === VAULT_ALGOLIA_INDEX) && 'acl:SYSTEM' || ''
      return {
        indexName: i,
        query: query,
        filters: filters,
        params: {
          hitsPerPage: hitsPerPage || 5
        }
      }
    })
    return this.action((client) => client.multipleQueries<AlgoliaUnifiedModel>(queries));
  }

  get<T>(index: string, id: string | string[]) {
    if (typeof id == 'string') {
      return this.action<T & ObjectWithObjectID>((client) => client.initIndex(index).getObject(id));
    } else if (id instanceof Array) {
      return this.action<{ results: (T & ObjectWithObjectID)[] }>((client) => client.initIndex(index).getObjects(id));
    }
  }

  searchUsers(val: string) {
    return this.search<AlgoliaUsers>(USERS_ALGOLIA_INDEX, val);
  }

  getUser(id: string) {
    return this.get<AlgoliaUsers>(USERS_ALGOLIA_INDEX, id);
  }

  searchServices(val: string) {
    return this.search<AlgoliaServiceModel>(SERVICES_ALGOLIA_INDEX, val);
  }

  getService(id: string) {
    return this.get<AlgoliaServiceModel>(SERVICES_ALGOLIA_INDEX, id);
  }

  searchProcedures(val: string, filter?: string) {
    return this.search<AlgoliaProcedure>(PROCEDURES_ALGOLIA_INDEX, val, filter);
  }

  searchTemplates(val: string, filter?: string) {
    return this.search<AlgoliaTemplate>(TEMPLATES_ALGOLIA_INDEX, val, filter);
  }

  getTemplate(id: string) {
    return this.get<AlgoliaTemplate>(TEMPLATES_ALGOLIA_INDEX, id);
  }

  searchTickets(val: string, filter?: string) {
    return this.search<AlgoliaTicket>(TICKETS_ALGOLIA_INDEX, val, filter);
  }

  // searchTicketsByStatus(val: string, filter?: string): Observable<any> {
  //   return this.search('tickets_by_status', val, filter);
  // }

  getTicket(id: string | string[]) {
    return this.get<AlgoliaTicket>(TICKETS_ALGOLIA_INDEX, id);
  }
}

export class AlgoliaDataSource<T> extends DataSource<T> {
  private nbPages = 1;
  private cachedData: T[];
  private fetchedPages = new Set<number>();
  private dataStream = new BehaviorSubject<T[]>([]);
  private subscription = new Subscription();
  get loading() {
    return !this.cachedData;
  }

  constructor(private algoliaService: AlgoliaService, private index: string, private query: string, private hitsPerPage?: number, private filter?: string) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    this.subscription.add(
      collectionViewer.viewChange.subscribe(range => {
        const startPage = this.getPageForIndex(range.start);
        const endPage = this.getPageForIndex(range.end - 1);
        for (let i = startPage; i <= endPage; i++) {
          this.fetchPage(i);
        }
      })
    );
    this.fetchPage(0);
    return this.dataStream;
  }

  disconnect(): void {
    this.subscription.unsubscribe();
  }

  private getPageForIndex(index: number): number {
    const page = Math.floor(index / this.hitsPerPage);
    return Math.min(page, this.nbPages);
  }

  private fetchPage(page: number): void {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.fetchedPages.add(page);
    this.algoliaService.search<T>(this.index, this.query, this.filter, this.hitsPerPage, page).subscribe(
      res => {
        this.nbPages = res.nbPages
        if (!this.cachedData) {
          this.cachedData = Array.from<T>({ length: res.nbHits })
        }
        this.cachedData.splice(page * this.hitsPerPage, this.hitsPerPage, ...res.hits);
        this.dataStream.next(this.cachedData);
      }
    )
  }
}
