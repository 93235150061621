export * from './operation'
export * from './category/contact'; 
import { VaultItemApplicationData } from './category/application';
import { VaultItemContactData } from './category/contact';
import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from '../../common';
import { Attachment } from '../../message';
import * as FirebaseFirestore from '@firebase/firestore-types';
import { VaultItemFileData } from './file';

export namespace VaultItem {

    export interface Common {
        name?: string,
        icon?: string,
        metadata?: {
            urlDomain?: string;
        },
        desc?: string,
        synonyms?: string,
        updatedAt?: allDateTypes,
        category?: VAULT_ITEM_CATEGORY,
        shareWith?: string,
        data?: (VaultItemApplicationData|VaultItemContactData|VaultItemFileData) & BaseData
        _data?: string,
        isLive?: boolean,
        displayOnUserProfile?: boolean,
        /* Field that will be used to replace shareWith */
        acl?: string[],
        _needToUpdate?: boolean
    }

    export interface DBObject extends Common {
        updatedAt?: dbDateTypes,
    }

    export interface Object extends Common {
        updatedAt?: Date,
        computed_id: string;
        /* User or team which owns this vault item */
        computed_ownerId?: string;
        computed_isFilled?: boolean;
        /* Static vault item with conditional display */
        computed_permission?: string;
        computed_ref?: FirebaseFirestore.DocumentReference<DBObject>
    }

    export interface DBUpdate extends Common {
        updatedAt?: dbDateUpdateTypes,
    }

    export interface BaseData {
        attachments?: Attachment.Common[],
        note?: string,
    }    

    export const helper = {
        toObject: (id: string, dbObject: VaultItem.DBObject, computed?: { ownerId?: string, isFilled?: boolean, permission?: string, isStatic?: boolean, ref?: FirebaseFirestore.DocumentReference<VaultItem.DBObject> }): VaultItem.Object => {
            return {
                ...dbObject,
                updatedAt: dbObject?.updatedAt?.toDate && dbObject?.updatedAt?.toDate(),
                computed_id: id,
                computed_ref: computed?.ref,
                computed_ownerId: computed?.ownerId,
                computed_isFilled: computed?.isFilled,
                computed_permission: computed?.permission,
            }
        }
    }

    export enum VAULT_ITEM_CATEGORY {
        CALENDAR = "calendar",
        APPLICATION = "application",
        // ADMIN = "admin", // To delete
        // ADDRESS = "address", // To delete
        CONTACT = "contact",
        FILE = "file", // To delete
        OTHER = "other",
        // PROCEDURE = "procedure", // To delete
        PAYMENT_METHODS = "payment_method"
    };
    
    export enum VAULT_ITEM_CALENDAR_IDS {
        MY_CALENDARS = "my_calendars",
        SCHEDULING_HOURS = "scheduling_hours",
        DEFAULT_MEETING_DURATION = "default_meeting_duration",
        MEETING_BREATHER_TIME = "meeting_breather_time",
        CALENDAR_LINK = "calendar_link"
    };
    
    export enum VAULT_ITEM_GENERAL_IDS {
        PROFILE_INFO = "profile_info",
        ASSISTANT = "assistant",
        EMAILS = "emails",
        PHONES = "phones",
        PAYMENT_CARDS = "payment_cards",
        BILLING_DETAILS = "billing_details",
        ROLES = "roles",
        PERMISSIONS = "permissions",
        STRIPE_LINK = "stripe_link",
        RESET_PASSWORD = "reset_password",
        LOG_OUT = "log_out",
        NYLAS = "nylas",
        GMAIL = "gmail",
        OUTLOOK = 'outlook',
    };
    
    // export enum VAULT_ITEM_ADMIN_SUBCATEGORY {
    //     PASSPORT = "passport",
    //     ID_CARD = "id_card",
    //     BANK_ACCOUNT = "bank_account",
    //     DRIVING_LICENSE = "driving_license",
    //     OTHER = "other"
    // };
    
    export enum VAULT_ITEM_STATIC_KEYS {
        UPDATED_AT = "updatedAt",
        NAME = "name",
        CATEGORY = "category",
        SUBCATEGORY = "subcategory",
        COMPUTED_ID = "computed_id",
        IS_STATIC = "isStatic",
        DATA = "data"
    }
    
}



