
export namespace UserRoles {
    export interface Roles {
        [USER_ROLES.IS_ADMIN]?: boolean;
        [USER_ROLES.IS_AGENT]?: boolean;
        [USER_ROLES.IS_CUSTOMER]?: boolean;
        [USER_ROLES.IS_TEAM_MEMBER]?: boolean;
        [USER_ROLES.MANAGED_USERS]?: string[];
        [USER_ROLES.TEAMS]?: string[];
        [USER_ROLES.IS_ROBOT]?: boolean;
        [USER_ROLES.IS_AUTHENTIFIED]?: boolean;
    }
    export enum USER_ROLES {
        IS_ADMIN = 'isAdmin',
        IS_AGENT = 'isAgent',
        IS_CUSTOMER = 'isCustomer',
        IS_TEAM_MEMBER = 'isTeamMember',
        MANAGED_USERS = 'managedUsers',
        TEAMS = 'teams',
        IS_ROBOT = 'isRobot',
        IS_AUTHENTIFIED = 'isAuthentified'
    }
}