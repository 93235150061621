import { dbDateTypes, dbDateUpdateTypes } from "../../common";

export enum STRIPE_CALLBACK_TYPE {
    CUSTOMER_CREATED = 'CUSTOMER_CREATED',
    TAX_ID_CREATED = 'TAX_ID_CREATED',
    TAX_ID_CREATION_FAIL = 'TAX_ID_CREATION_FAIL',
    TAX_ID_INVALID = 'TAX_ID_INVALID',
    PAYMENT_METHOD_UPDATED = 'PAYMENT_METHOD_UPDATED',
    PAYMENT_METHOD_UPDATE_FAIL = 'PAYMENT_METHOD_UPDATE_FAIL',
    SUBSCRIPTION_CREATED = 'SUBSCRIPTION_CREATED',
    SUBSCRIPTION_CREATION_FAIL = 'SUBSCRIPTION_CREATION_FAIL'
}

export namespace StripeCredit {

    export interface Common {
        initialQuantity: Number,
        remainingQuantity: Number
    }

    export interface DBObject extends Common {
        addedAt?: dbDateTypes,
        expiresAt?: dbDateTypes
    }

    export interface Object extends Common {
        addedAt?: Date,
        expiresAt?: Date
    }

    export interface DBUpdate extends Common {
        addedAt?: dbDateUpdateTypes,
        expiresAt?: dbDateUpdateTypes
    }

    export const helper = {
        toObject: (dbObject: DBObject): Object => {
          return {
            ...dbObject,
            addedAt: dbObject?.addedAt?.toDate && dbObject?.addedAt?.toDate(),
            expiresAt: dbObject?.expiresAt?.toDate && dbObject?.expiresAt?.toDate()
          }
        },
      }

}