export { Encoder, CLOUD_STORAGE_FOLDER } from 'ta-models'

export const NEW_ITEM_ID = 'NEW_ITEM_ID';

export const CommonHelper = {
    dateTimeReviver: (key, value) => {
        let a;
        if (typeof value === 'string') {
            a = /\/Date\((\d*)\)\//.exec(value);
            if (a) {
                return new Date(+a[1]);
            }
        }
        return value;
    }
}