<nz-layout class="ta-layout">
    <nz-sider class="ta-sider" nzCollapsible [nzTrigger]="null" [nzCollapsed]="sidebarCollapsed"
        [nzCollapsedWidth]="sidenavRouter?.isActivated?64:0" nzTheme="light" [nzWidth]="300"
        (mouseenter)="!sidebarForceOpened && sidebarDocked && (sidebarCollapsed = false)"
        (mouseleave)="!sidebarForceOpened && sidebarDocked && (sidebarCollapsed = true)">
        <a [routerLink]="['/']">
            <img class="ta-sider-logo" src="assets/logo.svg" alt="logo">
        </a>
        <div class="ta-sider-content">
            <router-outlet #sidenavRouter="outlet" name="sidenav"></router-outlet>
        </div>
    </nz-sider>
    <nz-layout class="ta-layout">
        <nz-header *ngIf="!hideTopbar" class="ta-header">
            <div class="flex flex-row justify-between items-center space-x-2" *ngIf="currentUser$ | async; let c">
                <a [routerLink]="['/']">
                    <img [ngClass]="{hideSidenav: !sidenavRouter?.isActivated}" class="ta-sider-logo"
                        src="assets/logo.svg" alt="logo">
                </a>
                <button *ngxPermissionsOnly="'controlPanelAccess'" nz-button nzType="text"  [routerLink]="['/', 'control-panel']" ><span nz-icon nzType="control"></span><span >Control panel</span></button>
                <app-search-bar class="flex-1" *ngxPermissionsOnly="'searchAccessAll'"></app-search-bar>
                <app-user-label nz-dropdown [nzDropdownMenu]="menu" taSize="small" mode="avatar"
                    [userId]="c.computed_id"></app-user-label>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                        <!-- <li nz-menu-item>
                            <span nz-icon nzType="bulb" nzTheme="outline"></span>&nbsp;
                            <span i18n="@@app-share-feedback">Share feedback</span>
                        </li>
                        <li nz-menu-divider></li> -->
                        <li nz-menu-item [routerLink]="['/', 'connect']" routerLinkActive="active-link">
                            <span nz-icon nzType="logout" nzTheme="outline"></span>&nbsp;
                            <span i18n="@@shared-logout">Log out</span>
                        </li>
                    </ul>
                </nz-dropdown-menu>

            </div>
        </nz-header>
        <nz-content class="ta-content">
            <div *ngIf="showBackground" class="ta-content-background ta-line-background"></div>
            <nz-layout class="ta-router-outlet"
                [ngClass]="{'hideSidenav': !sidenavRouter?.isActivated, 'hideTopbar': hideTopbar}">
                <nz-content>
                    <router-outlet></router-outlet>
                </nz-content>
            </nz-layout>
        </nz-content>
    </nz-layout>
    <div class="ta-model-overlay" [@inOutAnimation] *ngIf="ticketModalRouter.isActivated" (click)="closeTicketModal()">
    </div>
    <div class="ta-model-overlay" [@inOutAnimation] *ngIf="userModalRouter.isActivated" (click)="closeUserModal()">
    </div>
    <nz-sider class="ta-modal-sider" nzWidth="90%" [nzCollapsed]="!ticketModalRouter.isActivated"
        [nzCollapsible]="false" [nzCollapsedWidth]="0">
        <div class="flex flex-col justify-start content-stretch h-full">
            <nz-page-header class="ta-sider-header" (nzBack)="closeTicketModal()" nzBackIcon>
                <nz-page-header-extra>
                    <button nz-button nzType="text" nzSize="small" (click)="navigateTo('ticket')">
                        <i nz-icon nzType="expand-alt" nzTheme="outline"></i>
                    </button>
                </nz-page-header-extra>
            </nz-page-header>
            <div class="flex-1" style="overflow: scroll;">
                <router-outlet #ticketModalRouter="outlet" name="ticket-modal"></router-outlet>
            </div>
        </div>
    </nz-sider>
    <nz-sider class="ta-modal-sider" nzWidth="85%" [nzCollapsed]="!userModalRouter.isActivated" [nzCollapsible]="false"
        [nzCollapsedWidth]="0">
        <div class="flex flex-col justify-start content-stretch h-full">
            <nz-page-header class="ta-sider-header" (nzBack)="closeUserModal()" nzBackIcon>
                <nz-page-header-extra>
                    <button nz-button nzType="text" nzSize="small" (click)="navigateTo('user')">
                        <i nz-icon nzType="expand-alt" nzTheme="outline"></i>
                    </button>
                </nz-page-header-extra>
            </nz-page-header>
            <div class="flex-1" style="overflow: scroll;">
                <router-outlet #userModalRouter="outlet" name="user-modal"></router-outlet>
            </div>
        </div>
    </nz-sider>
</nz-layout>
<app-version></app-version>