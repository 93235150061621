import { dbDateTypes, allDateTypes, dbDateUpdateTypes } from '../common';
import { UserRoles } from './roles';
import { UserPermissions } from './permissions';
import { UserAssistant } from './assistant';
import { getEnvironment, Encoder } from '../shared';
import { VaultItem } from './vault-item';
import { Calendar } from './preferences/calendar';
import { General } from './preferences/general';
import { Preferences } from './preferences';

export * from './preferences'
export * from './account'
export * from './analytics'
export * from './assistant'
export * from './permissions'
export * from './roles'
export * from './vault-item'
export * from './credit'
export * from './agent-params'

export namespace User {

  export interface Common {
    firstName?: string,
    lastName?: string,
    fullName?: string,
    birthdate?: allDateTypes,
    createdAt?: allDateTypes,
    emails?: string[],
    _emails?: { [key: string]: number },
    phones?: string[],
    _phones?: { [key: string]: number },
    gender?: string,
    address?: Address,
    company?: Company,
    jobTitle?: string,
    bio?: string,
    internalNote?: string,
    website?: string,
    avatar?: string,
    socials?: { [key: string]: string }
    clearbitIndexAt?: allDateTypes,
    _email?: { [key: string]: boolean },
    termsAccepted?: boolean,
    shortId?: string,
    params?: {
      rating?: number, //Will be deprecated and replaced by efficiency in agentParams subcollection
      managedClientIds?: string[]
    },
    roles?: UserRoles.Roles,
    permissions?: UserPermissions.Common,
    assistant?: UserAssistant.DBObject,
    projectManagerId?: string,
    accountManagerId?: string,
    partnerGroups?: string[],
    clientGroup?: string,
    timezone?: string,
    contactLink?: string
  }

  export interface DBObject extends Common {
    birthdate?: dbDateTypes,
    createdAt?: dbDateTypes,
    clearbitIndexAt?: dbDateTypes,
    permissions?: UserPermissions.Common,
    assistant?: UserAssistant.Common,
  }

  export interface Object extends Common {
    computed_id: string,
    computed_assistant?: UserAssistant.Object,
    computed_preferences?: {
      [Preferences.PREFERENCES_CATEGORY.CALENDAR]: Calendar.Preferences,
      [Preferences.PREFERENCES_CATEGORY.GENERAL]: General.Preferences
    },
    computed_vaultItems?: {
      [key: string]: VaultItem.Object,
    },
    computed_userAccountIds?: {
      [key: string]: string,
    },
    _hasPaymentMethod?: boolean,
    birthdate?: Date,
    createdAt?: Date,
    clearbitIndexAt?: Date,
    permissions?: UserPermissions.DBUpdate,
  }

  export interface DBUpdate extends Common {
    birthdate?: dbDateUpdateTypes,
    createdAt?: dbDateUpdateTypes,
    clearbitIndexAt?: dbDateUpdateTypes,
    assistant?: UserAssistant.DBUpdate
    permissions?: UserPermissions.Common,
  }

  export const helper = {
    toObject: (id: string, dbObject: DBObject, computed?: {
      assistant?: UserAssistant.Object,
      preferences?: {
        [Preferences.PREFERENCES_CATEGORY.CALENDAR]: Calendar.Preferences,
        [Preferences.PREFERENCES_CATEGORY.GENERAL]: General.Preferences
      },
      calendarPreferences?: Calendar.Preferences,
      generalPreferences?: General.Preferences,
      vaultItems?: { [key: string]: VaultItem.Object },
      userAccountIds?: { [key: string]: string },
      hasPaymentMethod?: boolean,
    }): Object => {
      return {
        ...dbObject,
        computed_id: id,
        createdAt: dbObject?.createdAt?.toDate && dbObject?.createdAt?.toDate(),
        birthdate: dbObject?.birthdate?.toDate && dbObject?.birthdate?.toDate(),
        clearbitIndexAt: dbObject?.clearbitIndexAt?.toDate && dbObject?.clearbitIndexAt?.toDate(),
        _hasPaymentMethod: !!computed?.hasPaymentMethod,
        computed_assistant: computed?.assistant,
        computed_preferences: computed?.preferences,
        computed_vaultItems: computed?.vaultItems,
        computed_userAccountIds: computed?.userAccountIds
      }
    },
    toPublicProfile: (object: Object): Partial<Object> => {
      const publicProfile: { firstName?: string, lastName?: string, avatar?: string, emails?: string[] } = {};
      if (object?.firstName) publicProfile.firstName = object?.firstName;
      if (object?.lastName) publicProfile.lastName = object?.lastName;
      if (object?.avatar) publicProfile.avatar = object?.avatar;
      if (object?.emails) publicProfile.emails = object?.emails;
      return publicProfile;
    },
    isRobotEmail: (email: string, prod: boolean) => {
      const emailDomain = getEnvironment(prod).emailDomain;
      const domain = email.replace(/.*@/, '');
      return domain === emailDomain;
    },
    getCleanEmail: (email: string, prod: boolean) => {
      const domain = email.replace(/.*@/, '');
      let username = email.replace(/@.*/, '');
      if (username.includes('+')) {
        const regex = /\+.*/;
        username = username.replace(regex, '');
      }
      if (helper.isRobotEmail(email, prod) && username.includes('.')) {
        const regex = /\..*/;
        username = username.replace(regex, '');
      }
      return `${username}@${domain}`;
    },
    getClean_Email: (_email: string, prod: boolean) => {
      let email = Encoder.decodeKey(_email);
      if (!email) return null;
      email = User.helper.getCleanEmail(email, prod);
      return Encoder.encodeKey(email);
    },
    generateFullName: (user: Common) => {
      if (!user) return null;
      const firstName = user.firstName;
      const lastName = user.lastName;
      const fullName = user.fullName
      const emails = user.emails;
      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      } else if (fullName) {
        return fullName;
      } else if (lastName) {
        return lastName;
      } else if (firstName) {
        return firstName;
      } else if (emails && emails.length > 0) {
        return emails[0].substring(0, emails[0].lastIndexOf("@"));
      }
      return null;
    },
    getFirstName: (user: User.Common) => {
      if (user?.firstName) {
        return user.firstName;
      } else if (user?.fullName) {
        return user?.fullName.split(' ').slice(0, -1).join(' ');
      }
      return '';
    },
    getLastName: (user: User.Common) => {
      if (user?.lastName) {
        return user.lastName;
      } else if (user?.fullName) {
        return user.fullName.split(' ').slice(-1).join(' ');
      }
      return '';
    },
    getFullName: (user: User.Common) => {
      if (user) {
        if (user.fullName) {
          return user.fullName;
        } else if (user.firstName && user.lastName) {
          return `${user.firstName} ${user.lastName}`;
        } else if (user.firstName || user.lastName) {
          return `${user.firstName || ''}${user.lastName || ''}`;
        } else if (user.emails && user.emails.length > 0) {
          return `${user.emails[0]}`;
        }
      }
      return '';
    },
    getPhoneNumberFromSource: (source: string) => {
      return '+' + source.replace(/@.*/, '');
    },
    getEmail: (user: User.Common) => {
      return user?.emails?.[0] || null;
    },
    getPhone: (user: User.Common) => {
      return user?.phones?.[0] || null;
    },
    isCustomer: (user: User.Common) => {
      return !!user?.roles?.isCustomer;
    },
    isAdmin: (user: User.Common) => {
      return !!user?.roles?.isAdmin;
    },
    isAdminOrAgent(user: User.Common): boolean {
      return !!(user?.roles?.isAdmin || user?.roles?.isAgent)
    },
    isTeamMember: (user: User.Common) => {
      return !!user?.roles?.isTeamMember;
    },
    isRobot: (user: User.Common) => {
      return !!user?.roles?.isRobot;
    },
    isAuthentified: (user: User.Common) => {
      return !!user?.roles?.isAuthentified;
    },
    isCustomerOrTeamMember: (user: User.Common) => {
      return helper.isCustomer(user) || helper.isTeamMember(user);
    },
    getUniqueEmail: (email: string, id: string) => {
      return email ? email.replace('@', `.${id}@`) : email;
    },
    defaultAvatar: (fullName: string | null) => {
      return fullName ? `https://api.dicebear.com/5.x/initials/svg?fontFamily=Helvetica&seed=${fullName}&backgroundColor=c6a0f6,ed8796,ee99a0,f5a97f,eed49f,a6da95,8bd5ca,91d7e3,7dc4e4,8aadf4,b7bdf8&textColor=FFFFFF&fontWeight=800&fontSize=40` : '/assets/user.svg';
    },
    getAvatar: (user: User.Common) => {
      if (user && user.avatar) return user.avatar;
      const fullName = helper.getFullName(user);
      return helper.defaultAvatar(fullName);
    },
    getDefaultUserPermissions(userRoles: UserRoles.Roles) {
      let userPermissions: UserPermissions.Common = Object.keys(UserPermissions.USER_PERMISSIONS).reduce(
        (permissions, p) => {
          const typedPermissionKey = p as UserPermissions.USER_PERMISSIONS;
          permissions[typedPermissionKey] = false;
          return permissions;
        },
        {} as UserPermissions.Common
      );
      // Add permissions by roles
      Object.keys(userRoles).forEach((role: string) => {
        const roleKey = role as keyof UserRoles.Roles; // Cast to make it work
        if (!!userRoles[roleKey]) {
          const rolePermissions = (UserPermissions.ROLE_PERMISSIONS[role] || []).reduce((a, b) => {
            const bKey = b as keyof UserPermissions.Common; // Cast to make it work
            a[bKey] = true; return a;
          }, {} as UserPermissions.Common)
          userPermissions = { ...userPermissions, ...rolePermissions };
        }
      });
      return userPermissions;
    },
    getDBUserPermissions(user: User.Object) {
      const permissions = user?.permissions || {};
      Object.keys(permissions).forEach((p) => {
        const typedPermissionKey = p as UserPermissions.USER_PERMISSIONS;
        if (permissions[typedPermissionKey] !== true && permissions[typedPermissionKey] !== false) {
          delete permissions[typedPermissionKey];
        }
      });
      return permissions;
    },
    getUserPermissions(user: User.Object) {
      const userRoles = user?.roles || {};
      const userPermissions = User.helper.getDefaultUserPermissions(userRoles);
      const userDBPermissions = User.helper.getDBUserPermissions(user);
      // Add function to remove agent/admin permissions to customers
      return { ...userPermissions, ...userDBPermissions };
    },
    getAssistant: (value: User.Common, prod: boolean): UserAssistant.Object => {
      const environment = getEnvironment(prod);
      let _value: UserAssistant.Object;
      if (value && value.assistant) { _value = value.assistant; } else { _value = {}; }
      const firstName: string = _value.firstName || environment.assistant.firstName;
      const lastName: string = _value.lastName || '';
      const email: string = _value.email || UserAssistant.helper.getAssistantEmailUserName(firstName, prod);
      const computed_defaultEmail = `${email}@${environment.emailDomain}`;
      const computed_fullEmail: string = _value.customEmail || computed_defaultEmail;
      const computed_uniqueEmail: string = `${email}.u${value.shortId}@${environment.emailDomain}`;
      const title: string = _value.title || environment.assistant.title;
      const organization: string = _value.organization || environment.assistant.organization;
      const avatar: string = _value.avatar || helper.defaultAvatar(firstName);
      const emailSignature = _value?.emailSignature;
      const assistant: UserAssistant.Object = { firstName, lastName, computed_fullEmail, computed_defaultEmail, computed_uniqueEmail, email, title, organization, avatar, emailSignature };
      return assistant;
    },
    getUserReferralCode: (user: Partial<User.Object>) => {
      return (user?.firstName?.charAt(0) || 'X') + (user?.lastName?.charAt(0) || 'X') + '_' + user?.shortId;
    }
  }

  export interface Address {
    line1?: string,
    line2?: string
    postal_code?: string,
    city?: string,
    state?: string,
    country?: string,
  }

  export interface Company {
    name?: string,
    title?: string,
    website?: string,
    address?: Address,
    phone?: number,
    logo?: string
  }

  export interface Socials {
    'skype': string;
    'facebook': string;
    'github': string;
    'twitter': string;
    'linkedin': string;
    'googleplus': string;
    'aboutme': string;
  }

  export enum CardType {
    Professional = 'professional',
    Personal = 'personal',
  }

  export const VISIBLE_SOCIALS = ['linkedin', 'skype', 'twitter', 'facebook'];
}