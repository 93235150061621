export const environment = {
  production: false,
  firebaseCloudFunctionURL: "https://us-central1-the-assistant-dev.cloudfunctions.net",
  firebaseConfig: {
    apiKey: "AIzaSyAdH1uF6d1NcyuYK5Wu9Jaem3vYaksbtkM",
    authDomain: "the-assistant-dev.firebaseapp.com",
    databaseURL: "https://the-assistant-dev.firebaseio.com",
    projectId: "the-assistant-dev",
    storageBucket: "the-assistant-dev.appspot.com",
    messagingSenderId: "1018748943657",
    appId: "1:1018748943657:web:13369ab8e4e2db0ad48b38",
    measurementId: "G-QVT9G1YQGT"
  },
  thirdParties: {
    stripe: {
      default_licensed_product: "prod_ChHp2nqUNoTJG6",
      default_licensed_price: "plan_ErM4GqMIbIRIxU",
      starter_licensed_price: "price_1HueT4AWGtG7Qmtb30a1kfAA",
      pro_licensed_price: "price_1IEty5AWGtG7Qmtb4tGecGcO",
      team_licensed_price: "price_1IEuBNAWGtG7Qmtb1wsDy957",
      productUpdateDate: new Date("02-01-2019"),
      dashboardURL: 'https://dashboard.stripe.com/test',
      publishable_key: 'pk_test_jNO3mbD15ynAt7Fk0wDwuzqP',
      accountPath: 'customers'
    },
    cronofy: {
      dashboardURL: 'https://app.cronofy.com/oauth/applications/5a54abc9cd62566eb900083c',
    },
    ringover: {
      dashboardURL: 'https://dashboard.ringover.com/ivrs',
    },
    nylas: {
      dashboardURL: 'https://dashboard.nylas.com/applications/8tqj6f7bkytqoyta2izib2go8',
      accountPath: 'accounts'
    }
  },
  emailDomain: 'dev.theassistant.com',
  phoneDomain: 'phone.dev.theassistant.com',
  url: 'http://localhost:4200',
  internalUrl: "http://localhost:4200",
  assistant: {
    phone: '+33644644438',
    hiddenPhone: '+33 6 44 64 ** **',
    firstName: 'Alex',
    email: 'alex',
    title: 'Personal assistant',
    organization: 'The Assistant'
  },
  agentEmail: 'bertrand@team.theassistant.com',
  assistantReviewEmail: 'assistant@team.theassistant.com',
  urls: {
    support: {
      url: "https://calendly.com/the_assistant/support",
      email: 'mailto:support@team.theassistant.com'
    },
    // calendly=
    subscription: 'https://calendly.com/theassistant-sales/theassistant-abonnements-client',
    termsOfUse: 'https://www.theassistant.com/fr/cgu',
    termsOfSales: 'https://www.theassistant.com/fr/cgv',
    privacy: 'https://www.theassistant.com/fr/confidentialite',
    cookies: 'https://www.theassistant.com/fr/confidentialite'
  },
  agentsInputBase: 'D59PlMFrEzQsQmAIfDEc',
  clientsInputBase: 'gVUfFkFxRJUMhmkfwgwc',
  servicesInputBase: 'tHNvEp1MLBMTYMbhIm54',
  titleInputBase: 'qRnhAFYTltmQICli23Tx',
  oneloginDomain: 'theassistant',
  segmentWriteKey: 'lVgVyhj9TqwnLeChAXyOq0QbAMlIdjIj',
  externalAppUrls: {
    vault: 'http://localhost:8100/vault',
    request: 'http://localhost:8100/requests/timeline'
  }
};