import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { FirebaseService } from '../shared/services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private fbs: FirebaseService, private router: Router) { }

  canActivate(state: RouterStateSnapshot): Observable<boolean> {
    return this.fbs.currentUser().pipe(
      map((user) => {
        const roles = user?.roles;
        if (roles?.isAgent || roles?.isAdmin) {
          return true;
        } else {
          this.router.navigate(['connect'], { queryParams: { continueUrl: state.url } });
          return false;
        }
      })
    );
  }
}
