import { dbDateTypes, dbDateUpdateTypes } from "../../common";
​
export namespace Credit {
​
    export interface Common {
        initialQuantity?: number,
        remainingQuantity?: number,
        stripeInvoiceId?: string,
    }
​
    export interface DBObject extends Common {
        addedAt?: dbDateTypes,
        periodStart?: dbDateTypes,
        periodEnd?: dbDateTypes
    }
​
    export interface Object extends Common {
        addedAt?: Date,
        periodStart?: Date,
        periodEnd?: Date
    }
​
    export interface DBUpdate extends Common {
        addedAt?: dbDateUpdateTypes,
        periodStart?: dbDateUpdateTypes,
        periodEnd?: dbDateUpdateTypes
    }
​
    export const helper = {
        toObject: (dbObject: DBObject): Object => {
          return {
            ...dbObject,
            addedAt: dbObject?.addedAt?.toDate && dbObject?.addedAt?.toDate(),
            periodStart: dbObject?.periodStart?.toDate && dbObject?.periodStart?.toDate(),
            periodEnd: dbObject?.periodEnd?.toDate && dbObject?.periodEnd?.toDate(),
          }
        },
      }
​
}