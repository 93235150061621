export namespace Email {

    export interface Common {
        name?: string,
        address: string
    }

    // export interface DBObject extends Common {
    // }

    // export interface Object extends Common {
    // }

    // export interface DBUpdate extends Common {
    // }

    export const helper = {
        getNameFromEmail(email: string): string {
            const capitalizeFirstLetter = (string: string) => {
                return string && string.charAt(0).toUpperCase() + string.slice(1);
            }
            let username = email?.replace(/@.*/, '');
            if (username?.includes('+')) {
                const regex = /\+.*/;
                username = username?.replace(regex, '');
            }
            return username && capitalizeFirstLetter(username) || email;
        },
        getEmailName(value: Email.Common): string {
            if (value?.name) return value.name;
            return helper.getNameFromEmail(value?.address);
        },
        getSourceDomain: (source: string): string => {
            return source.replace(/.*@/, '');
        },
        addressdeparser: (u: Email.Common) => {
            return u.name ? `${u.name} <${u.address}>` : u.address;
        },
    }
}