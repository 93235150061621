import { Injectable } from '@angular/core';
import {
  combineLatest,
  fromEvent,
  merge,
  Observable
} from 'rxjs';
import { map, shareReplay, switchMap, throttleTime } from 'rxjs/operators';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment-timezone';
import firebase from 'firebase/compat/app';

const INTERVAL = 5 * 60000;

@Injectable({
  providedIn: 'root'
})
export class ActivityMonitorService {

  protected activityEvents$: Observable<any>;
  activeUsers$: Observable<string[]>;
  private sessionId: string;

  constructor(private fbs: FirebaseService, private db: AngularFirestore) {
    this.activityEvents$ = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
      fromEvent(document, 'keydown')
    );

    const userId$ = this.fbs.currentUserId();
    combineLatest([this.activityEvents$, userId$]).pipe(throttleTime(INTERVAL)).subscribe(([_, userId]) => {
      if (userId) {
        const activityCollection = this.db.collection(`users/${userId}/activity-monitor`);
        if (!this.sessionId) this.sessionId = activityCollection.doc().ref.id;
        activityCollection.doc(this.sessionId).set({
          date: firebase.firestore.FieldValue.serverTimestamp(),
          type: 'APP_ACTIVITY'
        });
      }
    });
    this.activeUsers$ = this.fbs.currentUserRole().pipe(
      throttleTime(INTERVAL),
      switchMap((role) => {
        if (role.isAgent) {
          return this.db.collectionGroup('activity-monitor', ref => ref.where('date', '>', moment().add('-30', 'minute').toDate())).snapshotChanges().pipe(
            map((actions) => {
              return [...new Set(actions.map(a => a.payload.doc.ref.parent.parent.id))];
            })
          );
        } else {
          return [];
        }
      }),
      shareReplay(1)
    )
  }

}
