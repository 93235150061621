import { Component, OnInit, Input, ChangeDetectionStrategy, TemplateRef, SimpleChanges, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivityMonitorService } from 'src/app/activity-monitor.service';

@Component({
  selector: 'app-user-label',
  templateUrl: './user-label.component.html',
  styleUrls: ['./user-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserLabelComponent implements OnInit, OnChanges {
  @Input() userId: string;
  @Input() taSize: 'large' | 'small' | 'default' | number = 'small';
  @Input() mode: 'avatar' | 'text' | 'default' = 'default';
  @Input() title: string | TemplateRef<any>;

  isActive$: Observable<boolean>;

  constructor(private activityMonitor: ActivityMonitorService) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.userId?.currentValue !== changes?.userId?.previousValue) {
      this.isActive$ = this.activityMonitor.activeUsers$.pipe(map((activeUsers) => activeUsers.includes(this.userId)));
    }
  }

}
