import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { FirebaseService } from './firebase.service';
import { User } from 'src/app/models/user';

@Injectable({
    providedIn: "root"
})
export class PartnerGroupsService {

    partnersGroups$: Observable<NzTreeNodeOptions[]>;

    constructor(private db: AngularFirestore, private fbs: FirebaseService) {
        this.partnersGroups$ = this.db.collection('_public').doc<{ value: NzTreeNodeOptions[] }>('partnerGroups').valueChanges().pipe(map(doc => doc.value), shareReplay());
    }

    getGroup$(groupId: string) {
        return this.partnersGroups$.pipe(map(groups => {
            const searchTree = (node: NzTreeNodeOptions) => {
                if (node.key == groupId) {
                    return node;
                } else if (node.children != null) {
                    var result: NzTreeNodeOptions | null = null;
                    for (let i = 0; result === null && i < node.children.length; i++) {
                        result = searchTree(node.children[i]);
                    }
                    return result;
                }
                return null;
            }
            return searchTree({ title: null, key: null, children: groups });
        }));
    }

    getGroupName$(groupId: string) {
        return this.getGroup$(groupId).pipe(map(g => g?.title))
    }

    private __getUserClientGroupId$: { [userId: string]: Observable<string> } = {};
    getUserClientGroupId(userId: string): Observable<string> {
        if (!this.__getUserClientGroupId$[userId]) {
            this.__getUserClientGroupId$[userId] = this.fbs.getUser(userId).pipe(switchMap(u => {
                if (u?.clientGroup) {
                    return of(u.clientGroup);
                } else {
                    return this.fbs.getUserTeamOwner(userId).pipe(
                        map(u => u?.clientGroup || undefined)
                    )
                }
            }), shareReplay());
        }
        return this.__getUserClientGroupId$[userId];
    }

    private __getUserProjectManagerId$: { [userId: string]: Observable<string> } = {};
    getUserProjectManagerId(userId: string): Observable<string> {
        if (!this.__getUserProjectManagerId$[userId]) {
            this.__getUserProjectManagerId$[userId] = this.fbs.getUser(userId).pipe(switchMap(u => {
                if (u?.projectManagerId) {
                    return of(u.projectManagerId);
                } else {
                    return this.fbs.getUserTeamOwner(userId).pipe(
                        map(u => u?.projectManagerId || undefined)
                    )
                }
            }), shareReplay());
        }
        return this.__getUserProjectManagerId$[userId];
    }

    private __getUserAccountManagerId$: { [userId: string]: Observable<string> } = {};
    getUserAccountManagerId(userId: string): Observable<string> {
        if (!this.__getUserAccountManagerId$[userId]) {
            this.__getUserAccountManagerId$[userId] = this.fbs.getUser(userId).pipe(switchMap(u => {
                if (u?.accountManagerId) {
                    return of(u.accountManagerId);
                } else {
                    return this.fbs.getUserTeamOwner(userId).pipe(
                        map(u => u?.accountManagerId || undefined)
                    )
                }
            }), shareReplay());
        }
        return this.__getUserAccountManagerId$[userId];
    }

    private __getGroupPartnerIds$: { [groupId: string]: Observable<string[]> } = {};
    getGroupPartnerIds(groupId: string): Observable<string[]> {
        if (!this.__getGroupPartnerIds$[groupId]) {
            this.__getGroupPartnerIds$[groupId] = this.db.collection<User.DBObject>('users', ref => ref.where('partnerGroups', 'array-contains', groupId)).snapshotChanges().pipe(
                    map(actions => {
                        return actions.map(a => a.payload.doc.id);
                    }),
                    shareReplay(1)
                );
        }
        return this.__getGroupPartnerIds$[groupId];
    }
}