import { shareReplay, first, map, switchMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';

import { HttpParams } from '@angular/common/http';

import { FirebaseService } from './firebase.service';
import { Stripe as stripe_server } from 'stripe';
import { ReplaySubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { UserAccount } from 'src/app/models/user';

// declare let Stripe;

@Injectable({
    providedIn: 'root'
})
export class StripeServerService {
    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private fbs: FirebaseService,
        private fns: AngularFireFunctions,
        private db: AngularFirestore,
    ) { }

    private _loadedLibraries: ReplaySubject<stripe.Stripe>;

    private loadScript() {
        if (!this._loadedLibraries) {
            this._loadedLibraries = new ReplaySubject();
            const script = this.document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = "https://js.stripe.com/v3/";
            script.onload = () => {
                const stripe = Stripe(environment.thirdParties.stripe.publishable_key);
                this._loadedLibraries.next(stripe);
                this._loadedLibraries.complete();
            };
            this.document.body.appendChild(script);
        }

        return this._loadedLibraries.asObservable();
    }

    stripe$ = this.loadScript();
    elements$ = this.stripe$.pipe(map((s) => s.elements()));


    private __getPaymentMethods$: { [userId: string]: Observable<stripe_server.PaymentMethod[]> } = {};
    getPaymentMethods$(userId: string): Observable<stripe_server.PaymentMethod[] | null> {
        if (!userId) return of(null);
        if (!this.__getPaymentMethods$[userId]) {
            this.__getPaymentMethods$[userId] = this.db.collection<stripe_server.PaymentMethod>(`users/${userId}/thirdParties/stripe/payment_methods`).valueChanges()
        }
        return this.__getPaymentMethods$[userId];
    }

    getStripeCustomer$(userId: string): Observable<stripe_server.Customer> {
        if (!userId) return of(null);
        return this.db.collection<stripe_server.Customer>(`users/${userId}/thirdParties/stripe/customers`).valueChanges().pipe(
            map(customers => customers && customers.length > 0 && customers[0]),
            shareReplay(1)
        )
    }

    getStripeCustomerId$(userId: string): Observable<string> {
        if (!userId) return of(null);
        return this.db.collection<UserAccount.DBObject>(`users/${userId}/thirdParties`).doc('stripe').valueChanges().pipe(
            map(userAccount => userAccount?.uid),
            shareReplay(1)
        )
    }

    createSetupIntent$(): Observable<stripe_server.SetupIntent> {
        const intent: stripe_server.SetupIntentCreateParams = {
            payment_method_types: ['card', 'sepa_debit'],
            mandate_data: {
                customer_acceptance: {
                    type: 'online',
                    accepted_at: Date.now()
                }
            }
        }
        const func = this.fns.httpsCallable<stripe_server.SetupIntentCreateParams, stripe_server.SetupIntent>('stripe-createSetupIntent');
        return func(intent).pipe(first())
    }



    getStripeSubscription$(userId: string): Observable<stripe_server.Subscription | null> {
        if (!userId) return of(null);
        return this.db.collection<stripe_server.Subscription>(`users/${userId}/thirdParties/stripe/subscriptions`).valueChanges().pipe(
            map(subscription => subscription && subscription.length > 0 && subscription[0]),
            shareReplay(1)
        )
    }


    private __getCustomerPack$?: Observable<stripe_server.Product | stripe_server.DeletedProduct | 'NO_PACK' | undefined>;
    getCurrentUserPack$() {
        if (!this.__getCustomerPack$) {
            this.__getCustomerPack$ = this.fbs.currentUserId().pipe(
                switchMap(userId => this.getCustomerPack$(userId)),
                shareReplay(1)
            )
        }
        return this.__getCustomerPack$;
    }

    getCustomerPack$(userId: string) {
        return this.getStripeSubscription$(userId).pipe(
            switchMap(subscription => {
                const sItemPack = subscription?.items?.data?.find(item => item?.plan?.metadata?.isPrice);
                const packProduct = sItemPack?.plan?.product;
                if (!packProduct) return of<'NO_PACK'>('NO_PACK');
                else if (typeof packProduct === 'string')
                    return this.db.collection('_public/stripe/products').doc<stripe_server.Product>(packProduct).valueChanges();
                else return of(packProduct);
            }),
            shareReplay(1)
        )
    }

    getStripeProductById$(productId: string): Observable<stripe_server.Product> {
        return this.db.collection(`_public/stripe/products`).doc<stripe_server.Product>(productId).valueChanges();
    }

    getStripePriceById$(priceId: string): Observable<stripe_server.Plan> {
        return this.db.collection(`_public/stripe/prices`).doc<stripe_server.Plan>(priceId).valueChanges();
    }
}