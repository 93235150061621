import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarPipe, UserFirstNamePipe, UserLastNamePipe, UserFullNamePipe, UserPipe, PublicUserPipe, UserEmailPipe, UserFromSourcePipe, IsAdminOrAgentPipe, UserTeamsPipe, UserShortFullNamePipe, UserAnalyticsPipe, UserTeamOwnerIdPipe, IsCustomerPipe, IsCustomerOrTeamMemberPipe } from './user.pipe';
import { UserLabelComponent } from './user-label/user-label.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzOutletModule } from 'ng-zorro-antd/core/outlet';

@NgModule({
  declarations: [
    UserAvatarPipe,
    UserFirstNamePipe,
    UserLastNamePipe,
    UserFullNamePipe,
    UserShortFullNamePipe,
    UserEmailPipe,
    UserPipe,
    UserLabelComponent,
    PublicUserPipe,
    UserFromSourcePipe,
    IsAdminOrAgentPipe,
    IsCustomerPipe,
    IsCustomerOrTeamMemberPipe,
    UserTeamsPipe,
    UserAnalyticsPipe,
    UserTeamOwnerIdPipe
  ],
  imports: [
    CommonModule,
    NzAvatarModule,
    NzTypographyModule,
    NzOutletModule
  ],
  exports: [
    UserAvatarPipe,
    UserFirstNamePipe,
    UserLastNamePipe,
    UserFullNamePipe,
    UserShortFullNamePipe,
    UserEmailPipe,
    UserPipe,
    PublicUserPipe,
    UserLabelComponent,
    UserFromSourcePipe,
    IsAdminOrAgentPipe,
    UserTeamsPipe,
    UserAnalyticsPipe,
    UserTeamOwnerIdPipe,
    IsCustomerPipe,
    IsCustomerOrTeamMemberPipe
  ]
})
export class UserModelModule { }
