export { Message, Attachment, Email } from 'ta-models';
import { Message, Attachment, Email } from 'ta-models';
import * as TreeModel from 'tree-model';
import { User } from '../user';
import { Observable } from 'rxjs';
import { NzUploadFile } from 'ng-zorro-antd/upload';

export interface EmailWithUser extends Email.Common {
  user$?: Observable<User.Object>;
}

export interface MessageTreeModel extends TreeModel.Model<Message.Object> { }

export const getMessageThreads = (messages: MessageTreeModel[]) => {
  const map: { [key: string]: number } = {};
  let node: MessageTreeModel;
  const roots: MessageTreeModel[] = [];
  let i: number;
  for (i = 0; i < messages.length; i++) {
    map[messages[i]._emailMessageId] = i; // initialize the map
    messages[i].children = []; // initialize the children
  }
  for (i = 0; i < messages.length; i++) {
    let added = false;
    node = messages[i];
    const _references = node._emailReferences || {};
    const references = Object.keys(_references).sort((a, b) => (_references[a] > _references[b]) ? 1 : -1);
    do {
      const potentialParent = references.pop();
      const potentialParentIndex = map[potentialParent];
      if (potentialParentIndex && messages[potentialParentIndex]) {
        // if you have dangling branches check that map[node.parentId] exists
        messages[potentialParentIndex].children.push(node);
        added = true;
      }
    } while (references.length > 0 && !added)
    if (!added) { roots.push(node); }
  }
  const tree = new TreeModel();
  const t = tree.parse<MessageTreeModel>({ children: roots });
  return t;
}

export const MessageHelper = {
  dbAttachmentToUploadFile(attachment: Attachment.DBObject) {
    if (!attachment) return null;
    return {
      lastModified: attachment.key,
      type: attachment['content-type'],
      name: attachment.name,
      size: attachment.size,
      stored: attachment.stored,
      url: attachment.url,
      uid: attachment.key,
    } as NzUploadFile
  },
  uploadFileToDBAttachment(file: NzUploadFile) {
    if (!file) return null;
    return {
      key: file.uid,
      'content-type': file.type,
      name: file.name,
      size: file.size,
      stored: true,
      url: file.url,
    } as Attachment.DBObject
  },

}