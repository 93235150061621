<div *ngIf="userId | user$ | async as user; else emptyLabel">
    <ng-container
        *ngTemplateOutlet="userLabel; context: {avatar: user | userAvatar, username: user | userFullName, email: user | userEmail, mode: mode}">
    </ng-container>
</div>
<ng-template #emptyLabel>
    <div class="muted-block">
        <ng-container *ngTemplateOutlet="userLabel; context: {icon: 'question', username: 'nobody', mode: mode}">
        </ng-container>
    </div>
</ng-template>
<ng-template #userLabel let-avatar='avatar' let-username='username' let-email="email" let-icon="icon" let-mode="mode">
    <div [ngClass]="taSize" class="flex flex-row justify-start items-center gap-1">
        <div [ngClass]="{'active': isActive$ | async}" *ngIf="mode != 'text'">
            <nz-avatar class="avatar" [nzSize]="taSize" [nzIcon]="icon" [nzSrc]="avatar"></nz-avatar>
        </div>
        <div *ngIf="mode != 'avatar'" class="flex flex-col justify-center items-start">
            <span class="username" nz-typography nzEllipsis [nzEllipsisRows]="1">
                <span *ngIf="username">{{username}}</span>
                <span *ngIf="!username" class="text-muted" i18n="@@shared-nobody">nobody</span>
            </span>
            <span class="title" nz-typography nzEllipsis [nzEllipsisRows]="1" *ngIf="title">
                <ng-container *nzStringTemplateOutlet="title">{{ title }}</ng-container>
            </span>
        </div>
    </div>
</ng-template>