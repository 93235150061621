
import { environment } from '../environments/environment';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//Permissions
import { NgxPermissionsModule } from 'ngx-permissions';

//Analytics
import { Angulartics2Module } from 'angulartics2';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NZ_I18N, fr_FR, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SearchBarModule } from './search-bar/search-bar.module';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { UserModelModule } from './models/user/user-model.module';
import { VersionModule } from './shared/ui/version/version.module';
import { UserPermissionService } from './shared/services/user-permissions.service';
import { ActivityMonitorModule } from './activity-monitor/activity-monitor.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

// import localeFr from '@angular/common/locales/fr';
// import localeFrExtra from '@angular/common/locales/extra/fr';

// registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

// import en from '@angular/common/locales/en';
// registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireFunctionsModule,
    AngularFireDatabaseModule,
    // AngularFirestoreModule.enablePersistence({
    //   synchronizeTabs: true
    // }),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AppRoutingModule,
    HttpClientJsonpModule, // For Google map service
    Angulartics2Module.forRoot(),
    NzDrawerModule,
    NzButtonModule,
    NzDividerModule,
    NzDropDownModule,
    NzIconModule,
    VersionModule,
    UserModelModule,
    NzLayoutModule,
    NzPageHeaderModule,
    NgxPermissionsModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SearchBarModule,
    ActivityMonitorModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: NZ_I18N, useValue: fr_FR },
    { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
    { provide: SETTINGS, useValue: {} },
    {
      provide: APP_INITIALIZER,
      useFactory: (ups: UserPermissionService) => function () { return ups.init() },
      deps: [UserPermissionService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
