import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from "../../common";
import { Calendar } from "./calendar";
import { General } from "./general"

export * from  "./calendar"
export * from  "./general"
export * from  "./partner"

export namespace Preferences {

    export interface Common {
        addedAt?: allDateTypes
        category?: PREFERENCES_CATEGORY,
        data?: General.Preferences|Calendar.Preferences,
    }

    export interface DBObject extends Common {
        addedAt?: dbDateTypes
    }

    export interface Object extends Common {
        computed_id?: string,
        addedAt?: Date,
        computed_userId?: string
    }

    export interface DBUpdate extends Common {
        addedAt?: dbDateUpdateTypes
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject, userId: string): Object => {
            const object: Object = {
                ...dbObject,
                addedAt: dbObject?.addedAt?.toDate && dbObject?.addedAt?.toDate(),
                computed_id: id,
                computed_userId: userId
            };
            return object;
        }
    }

    export enum PREFERENCES_CATEGORY {
        GENERAL = 'GENERAL',
        CALENDAR = 'CALENDAR',
        PARTNER = 'PARTNER'
    }
}
