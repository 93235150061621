<button nz-button nzShape="round" nz-dropdown [nzDropdownMenu]="menu4">
    <div class="flex flex-row items-center space-x-1">
        <ng-container [ngSwitch]="status">
            <nz-badge *ngSwitchCase="'active'" nzStatus="success" i18n-nzText="@@status-active"
                nzText="Active"></nz-badge>
            <nz-badge *ngSwitchCase="'away'" nzStatus="default" i18n-nzText="@@status-away" nzText="Away"></nz-badge>
            <nz-badge *ngSwitchCase="'mute'" nzStatus="error" i18n-nzText="@@status-mute"
                nzText="Do not disturb"></nz-badge>
        </ng-container>
        <span nz-icon nzType="caret-down" nzTheme="outline"></span>
    </div>
</button>
<nz-dropdown-menu #menu4="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item>
            <div class="flex flex-row items-center space-x-4 px-2">
                <nz-badge nzStatus="success"></nz-badge>
                <div class="flex flex-col">
                    <span nz-typography>Automatic</span>
                    <span nz-typography nzType="secondary" class="text-sm">Based on activity</span>
                </div>
                <span nz-icon nzType="check" nzTheme="outline"></span>
            </div>
        </li>
        <li nz-menu-item>
            <div class="flex flex-row items-center space-x-4 px-2">
                <nz-badge nzStatus="error"></nz-badge>
                <div class="flex flex-col">
                    <span nz-typography>Do not disturb</span>
                    <span nz-typography nzType="secondary" class="text-sm">Need to concentrate</span>
                </div>
                <!-- <span nz-icon nzType="check" nzTheme="outline"></span> -->
            </div>
        </li>
        <li nz-menu-item>
            <div class="flex flex-row items-center space-x-4 px-2">
                <nz-badge nzStatus="default"></nz-badge>
                <div class="flex flex-col">
                    <span nz-typography>Set as away</span>
                    <span nz-typography nzType="secondary" class="text-sm">I'm not working</span>
                </div>
                <!-- <span nz-icon nzType="check" nzTheme="outline"></span> -->
            </div>
        </li>
        <li nz-menu-divider></li>
        <li nz-submenu [nzTitle]="titleTpl">
            <ul>
                <li nz-menu-item>
                    <div class="flex flex-row items-center space-x-4 px-2">
                        <ngx-emoji emoji=":woman-walking:" size="18"></ngx-emoji>
                        <div class="flex flex-col">
                            <span nz-typography>Revient bientôt</span>
                            <span nz-typography nzType="secondary" class="text-sm">30 minutes</span>
                        </div>
                    </div>
                </li>
                <li nz-menu-item>
                    <div class="flex flex-row items-center space-x-4 px-2">
                        <ngx-emoji emoji=":broccoli:" size="18"></ngx-emoji>
                        <div class="flex flex-col">
                            <span nz-typography>Déjeune</span>
                            <span nz-typography nzType="secondary" class="text-sm">1 heure</span>
                        </div>
                    </div>
                </li>
                <li nz-menu-item>
                    <div class="flex flex-row items-center space-x-4 px-2">
                        <ngx-emoji emoji=":face_with_thermometer:" size="18"></ngx-emoji>
                        <div class="flex flex-col">
                            <span nz-typography>Maladie</span>
                            <span nz-typography nzType="secondary" class="text-sm">Aujourd'hui</span>
                        </div>
                    </div>
                </li>
            </ul>
        </li>
        <ng-template #titleTpl>
            <div class="flex flex-row items-center space-x-4 px-2">
                <span nz-icon nzType="edit" nzTheme="fill"></span>
                <div class="flex flex-col">
                    <span nz-typography>Add a status</span>
                </div>
            </div>
        </ng-template>
    </ul>
</nz-dropdown-menu>