import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from "../common"

export namespace File {
    export interface Common {
        'content-type'?: string;
        name?: string;
        size?: number;
        url?: string;
    }

    export interface DBObject extends Common {
    }

    export interface Object extends Common {
        computed_id?: string,
    }

    export interface DBUpdate extends Common {
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...dbObject,
                computed_id: id,
            }
        }
    }
}

export namespace FileUpload {
    export interface Common extends File.Common {
        addedAt?: allDateTypes,
        addedBy?: string
    }

    export interface DBObject extends Common {
        addedAt?: dbDateTypes,
    }

    export interface Object extends Common {
        computed_id?: string,
        addedAt?: Date,
    }

    export interface DBUpdate extends Common {
        addedAt?: dbDateUpdateTypes,
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...dbObject,
                addedAt: dbObject.addedAt?.toDate(),
                computed_id: id,
            }
        }
    }
}