import { RawDeltaItem } from '../_temp';
import { allDateTypes, dbDateUpdateTypes, dbDateTypes } from '../common';
import { TicketGeneralFields } from '../ticket';
import { Attachment } from '../message/attachment';

export namespace Schedule {

    export interface ScheduleDeltaItemValue {
        bodyHtml: string;
        subject: string;
        attachments: Attachment.Common[];
        userId: string;
        ticketId?: string;
    }

    export interface ScheduleDeltaItem extends RawDeltaItem.Object {
        value: ScheduleDeltaItemValue
    }

    export interface Common {
        addedAt?: allDateTypes,
        title?: string,
        ticketFields?: TicketGeneralFields.Common
        cron?: string,
        nextAt?: allDateTypes,
        active?: boolean,
        description?: string;
        schedulingMethod?: SCHEDULING_METHOD;
        scheduleDeltaItem?: ScheduleDeltaItem;
        lastRun?: allDateTypes,
        deadline?: {
            date?: allDateTypes,
            delta?: number
        }
    }

    export interface DBObject extends Common {
        addedAt?: dbDateTypes,
        nextAt?: dbDateTypes,
        ticketFields?: TicketGeneralFields.DBObject,
        lastRun?: dbDateTypes,
        deadline?: {
            date?: dbDateTypes,
            delta?: number
        }
    }

    export interface Object extends Common {
        addedAt?: Date,
        computed_id: string,
        nextAt?: Date,
        ticketFields?: TicketGeneralFields.Object,
        lastRun?: Date,
        deadline?: {
            date?: Date,
            delta?: number
        }
    }

    export interface DBUpdate extends Common {
        addedAt?: dbDateUpdateTypes,
        nextAt?: dbDateUpdateTypes,
        ticketFields?: TicketGeneralFields.DBUpdate,
        lastRun?: dbDateUpdateTypes,
        deadline?: {
            date?: dbDateUpdateTypes,
            delta?: number
        }
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...dbObject,
                computed_id: id,
                addedAt: dbObject?.addedAt?.toDate?.(),
                nextAt: dbObject?.nextAt?.toDate?.(),
                ticketFields: dbObject?.ticketFields && TicketGeneralFields.helper.toObject(dbObject?.ticketFields),
                lastRun: dbObject?.lastRun?.toDate(),
                deadline: {
                    ...dbObject?.deadline,
                    date: dbObject?.deadline?.date?.toDate()
                }
            }
        },
    };

    export enum SCHEDULING_METHOD {
        DATE = 'DATE',
        CRON = 'CRON'
    }
}

export namespace ScheduleRun {

    export enum STATUS {
        TO_RUN = "TO_RUN",
        RUNNING = "RUNNING",
        SUCCEEDED = "SUCCEEDED",
        FAILED = "FAILED",
    }

    export interface Common {
        addedAt?: allDateTypes,
        scheduleAt?: allDateTypes,
        ticketId?: string,
        status?: STATUS,
        error?: string
    }

    export interface DBObject extends Common {
        addedAt?: dbDateTypes,
        scheduleAt?: dbDateTypes,
    }

    export interface Object extends Common {
        addedAt?: Date,
        scheduleAt?: Date,
        computed_id: string,
    }

    export interface DBUpdate extends Common {
        addedAt?: dbDateUpdateTypes,
        scheduleAt?: dbDateUpdateTypes,
        nextAt?: dbDateUpdateTypes,
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...dbObject,
                computed_id: id,
                addedAt: dbObject?.addedAt?.toDate?.(),
                scheduleAt: dbObject?.scheduleAt?.toDate?.(),
            }
        },
    }
}