import { Email } from "../message";
import { allDateTypes, dbDateTypes } from "../common";

export namespace Template {

  export interface Common {
    name?: string,
    isLive?: boolean,
    isPartial?: boolean,
    desc?: string,
    icon?: string,
    synonyms?: string,
    linkedServices?: string[],
    linkedUsers?: string[],
    templates?: TemplateItem[],
    to?: Email.Common[];
    cc?: Email.Common[];
    bcc?: Email.Common[];
    createdAt?: allDateTypes;
    updatedAt?: allDateTypes;
  }

  export interface DBObject extends Common {
    createdAt?: dbDateTypes;
    updatedAt?: dbDateTypes;
  }
  
  export interface Object extends Common {
    computed_id?: string,
    createdAt?: Date;
    updatedAt?: Date;
  }

  export const helper = {
    toObject: (id: string, dbObject: DBObject): Object => {
        return {
            ...dbObject,
            createdAt: dbObject?.createdAt?.toDate && dbObject?.createdAt?.toDate(),
            updatedAt: dbObject?.updatedAt?.toDate && dbObject?.updatedAt?.toDate(),
            computed_id: id,
        }
    },
  }
}

export interface TemplateItem {
  subject?: string,
  html?: string,
  language?: string,
  tags?: string[],
}