export namespace TimelineItemCommentValue {

    export interface Common {
        html?: string,
    }

    export interface DBObject extends Common {
    }

    export interface Object extends Common {
    }

    export interface DBUpdate extends Common {
    }

    export const helper = {
        toObject: (dbObject: DBObject): Object => {
            return {
                ...dbObject,
            }
        }
    }
}