import { TimelineItemComment } from './comment';
import { TimelineItemMessage } from './message';
import { TimelineItemGeneralField } from './field';
import { TimelineItemDraft } from './draft';
import { TimelineItemTask } from './task';
import { TicketGeneralFields } from '../general-fields';
import { TimelineItemCommon } from './common';
import { TimelineItemClientRating } from './client-rating';
import { TimelineItemFork } from './fork'

export { TimelineItemComment, TimelineItemCommentValue } from './comment';
export { TimelineItemMessage } from './message';
export { TimelineItemGeneralField, TimelineItemGeneralFieldValue } from './field';
export { TimelineItemDraft } from './draft';
export { TimelineItemTask, TimelineItemTaskValue } from './task';
export { TimelineItemClientRating } from './client-rating';
export { TimelineItemFork } from './fork'
export { TimelineItemCommon } from './common';

export namespace TimelineItem {
    export const helper = {
        toObject : <T extends TimelineItemCommon.DBObject, U extends TimelineItemCommon.Object>(id: string, dbObject: T): U|undefined => {
            switch (dbObject.type) {
                case TimelineItemCommon.TIMELINE_ITEM_TYPE.MSG: return TimelineItemDraft.helper.toObject(id, dbObject as TimelineItemDraft.DBObject) as U
                case TimelineItemCommon.TIMELINE_ITEM_TYPE.TASK: return TimelineItemTask.helper.toObject(id, dbObject as TimelineItemTask.DBObject) as U
                case TimelineItemCommon.TIMELINE_ITEM_TYPE.COMMENT: return TimelineItemComment.helper.toObject(id, dbObject as TimelineItemComment.DBObject) as U
                case TimelineItemCommon.TIMELINE_ITEM_TYPE.MESSAGE: return TimelineItemMessage.helper.toObject(id, dbObject as TimelineItemMessage.DBObject) as U
                case TimelineItemCommon.TIMELINE_ITEM_TYPE.CLIENT_RATING: return TimelineItemClientRating.helper.toObject(id, dbObject as TimelineItemClientRating.DBObject) as U
                case TimelineItemCommon.TIMELINE_ITEM_TYPE.FORK: return TimelineItemFork.helper.toObject(id, dbObject as TimelineItemFork.DBObject) as U
                case TimelineItemCommon.TIMELINE_ITEM_TYPE.FIELDS: {
                    switch(dbObject.value?.name) {
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.ATTRIBUTED_TO: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.ATTRIBUTED_TO>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.CLIENT: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.CLIENT>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.DEADLINE: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.DEADLINE>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.OWNER: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.OWNER>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.TITLE: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.TITLE>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.VAULT_ITEMS: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.VAULT_ITEMS>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.PROCEDURES: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.PROCEDURES>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.CATEGORY: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.CATEGORY>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.MAX_ACTIONS: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.MAX_ACTIONS>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.ESTIMATED_DURATION: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.ESTIMATED_DURATION>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.URGENT: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.URGENT>) as U
                        case TicketGeneralFields.TICKET_GENERAL_FIELDS.TASK_TYPES: return TimelineItemGeneralField.helper.toObject(id, dbObject as TimelineItemGeneralField.DBObject<TicketGeneralFields.TICKET_GENERAL_FIELDS.TASK_TYPES>) as U
                    }
                }
            }

            return undefined;
        },
    }
}






