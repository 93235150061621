import { TimelineItemCommon } from "../common";

export namespace TimelineItemFork {

    export interface Common extends TimelineItemCommon.Common {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.FORK,
        value?: {
            type: TimelineItemCommon.TIMELINE_ITEM_TYPE,
            value?: any,
            toTicketIds?: string[]
        },
    }

    export interface DBObject extends TimelineItemCommon.DBObject {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.FORK,
        value?: {
            type: TimelineItemCommon.TIMELINE_ITEM_TYPE,
            value?: any,
            toTicketIds?: string[]
        },
    }

    export interface Object extends TimelineItemCommon.Object {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.FORK,
        value?: {
            type: TimelineItemCommon.TIMELINE_ITEM_TYPE,
            value?: any,
            toTicketIds?: string[]
        },
    }

    export interface DBUpdate extends TimelineItemCommon.DBUpdate {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.FORK,
        value?: {
            type: TimelineItemCommon.TIMELINE_ITEM_TYPE,
            value?: any,
            toTicketIds?: string[]
        },
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...TimelineItemCommon.helper.__toObject(id, dbObject),
                type: TimelineItemCommon.TIMELINE_ITEM_TYPE.FORK,
                value: dbObject.value
            }
        },
    }
}