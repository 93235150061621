import { Encoder, getEnvironment } from '../shared';
import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from '../common';
import { Attachment } from './attachment';
import { Email } from './email';
import { TicketGeneralFields } from '../ticket';
import { LightMessageValue } from './light';
import { UserAccount } from '../user';
// import { Timestamp } from '@firebase/firestore-types';

export { Attachment } from './attachment';
export { Email } from './email';
export { LightMessageValue } from './light'

export namespace Message {

  export interface Common {
    createdAt?: allDateTypes;
    attachmentCount?: number;
    bodyHtml?: string;
    strippedHtml?: string;
    bodyText?: string;
    rawMail?: string
    strippedText?: string;
    subject?: string;
    type?: MESSAGE_TYPE;
    outboundStatus?: MESSAGE_OUTBOUND_STATUS;
    sentAt?: allDateTypes;
    /* Mime email ID */
    _emailMessageId?: string;
    /* Mime reply to email ID */
    _emailInReplyTo?: string;
    _emailReferences?: { [key: string]: number };
    threadId?: string;
    _addressesNames?: { [key: string]: string };
    _emails?: { [key: string]: boolean };
    /* Email address to reply to */
    _replyTo?: string;
    _fromEmail?: string|null;
    _toEmails?: { [key: string]: number };
    _ccEmails?: { [key: string]: number };
    _bccEmails?: { [key: string]: number };
    events?: { [key: string]: { delivered: number, opened: number, failed: number } };
    error?: { code?: number, message?: string };
    sentBy?: string;
    direction?: MESSAGE_DIRECTION;
    isSpam?: boolean;
    isAd?: boolean;
    metadata?: MessageMetadata;
    bodyCalendar?: string;
  }

  export interface DBObject extends Common {
    createdAt?: dbDateTypes;
    sentAt?: dbDateTypes;
  }

  export interface Object extends Common {
    computed_id?: string;
    computed_attachments?: Attachment.Object[];
    computed_read?: boolean;
    createdAt?: Date;
    sentAt?: Date;
  }

  export interface DBUpdate extends Common {
    createdAt?: dbDateUpdateTypes;
    sentAt?: dbDateUpdateTypes;
  }

  export const helper = {
    toObject: (id: string, dbObject: DBObject, computed_attachments?: Attachment.Object[]): Object => {
      return {
        ...dbObject,
        computed_id: id,
        sentAt: dbObject?.sentAt?.toDate && dbObject?.sentAt?.toDate(),
        createdAt: dbObject?.createdAt?.toDate && dbObject?.createdAt?.toDate(),
        computed_attachments: computed_attachments
      }
    },
    toDBUpdate: (object: Object): DBUpdate => {
      const dbUpdate: DBUpdate = {};
      if (object.attachmentCount) dbUpdate.attachmentCount = object.attachmentCount;
      if (object.bodyHtml) dbUpdate.bodyHtml = object.bodyHtml;
      if (object.strippedHtml) dbUpdate.strippedHtml = object.strippedHtml;
      if (object.bodyText) dbUpdate.bodyText = object.bodyText;
      if (object.rawMail) dbUpdate.rawMail = object.rawMail;
      if (object.strippedText) dbUpdate.strippedText = object.strippedText;
      if (object.type) dbUpdate.type = object.type;
      if (object.outboundStatus) dbUpdate.outboundStatus = object.outboundStatus;
      if (object.subject) dbUpdate.subject = object.subject;
      if (object._replyTo) dbUpdate._replyTo = object._replyTo;

      if (object._emailMessageId) dbUpdate._emailMessageId = object._emailMessageId;

      if (object._emailInReplyTo) dbUpdate._emailInReplyTo = object._emailInReplyTo;
      if (object._emailReferences) dbUpdate._emailReferences = object._emailReferences;
      if (object._addressesNames) dbUpdate._addressesNames = object._addressesNames;
      if (object.threadId) dbUpdate.threadId = object.threadId;

      if (object._emails) dbUpdate._emails = object._emails;
      if (object._fromEmail) dbUpdate._fromEmail = object._fromEmail;
      if (object._toEmails) dbUpdate._toEmails = object._toEmails;
      if (object._ccEmails) dbUpdate._ccEmails = object._ccEmails;
      if (object._bccEmails) dbUpdate._bccEmails = object._bccEmails;
      if (object.events) dbUpdate.events = object.events;

      if (object.error) dbUpdate.error = object.error;
      if (object.sentBy) dbUpdate.sentBy = object.sentBy;
      if (object.direction) dbUpdate.direction = object.direction;
      if (object.isSpam) dbUpdate.isSpam = object.isSpam;
      if (object.isAd) dbUpdate.isAd = object.isAd;
      if (object.metadata) dbUpdate.metadata = object.metadata;
      if (object.bodyCalendar) dbUpdate.bodyCalendar = object.bodyCalendar;

      //Can't use Firebase conversion functions
      if (object.sentAt) dbUpdate.sentAt = object.sentAt as any;
      if (object.createdAt) dbUpdate.createdAt = dbUpdate.createdAt as any;
      // if (object.sentAt) dbUpdate.sentAt = Timestamp.fromDate(object.sentAt);
      // if (object.createdAt) dbUpdate.createdAt = Timestamp.fromDate(object.createdAt);
      return dbUpdate;
    },
    emailAddress: (message: Common, _email?: string|null) => {
      if (!_email) return null;
      const name = message && message._addressesNames && message._addressesNames[_email] || null;
      const address = Encoder.decodeKey(_email);
      return { name, address } as Email.Common;
    },
    from: (message: Common) => {
      return helper.emailAddress(message, message._fromEmail);
    },
    replyTo: (message: Common) => {
      return helper.emailAddress(message, message._replyTo);
    },
    fromArray: (message: Common) => {
      const from = helper.from(message);
      return from ? [from] : [];
    },
    to: (message: Common) => {
      return Object.keys(message._toEmails || {}).map(o => helper.emailAddress(message, o));
    },
    cc: (message: Common) => {
      return Object.keys(message._ccEmails || {}).map(o => helper.emailAddress(message, o));
    },
    bcc: (message: Common) => {
      return Object.keys(message._bccEmails || {}).map(o => helper.emailAddress(message, o));
    },
    senders: (message: Common) => {
      return helper.fromArray(message);
    },
    all: (message: Common) => {
      return [...helper.fromArray(message), ...helper.receivers(message)];
    },
    receivers(message: Common) {
      return [...this.to(message), ...this.cc(message), ...this.bcc(message)];
    },
    getSourceDomain: (source: string): string => {
      return source.replace(/.*@/, '');
    },
    isShortEmail: (email: string, prod: boolean): boolean => {
      return !!helper.getShortId(email, prod);
    },
    getShortId: (email: string, prod: boolean): string | null => {
      const lowerCasedEmail = email.toLowerCase();
      const domain = helper.getSourceDomain(lowerCasedEmail);
      if (domain === getEnvironment(prod).emailDomain) {
        const username = lowerCasedEmail.replace(/@.*/, '');
        const proceed = (char: string): string | null => {
          if (username.includes(char)) {
            const regex = new RegExp(`.*\\${char}`);
            const id = username.replace(regex, '');
            if (id) return id;
          }
          return null;
        }
        const dotStyle = proceed('.');
        if (dotStyle) return dotStyle;
        const plusStyle = proceed('+');
        if (plusStyle) return plusStyle;
      }
      return null;
    },
    contains_Email: (_email: string, message: Message.Common): boolean => {
      const _emails = message._emails || {};
      return Object.keys(_emails).indexOf(_email) > -1;
    },
    encodeTempEmail: (emails: Email.Common[]): { [key: string]: number } => {
      return Encoder.arrayToOrderedObjectWithEncodedKey(emails.map(e => e.address));
    },
    fromLightMessageToMessage: (data: LightMessageValue.Object): Message.Object => {
      const to = data.to || [];
      const cc = data.cc || [];
      const bcc = data.bcc || [];
      let _toEmails: { [key: string]: number };
      let _ccEmails: { [key: string]: number };
      let _bccEmails: { [key: string]: number };
      let _addressesNames: { [key: string]: string };
      const _fromEmail = Encoder.encodeKey(data?.from?.address);
      _toEmails = helper.encodeTempEmail(to);
      _ccEmails = helper.encodeTempEmail(cc);
      _bccEmails = helper.encodeTempEmail(bcc);
      _addressesNames = [data?.from, ...to, ...cc, ...bcc].reduceRight((a, b) => {
        if (b && b.name) {
          const key = Encoder.encodeKey(b.address);
          key && (a[key] = b.name);
        }
        return a;
      }, {} as { [key: string]: string });

      const _emails = Object.keys(_addressesNames).reduce((a, b) => { a[b] = true; return a }, {} as { [key: string]: boolean });

      const message: Message.Object = {
        subject: data.subject,
        bodyHtml: data.bodyHtml,
        strippedHtml: data.strippedHtml,
        bodyText: data.bodyText,
        strippedText: data.strippedText,
        direction: data.direction,
        outboundStatus: data.outboundStatus, //Specific to outbound only
        type: data.type,
        _fromEmail,
        _toEmails,
        _ccEmails,
        _bccEmails,
        _addressesNames,
        _emails: _emails,
        sentAt: data.sentAt,
        sentBy: data.sentBy
      }

      if (data.threadId) message.threadId = data.threadId;
      const _emailInReplyTo = Encoder.encodeKey(data.emailInReplyTo);
      if (_emailInReplyTo) message._emailInReplyTo = _emailInReplyTo;
      const _emailMessageId = Encoder.encodeKey(data.emailMessageId);
      if (_emailMessageId) message._emailMessageId = _emailMessageId;

      //Convert Encoded ref decoded to encoded ref object
      message._emailReferences = Encoder.arrayToOrderedObjectWithEncodedKey(data.emailReferences);

      const _replyTo = Encoder.encodeKey(data.replyTo);
      if (_replyTo) message._replyTo = _replyTo;

      if (data.metadata) message.metadata = data.metadata;
      return message;
    }
  }

  // Enums
  export enum MESSAGE_OUTBOUND_STATUS {
    DRAFT = "DRAFT",
    /** To be preprocessed */
    TO_SEND = "TO_SEND",
    /** To be approved */
    TO_VALIDATE = "TO_VALIDATE",
    /** To be sent by a Message client (Nylas, Mailgun...) */
    SENDING = "SENDING",
    NEED_TO_SEND = "NEED_TO_SEND",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
  }

  export enum MESSAGE_DIRECTION {
    INBOUND = "inbound",
    OUTBOUND = "outbound",
  }

  export enum MESSAGE_TYPE {
    EMAIL = "email",
    VOICE = "voice",
  }

  // export interface MessageMetadata {
  //   ticketId?: string,
  //   timelineItemId?: string,
  //   userId?: string,
  //   accountId?: string,
  //   isAuthorized?: boolean,
  //   providerMessageId?: string,
  //   providerInReplyToMessageId?: string,
  //   ownerId?: string,
  //   attributedTo?: string,
  //   procedureRefPath?: string,
  //   category?: TicketGeneralFields.TICKET_CATEGORY
  // };


  export interface TAMessageMetadata {
    isAuthorized?: boolean,
    ticketId?: string,
    timelineItemId?: string,
    userId?: string,
    provider: UserAccount.THIRD_PARTY_PROVIDER,
    ownerId?: string,
    attributedTo?: string,
    procedureRefPath?: string,
    category?: TicketGeneralFields.TICKET_CATEGORY,
    providerMetadata: any
  };

  export interface ThirdPartyMessageMetadata {
    // emailAddress?: string,
    // accountId?: string,

    inReplyToMessageId?: string,
  };

  export interface MessageMetadata {
    isAuthorized?: boolean,
    unauthorizedReason?: string,
    ticketId?: string,
    timelineItemId?: string,
    msgId?: string,
    messageId?: string,
    userId?: string,
    provider?: UserAccount.THIRD_PARTY_PROVIDER,
    ownerId?: string,
    attributedTo?: string,
    procedureRefPath?: string,
    category?: TicketGeneralFields.TICKET_CATEGORY,
    providerMetadata?: {  //Some providers require to use their own id to reply to the right thread
      messageId?: string,
      inReplyTo?: string,
      userAccountUid?: string
    }
  }

  export interface MessageEntity {
    name: string,
    type: string,
    salience?: number
  }
}