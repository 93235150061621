import { TimelineItemCommon } from '../common';
import { LightMessageValue } from '../../../message/light';
import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from '../../../common';

export namespace TimelineItemMessage {

    export interface Common extends TimelineItemCommon.Common {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.MESSAGE,
        value?: string,
        $value?: LightMessageValue.Common,
        fork?: {
            fromTicketIds?: string[],
            addedBy?: string,
        }
    }

    export interface DBObject extends TimelineItemCommon.DBObject {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.MESSAGE,
        value?: string,
        $value?: LightMessageValue.DBObject,
        fork?: {
            fromTicketIds?: string[],
            addedBy?: string,
            addedAt?: dbDateTypes
        }
    }

    export interface Object extends TimelineItemCommon.Object {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.MESSAGE,
        value?: string,
        $value?: LightMessageValue.Object,
        fork?: {
            fromTicketIds?: string[],
            addedBy?: string,
            addedAt?: Date
        }
    }

    export interface DBUpdate extends TimelineItemCommon.DBUpdate {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.MESSAGE,
        value?: string,
        $value?: LightMessageValue.DBUpdate,
        fork?: {
            fromTicketIds?: string[],
            addedBy?: string,
            addedAt?: dbDateUpdateTypes
        }
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {

            const object: Object = {
                ...TimelineItemCommon.helper.__toObject(id, dbObject),
                type: TimelineItemCommon.TIMELINE_ITEM_TYPE.MESSAGE,
                value: dbObject?.value,
                }
                if (dbObject?.fork) {
                    object.fork = {
                        fromTicketIds: dbObject?.fork?.fromTicketIds,
                        addedBy: dbObject?.fork?.addedBy,
                        addedAt: dbObject?.fork?.addedAt && dbObject?.fork?.addedAt?.toDate && dbObject?.fork?.addedAt?.toDate()    
                    }    
                }
            return object;
        }
    }
}