import { VaultItem, UserPermissions } from "src/app/models/user";

interface VaultItemWithLocal extends VaultItem.Object {
    namei18n: {
        en: string,
        fr: string,
    }
}

const _STATIC_VAULT_ITEMS: VaultItemWithLocal[] = [
    {
        namei18n: {
            en: 'My Calendars',
            fr: 'Mes calendriers'
        }, category: VaultItem.VAULT_ITEM_CATEGORY.CALENDAR, computed_id: 'my_calendars'
    },
    {
        namei18n: {
            en: 'Scheduling hours',
            fr: 'Mes créneaux horaires'
        }, category: VaultItem.VAULT_ITEM_CATEGORY.CALENDAR, computed_id: 'scheduling_hours'
    },
    {
        namei18n: {
            en: 'Amazon',
            fr: 'Amazon'
        }, category: VaultItem.VAULT_ITEM_CATEGORY.APPLICATION, computed_id: 'app_amazon@{{userId}}'
    },
    {
        namei18n: {
            en: 'Google',
            fr: 'Google'
        }, category: VaultItem.VAULT_ITEM_CATEGORY.APPLICATION, computed_id: 'app_google@{{userId}}'
    },
    {
        namei18n: {
            en: 'Airbnb',
            fr: 'Airbnb'
        }, category: VaultItem.VAULT_ITEM_CATEGORY.APPLICATION, computed_id: 'app_airbnb@{{userId}}'
    },
    {
        namei18n: {
            en: 'My contact details',
            fr: 'Mes informations'
        }, category: VaultItem.VAULT_ITEM_CATEGORY.CONTACT, computed_id: 'my_contact_details@{{userId}}'
    },
    {
        namei18n: {
            en: 'General practitioner',
            fr: 'Médecin généraliste'
        }, category: VaultItem.VAULT_ITEM_CATEGORY.CONTACT, computed_id: 'contact_general_practitioner@{{userId}}'
    },
    {
        namei18n: {
            en: 'Hairdresser',
            fr: 'Coiffeur'
        }, category: VaultItem.VAULT_ITEM_CATEGORY.CONTACT, computed_id: 'contact_hairdresser@{{userId}}'
    },

    //Static vault items with conditional display
    {
        namei18n: {
            en: 'View calendar',
            fr: 'Voir le calendrier'
        },
        category: VaultItem.VAULT_ITEM_CATEGORY.CALENDAR,
        computed_id: VaultItem.VAULT_ITEM_CALENDAR_IDS.CALENDAR_LINK,
        computed_permission: UserPermissions.USER_PERMISSIONS.USER_ACCESS_CALENDAR
    },
    {
        namei18n: {
            en: 'Payment methods',
            fr: 'Moyens de paiement'
        },
        category: VaultItem.VAULT_ITEM_CATEGORY.OTHER,
        computed_id: VaultItem.VAULT_ITEM_GENERAL_IDS.PAYMENT_CARDS,
        computed_permission: UserPermissions.USER_PERMISSIONS.USER_ACCESS_PAYMENT_METHODS,
    }
];

export const STATIC_VAULT_ITEMS = (localeId: string) => _STATIC_VAULT_ITEMS.map(i => {
    const name = i.namei18n[localeId] || i.namei18n.en;
    const staticItem: VaultItem.Object = {
        name: name, category: i.category, data: i.data || {}, computed_id: i.computed_id, computed_permission: i.computed_permission || null, displayOnUserProfile: true
    };
    if (i._data) staticItem._data = i._data;
    return staticItem;
});