import { UserAccount } from "../../user/account"
import { File } from "../../file"

export namespace Attachment {

    export interface Common extends File.Common {
        key?: string;
        stored?: boolean;
        origin?: {
            type: UserAccount.THIRD_PARTY_PROVIDER,
            value: string,
            userAccountUid?: string
        };
        error?: string;
        retryCount?: number;
        transcript?: string;
    }

    export interface DBObject extends Common {
    }

    export interface Object extends Common {
        computed_id?: string;
    }

    export interface DBUpdate extends Common {
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...dbObject,
                computed_id: id,
            }
        }
    }
}