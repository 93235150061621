import { TimelineItemCommon } from "../common"
import { TimelineItemGeneralFieldValue } from "./value"
import { TicketGeneralFields } from "../../general-fields"
export { TimelineItemGeneralFieldValue } from "./value"

export namespace TimelineItemGeneralField {

    export interface Common<T extends TicketGeneralFields.TICKET_GENERAL_FIELDS> extends TimelineItemCommon.Common {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.FIELDS,
        value?: TimelineItemGeneralFieldValue.Common<T>,
    }

    export interface DBObject<T extends TicketGeneralFields.TICKET_GENERAL_FIELDS> extends TimelineItemCommon.DBObject {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.FIELDS,
        value?: TimelineItemGeneralFieldValue.DBObject<T>,
    }

    export interface Object<T extends TicketGeneralFields.TICKET_GENERAL_FIELDS> extends TimelineItemCommon.Object {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.FIELDS,
        value?: TimelineItemGeneralFieldValue.Object<T>,
    }

    export interface DBUpdate<T extends TicketGeneralFields.TICKET_GENERAL_FIELDS> extends TimelineItemCommon.DBUpdate {
        type?: TimelineItemCommon.TIMELINE_ITEM_TYPE.FIELDS,
        value?: TimelineItemGeneralFieldValue.DBUpdate<T>,
    }

    export const helper = {
        toObject: <T extends TicketGeneralFields.TICKET_GENERAL_FIELDS>(id: string, dbObject: DBObject<T>): Object<T> => {
            return {
                ...TimelineItemCommon.helper.__toObject(id, dbObject),
                type: TimelineItemCommon.TIMELINE_ITEM_TYPE.FIELDS,
                value: dbObject?.value && TimelineItemGeneralFieldValue.helper.toObject(dbObject?.value)
            }
        }
    }
}