import { allDateTypes, dbDateUpdateTypes, dbDateTypes } from '../../common';
import { NotificationStatus } from './status';
export { NotificationStatus } from './status';

export namespace Notification {

    export interface Common {
        addedAt?: allDateTypes,
        userId?: string,
        ticketId?: string,
        type?: NOTIFICATION_TYPE,
        status?: NotificationStatus.Common,
        idempotentKey?: string,
    }

    export interface DBObject extends Common {
        addedAt?: dbDateTypes,
        status?: NotificationStatus.DBObject,
    }

    export interface Object extends Common {
        addedAt?: Date,
        computed_id: string,
        computed_timelineItemId?: string,
        status?: NotificationStatus.Object,
    }

    export interface DBUpdate extends Common {
        addedAt?: dbDateUpdateTypes,
        status?: NotificationStatus.DBUpdate
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject, timelineItemId?: string): Object => {
            return {
                ...dbObject,
                computed_timelineItemId: timelineItemId,
                computed_id: id,
                addedAt: dbObject?.addedAt?.toDate && dbObject?.addedAt?.toDate(),
                status: dbObject?.status && NotificationStatus.helper.toObject(dbObject?.status),
            }
        },
    }

    export enum NOTIFICATION_TYPE {
      NEW_MESSAGE = "NEW_MESSAGE",
      MESSAGE_SENT_FAILED = "MESSAGE_SENT_FAILED",
      SNOOZED_TASK = "SNOOZED_TASK",
      PENDING_TASK = "PENDING_TASK",
      COMMENT = "COMMENT",
      TICKET_ATTRIBUTED = "TICKET_ATTRIBUTED",
    };
}