import { environment } from 'src/environments/environment';
import { NgxPermissionsObject } from 'ngx-permissions';
import { UserPermissions, User } from 'src/app/models/user';
import { Ticket, Notification } from 'src/app/models/ticket';
import { TicketItem } from './ticket-item/ticket-item.component';
import { AlgoliaTicket } from '../third-parties/algolia';

export { Ticket, TimelineItem, PublicTicket, TicketState, TimelineItemTask, TimelineItemTaskValue, TimelineItemClientRating, TimelineItemDraft, TicketGeneralFields, TimelineItemGeneralField, TimelineItemGeneralFieldValue, TimelineItemCommentValue, TimelineItemComment, Notification, TimelineItemMessage, TimelineItemCommon } from 'ta-models';
import { TimelineItemTaskValue, TimelineItemTask } from 'ta-models';

export interface IItemTip {
  title?: string,
  messageTemplate?: string,
  type?: Notification.NOTIFICATION_TYPE,
  trigger: {
    query: { fieldPath: string, opStr: any, value: string | Date | boolean }[],
    type: 'EXISTS' | 'DOES_NOT_EXIST'
  },
  actions?: {
    title: string,
    action: string,
    option?: { [key: string]: any },
    type?: string,
  }[],
  done?: boolean
}

export const getTicketStatusURL = (id: string) => {
  return `${environment.url}/requests/timeline/${id}`;
}

export const TicketHelper = {
  hasDirectAccess: (permissions: NgxPermissionsObject, currentUser: User.Object, ticket: Ticket.Common) => {
    const attributedTo = Ticket.helper.getAttributedTo(ticket);
    const owner = Ticket.helper.getOwner(ticket);
    const client = Ticket.helper.getClientId(ticket);
    if (permissions[UserPermissions.USER_PERMISSIONS.TICKET_ACCESS_ALL]) {
      return true;
    }
    if (permissions[UserPermissions.USER_PERMISSIONS.TICKET_ACCESS_ATTRIBUTED_TO] && (attributedTo === currentUser?.computed_id || owner === currentUser?.computed_id)) {
      return true;
    }
    if (!attributedTo && currentUser?.params?.managedClientIds?.includes(client)) return true;
    if (permissions[UserPermissions.USER_PERMISSIONS.INBOX_ACCESS_ALL] && !attributedTo) {
      return true;
    }
    return false;
  },
  // hasDirectAccessV2

  alogliaTicketToTicketItem: (h: AlgoliaTicket): TicketItem | null => {
    if (!h) return null;
    const state = h.state && {
      key: h.state.key,
      value: h.state.value && new Date(h.state.value)
    }
    return {
      id: h.objectID,
      title: h.title,
      entities: h.entities,
      createdAt: new Date(h.createdAt),
      updatedAt: new Date(h.updatedAt),
      clientId: h.client && h.client.id,
      serviceId: h.service && h.service.id,
      procedureId: h.procedures?.length > 0 && h.procedures[0],
      agentId: h.agentId,
      ownerId: h.ownerId,
      state: state,
      deadline: h.deadline && new Date(h.deadline),
    }
  },
  ticketObjectToTicketItem: (t: Ticket.Object): TicketItem | null => {
    if (!t) return null;
    return {
      id: t.computed_id,
      title: Ticket.helper.getTitle(t),
      entities: [],
      createdAt: t.createdAt,
      updatedAt: t.updatedAt,
      clientId: Ticket.helper.getClientId(t),
      serviceId: Ticket.helper.getServiceId(t),
      agentId: Ticket.helper.getAttributedTo(t),
      ownerId: Ticket.helper.getOwner(t),
      deadline: Ticket.helper.getDeadline(t),
      state: t.state,
      _notificationsCount: t._notificationsCount
    }
  },
  getTaskDuration: (task: TimelineItemTaskValue.Object): (number|null) => {
    if (task?.type !== TimelineItemTask.TICKET_TASK_TYPE.CUSTOM) return null;
    //Task running
    if (task.start) return Math.floor((Date.now() - task.start.getTime()) / 1000 + (task.duration || 0));
    //Task paused
    else return task.duration;
  },
  getTaskQuantity: (task: TimelineItemTaskValue.Object): (number|null) => {
    if (task.done && task.quantity) return task.quantity;
    const duration = TicketHelper.getTaskDuration(task);
    if (duration >= 0) {
      const rating = task.rating;
      return Ticket.helper.DURATION_TO_QUANTITY(duration, rating)
    } else {
      return null;
    }
  }
}

