import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from "../../common";

export namespace PaymentDetails {

  export interface Common {
        quantity?: number,
        subscriptionItemId?: string,
        stripeMburId?: string,
        billed?: allDateTypes|null,
        creditItemIds?: string[],
        unitPrice?: number
  }

  export interface DBObject extends Common {
    billed?: dbDateTypes|null,
  }

  export interface Object extends Common {
    billed?: Date|null,
  }

  export interface DBUpdate extends Common {
    billed?: dbDateUpdateTypes|null
  }

  export const helper = {
    toObject: (dbObject: DBObject): Object => {
      const object: Object = {
        ...dbObject,
        billed: dbObject?.billed?.toDate && dbObject?.billed?.toDate(),
      }
      return object
    },
  }
}