import { throwError as observableThrowError, Observable, of, combineLatest } from 'rxjs';
import { switchMap, map, distinctUntilChanged, take, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Message, Attachment, Email } from 'src/app/models/message';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { STATIC_VAULT_ITEMS } from 'src/app/user/user-vault/user-vault.static-items';
import { Team } from 'src/app/models/team';
import { Ticket, PublicTicket } from 'src/app/models/ticket';
import { VaultItem, Calendar, UserAccount, UserRoles, User, AgentParams, Preferences, General, Partner } from 'src/app/models/user';
import { Encoder } from 'src/app/models/shared';
import firebase from 'firebase/compat/app';
import { Stripe as stripe_server } from 'stripe';
import { LightMessageValue, UserAnalytics } from 'ta-models/dist';
import { NgxPermissionsService } from 'ngx-permissions';
import { Procedure } from 'ta-models/src/procedure';

@Injectable({
  providedIn: "root"
})
export class FirebaseService {
  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private http: HttpClient,
    private permissionService: NgxPermissionsService
  ) {
    this.staticVaultItems = STATIC_VAULT_ITEMS(this.localeId);
  }

  currentUser(): Observable<User.Object | null> {
    return this.afAuth.authState.pipe(
      switchMap((user) => {
        const uid = user && user.uid;
        if (uid) {
          return this.userWithUid(uid);
        }
        return of(null);
      })
    );
  }

  userWith_Email(_email: string): Observable<User.Object | null> {
    const email = Encoder.decodeKey(_email);
    return this.userWithEmail(email);
  }

  private __userWithEmail$: { [_email: string]: Observable<User.Object> } = {}
  userWithEmail(email: string): Observable<User.Object | null> {
    const cleanEmail = User.helper.getCleanEmail(email, environment.production);
    const _cleanEmail = Encoder.encodeKey(cleanEmail);
    if (!this.__userWithEmail$[_cleanEmail]) {
      this.__userWithEmail$[_cleanEmail] = this.db.collection<User.DBObject>('users', ref => ref.where(`_emails.${_cleanEmail}`, '>=', 0).limit(1)).snapshotChanges().pipe(
        map(actions => {
          const action = actions?.length && actions?.[0];
          if (action)
            return User.helper.toObject(action.payload.doc.id, action.payload.doc.data())
          return null;
        }),
        shareReplay(1)
      );
    }
    return this.__userWithEmail$[_cleanEmail];
  }

  private __userWithPhone$: { [phone: string]: Observable<User.Object> } = {}
  userWithPhone(phone: string): Observable<User.Object | null> {
    if (!this.__userWithPhone$[phone]) {
      this.__userWithPhone$[phone] = this.db.collection<User.DBObject>('users', ref => ref.where('phones', 'array-contains', phone).limit(1)).snapshotChanges().pipe(
        map(actions => {
          const action = actions?.length && actions?.[0];
          if (action)
            return User.helper.toObject(action.payload.doc.id, action.payload.doc.data())
          return null;
        }),
        shareReplay(1)
      );
    }
    return this.__userWithPhone$[phone];
  }

  private __userWithUid$: { [uid: string]: Observable<User.Object> } = {};
  userWithUid(uid: string): Observable<User.Object | null> {
    if (!this.__userWithUid$[uid]) {
      this.__userWithUid$[uid] = this.db.doc<User.DBObject>(`users/${uid}`).valueChanges().pipe(
        map(user => User.helper.toObject(uid, user)),
        shareReplay(1)
      );
    }
    return this.__userWithUid$[uid];
  }

  postHttp(functionName: string, params?: HttpParams, body?: any, headers: HttpHeaders = undefined): Observable<Object> {
    const url = `${environment.firebaseCloudFunctionURL}/${functionName}`;
    return this.http.post(url, body,
      { params: params, headers }
    ).pipe(
      take(1)
    );
  }

  postHttpWithAuth(functionName: string, params?: HttpParams, body?: any): Observable<Object> {
    return this.afAuth.authState.pipe(
      switchMap((user) => {
        return user ? user.getIdToken() : observableThrowError("User not authenticated");
      }),
      switchMap((token: string) => {
        const url = `${environment.firebaseCloudFunctionURL}/${functionName}`;
        return this.http.post(url, body,
          { params: params, headers: new HttpHeaders().set('Authorization', token) }
        );
      }),
      take(1)
    );
  }

  deleteHttpWithAuth(functionName: string, params?: HttpParams): Observable<Object> {
    return this.afAuth.authState.pipe(
      switchMap((user) => {
        return user ? user.getIdToken() : observableThrowError("User not authenticated");
      }),
      switchMap((token: string) => {
        const url = `${environment.firebaseCloudFunctionURL}/${functionName}`;
        return this.http.delete(url,
          { params: params, headers: new HttpHeaders().set('Authorization', token) }
        );
      }),
      take(1)
    );
  }

  getHttp(functionName: string, params: HttpParams = undefined, headers: HttpHeaders = undefined, noCache = false): Observable<Object> {
    if (noCache) {
      let timeStamp = (new Date()).getTime().toString();
      params = (params || new HttpParams()).set("tsp", timeStamp);
    }
    const url = `${environment.firebaseCloudFunctionURL}/${functionName}`;
    return this.http.get(url, { params: params, headers: headers });
  }

  getHttpWithAuth(functionName: string, params: HttpParams = undefined, noCache = false): Observable<Object> {
    return this.afAuth.authState.pipe(
      switchMap((user) => {
        return user ? user.getIdToken() : observableThrowError("User not authenticated");
      }),
      switchMap((token: string) => {
        return this.getHttp(functionName, params, new HttpHeaders().set('Authorization', token), noCache);
      }),
      take(1)
    );
  }

  emailVerified(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      map((user) => !!(user && user.emailVerified))
    )
  }

  private __getTicket$: { [id: string]: Observable<Ticket.Object> } = {}
  getTicket(id: string): Observable<Ticket.Object> {
    if (typeof id !== "string") return of(null);
    if (this.__getTicket$[id]) return this.__getTicket$[id];
    const ticketDoc = this.db.doc<Ticket.DBObject>(`tickets/${id}`);
    this.__getTicket$[id] = ticketDoc.valueChanges().pipe(
      map(t => Ticket.helper.toObject(id, t)),
      shareReplay(1)
    );
    return this.__getTicket$[id];
  }

  getThirdPartyAccount(userId: string, category: UserAccount.USER_ACCOUNT_CATEGORY, provider?: string, scope?: string): Observable<UserAccount.Object[]> {
    if (typeof userId !== "string") return of(null);
    let query = this.db.collection<UserAccount.DBObject>(`users/${userId}/thirdParties`, ref => ref.where('category', '==', category));
    return query.snapshotChanges().pipe(
      map(actions => actions.map(a => UserAccount.helper.toObject(a.payload.doc.id, a.payload.doc.data(), userId, a.payload.doc.ref))),
      shareReplay(1)
    );
  }

  getThirdPartyAccountByType(userId: string, category: UserAccount.USER_ACCOUNT_CATEGORY, scope?: string): Observable<UserAccount.Object[]> {
    if (typeof userId !== "string") return of(null);
    let query = this.db.collection<UserAccount.DBObject>(`users/${userId}/thirdParties`, ref => ref.where('category', '==', category));
    return query.snapshotChanges().pipe(
      map(actions => actions.map(a => UserAccount.helper.toObject(a.payload.doc.id, a.payload.doc.data(), userId, a.payload.doc.ref))),
      shareReplay(1)
    );
  }

  getTicketUniqueEmail$(ticket: Ticket.Common): Observable<Email.Common> {
    const userId = Ticket.helper.getClientId(ticket);
    const shortId = ticket?.shortId;
    if (userId && shortId) {
      return this.getUser(userId, { assistant: true }).pipe(
        map(u => {
          if (!u) return null;
          const email = u?.computed_assistant?.computed_defaultEmail;
          const name = u?.computed_assistant?.firstName;
          return { address: User.helper.getUniqueEmail(email, `t${shortId}`), name };
        }),
        shareReplay(1)
      );
    }
    return of(null);
  }

  getAssistantEmail$(userId: string): Observable<Email.Common[]> {
    return this.getUser(userId, { assistant: true }).pipe(
      map(u => {
        const computed_assistant = u.computed_assistant || {};
        const fullEmail = computed_assistant.computed_fullEmail;
        const defaultEmail = computed_assistant.computed_defaultEmail;
        const name = u?.computed_assistant?.firstName;
        const emails = fullEmail.toLowerCase() === defaultEmail.toLowerCase() ? [fullEmail] : [fullEmail, defaultEmail]
        return emails.map(address => { return { name, address } });
      })
    )
  }

  getTicketAssistantEmails$(ticket: Ticket.Object): Observable<Email.Common[]> {
    const clientId = Ticket.helper.getClientId(ticket);
    if (clientId) {
      return this.getAssistantEmail$(clientId);
    }
  }

  private staticVaultItems: VaultItem.Object[];
  getUserVaultItems(id: string): Observable<VaultItem.Object[]> {
    if (!id) return of([])
    return this.getUserTeams(id).pipe(
      switchMap(teams => {
        const teamIds = teams.map(team => team.computed_id);
        const teamId = teamIds?.[0];
        // const teamVaultSnaps = teamIds.map(teamId => this.db.collection<VaultItem.DBObject>(`teams/${teamId}/vault`).snapshotChanges());
        // const userVaultSnap = this.db.collection<VaultItem.DBObject>(`users/${id}/vault`).snapshotChanges();
        // const vaultItems = [userVaultSnap, ...teamVaultSnaps];
        const acl = [id];
        if (teamId) acl.push(teamId);
        return this.db.collectionGroup<VaultItem.DBObject>(`vault`, ref => ref.where('acl', 'array-contains-any',acl)).snapshotChanges().pipe(
          map(actions => actions.reduce((acc, val) => acc.concat(val), [])),
          map((actions) => {
            return actions.map(a => {
              return VaultItem.helper.toObject(a.payload.doc.id, a.payload.doc.data(), {
                ownerId: a?.payload?.doc?.ref?.parent?.parent.id
              });
            })
          }),
          map(items => {
            /* Add static items which are not already saved*/
            const toAdd = this.staticVaultItems.filter(i1 => !items.some(i2 => i2.computed_id === i1.computed_id || i2.computed_id?.replace(/@.*/, '') === i1.computed_id?.replace(/@.*/, ''))).map(item => {
              return { ...item, computed_id: item?.computed_id?.replace('{{userId}}', id) }
            });
            return items.concat(toAdd);
          }),
          shareReplay(1)
        )
      })
    );
  }

  private _getExtraVaultItems$: { [userId: string]: Observable<VaultItem.Object[]> } = {};
  getExtraVaultItems$(userId: string): Observable<VaultItem.Object[]> {
    if (!this._getExtraVaultItems$[userId]) {
      const paymentMethods$ = this.db.collection<any>(`users/${userId}/thirdParties/stripe/payment_methods`).valueChanges();
      const userCalendarAccounts$ = this.getThirdPartyAccount(userId, UserAccount.USER_ACCOUNT_CATEGORY.CALENDAR);
      this._getExtraVaultItems$[userId] = combineLatest([paymentMethods$, userCalendarAccounts$, this.permissionService.permissions$]).pipe(
        map(([paymentMethods, userCalendarAccounts, permissions]) => {
          const items: VaultItem.Object[] = [];
          const cardItem = { ...this.staticVaultItems.find(item => item.computed_id === VaultItem.VAULT_ITEM_GENERAL_IDS.PAYMENT_CARDS), readOnly: true };
          const calendarItem = this.staticVaultItems.find(item => item.computed_id === VaultItem.VAULT_ITEM_CALENDAR_IDS.CALENDAR_LINK);
          if (paymentMethods.find(paymentMethod => paymentMethod.type === 'card')) items.push(cardItem);
          const cronofy = userCalendarAccounts?.[0]?.data;
          if (cronofy?.account_id) items.push(calendarItem);
          return items.filter(item => !!permissions?.[item?.computed_permission]);
        }),
        shareReplay(1)
      )
    }
    return this._getExtraVaultItems$[userId];
  }

  private __getProcedureByReference$: { [id: string]: Observable<Procedure.Object> } = {};
  getProcedureByReference(ref: firebase.firestore.DocumentReference<Procedure.DBObject>): Observable<any> {
    if (!ref?.id) return of(null);
    if (!this.__getProcedureByReference$[ref?.id]) {
      this.__getProcedureByReference$[ref.id] = this.db.doc<Procedure.DBObject>(ref).valueChanges().pipe(
        map((procedure) => Procedure.helper.toObject(ref.id, procedure), ref),
        shareReplay(1)
      )
    }
    return this.__getProcedureByReference$[ref.id];
  }

  private __getVaultItemByReference$: { [id: string]: Observable<VaultItem.Object> } = {};
  getVaultItemByReference(ref: firebase.firestore.DocumentReference<VaultItem.DBObject>): Observable<VaultItem.Object> {
    if (!ref?.id) return of(null);
    if (!this.__getVaultItemByReference$[ref.id]) {
      this.__getVaultItemByReference$[ref.id] = this.db.doc<VaultItem.DBObject>(ref).valueChanges().pipe(
        map((vaultItem) => vaultItem && VaultItem.helper.toObject(ref.id, vaultItem, { ownerId: ref?.parent?.parent?.id })),
        shareReplay(1),
      )
    }
    return this.__getVaultItemByReference$[ref.id];
  }


  //Temp will be remove after changing User Vault model
  getUserVaultItemById(userId: string, vaultItemId: string): Observable<any> {
    if (!userId || !vaultItemId) return of(null)
    return this.getUserTeams(userId).pipe(
      switchMap(teams => {
        const teamIds = teams.map(team => team.computed_id);
        const teamVaultSnaps = teamIds.map(teamId => this.db.collection(`teams/${teamId}/vault/`).doc<VaultItem.DBObject>(vaultItemId).snapshotChanges());
        const userVaultSnap = this.db.collection(`users/${userId}/vault`).doc<VaultItem.DBObject>(vaultItemId).snapshotChanges();
        const vaultItems = [userVaultSnap, ...teamVaultSnaps];
        return combineLatest(vaultItems).pipe(
          map((actions) => {
            return actions.filter(a => a.payload.exists).map(a => {
              return VaultItem.helper.toObject(vaultItemId, a.payload.data(), { ownerId: a?.payload?.ref?.parent?.parent.id })
            });
          }),
          map(items => items[0]),
          shareReplay(1)
        )
      })
    )
  }

  private __getUserProcedures$: { [userId: string]: Observable<Procedure.Object[]> } = {};
  getUserProcedures(userId: string): Observable<Procedure.Object[]> {
    if (!this.__getUserProcedures$[userId]) {
      this.__getUserProcedures$[userId] = this.getUserTeams(userId).pipe(
        switchMap(teams => {
          const teamId = teams.map(team => team?.computed_id).find(Boolean);
          const acl = [userId];
          teamId && acl.push(teamId);
          return this.db.collectionGroup<Procedure.DBObject>('procedures', ref => ref.where('acl', 'array-contains-any', acl)).snapshotChanges().pipe(
            map((actions) => actions.map(a => Procedure.helper.toObject(a.payload.doc.id, a.payload.doc.data(), a.payload.doc.ref)))
          );
        }),
        shareReplay(1)
      )
    }
    return this.__getUserProcedures$[userId];
  }

  private __getUserTeams$: { [userId: string]: Observable<Team.Object[]> } = {};
  getUserTeams(userId: string): Observable<Team.Object[]> {
    if (!this.__getUserTeams$[userId]) {
      this.__getUserTeams$[userId] = this.db.collection<Team.DBObject>('teams', ref => ref.where(`members.${userId}`, '>=', 0)).snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            return a.payload.doc.data() && Team.helper.toObject(a.payload.doc.id, a.payload.doc.data());
          });
        }),
        shareReplay(1)
      );
    }
    return this.__getUserTeams$[userId];
  }

  private __getUserTeamOwnerId$: { [userId: string]: Observable<string> } = {};
  getUserTeamOwnerId(userId: string): Observable<string> {
    if (!this.__getUserTeamOwnerId$[userId]) {
      this.__getUserTeamOwnerId$[userId] = this.getUserTeams(userId).pipe(
        map((teams) => {
          const team = teams?.[0];
          if (team) return Team.helper.getTeamOwner(team);
          else return userId;
        })
      );
    }
    return this.__getUserTeamOwnerId$[userId];
  }

  private __getUserTeamOwner$: { [userId: string]: Observable<User.Object> } = {};
  getUserTeamOwner(userId: string): Observable<User.Object> {
    if (!this.__getUserTeamOwner$[userId]) {
      this.__getUserTeamOwner$[userId] = this.getUserTeamOwnerId(userId).pipe(switchMap(id => this.getUser(id)));
    }
    return this.__getUserTeamOwner$[userId];
  }

  private __getUser$: { [key: string]: Observable<User.Object> } = {}
  getUser(id: string, opts: { assistant?: boolean, vaultItems?: boolean, preferences?: boolean, thirdParties?: boolean } = {}): Observable<User.Object> {
    const key = `${id}-${opts.vaultItems || false}-${opts.preferences || false}-${opts.thirdParties || false}`;
    if (this.__getUser$[key]) return this.__getUser$[key];
    if (typeof id !== "string") return of(null);
    let vaultItems$: Observable<VaultItem.Object[]> = of([]);
    if (opts.vaultItems) {
      vaultItems$ = this.getUserVaultItems(id);
    }
    let preferences$: Observable<{ 
      [Preferences.PREFERENCES_CATEGORY.CALENDAR]: Calendar.Preferences, 
      [Preferences.PREFERENCES_CATEGORY.GENERAL]: General.Preferences, 
      [Preferences.PREFERENCES_CATEGORY.PARTNER]: Partner.Preferences 
    }> = of({ [Preferences.PREFERENCES_CATEGORY.CALENDAR]: null, [Preferences.PREFERENCES_CATEGORY.GENERAL]: null, [Preferences.PREFERENCES_CATEGORY.PARTNER]: null});
    if (opts.preferences) {
      preferences$ = this.db.collection<Preferences.DBObject>(`users/${id}/preferences`).valueChanges().pipe(
        map(preferences => preferences.reduce((a, b) => {
          const category = b.category;
          if (category) a[category] = b.data;
          return a;
        }, {} as { [Preferences.PREFERENCES_CATEGORY.CALENDAR]: Calendar.Preferences, [Preferences.PREFERENCES_CATEGORY.GENERAL]: General.Preferences, [Preferences.PREFERENCES_CATEGORY.PARTNER]: Partner.Preferences })
        ),
      );
    }
    let thirdPartiesAccounts$: Observable<{ [key: string]: string }> = of({});
    let hasPaymentMethod$ = this.db.collection<stripe_server.PaymentMethod>(`users/${id}/thirdParties/stripe/payment_methods`, ref => ref.where('type', '==', 'card')).valueChanges().pipe(map(paymentMethods => paymentMethods?.length > 0));
    if (opts.thirdParties) {
      thirdPartiesAccounts$ = this.db.collection<UserAccount.DBObject>(`users/${id}/thirdParties`).valueChanges().pipe(
        map(userAccounts => userAccounts.reduce((a, b) => {
          const provider = b.provider;
          if (provider && b.uid) a[provider] = b.uid;
          return a;
        }, {} as { [key: string]: string })
        ),
      );
    }

    this.__getUser$[key] = combineLatest(
      [this.db.doc<User.DBObject>(`users/${id}`).valueChanges(),
        vaultItems$,
        preferences$,
        thirdPartiesAccounts$,
        hasPaymentMethod$
      ]).pipe(
        map(([u, vaulItems, preferences, thirdPartiesAccounts, hasPaymentMethod]) => {
          if (!u) return null;
          return User.helper.toObject(id, u, {
            assistant: User.helper.getAssistant(u, environment.production),
            userAccountIds: thirdPartiesAccounts,
            hasPaymentMethod,
            preferences: preferences,
            vaultItems: vaulItems.reduce((a, b) => {
              a[b.computed_id] = b; return a;
            }, {} as { [key: string]: VaultItem.Object })
          });
        }),
        shareReplay(1)
      );
    return this.__getUser$[key];
  }

  private __getAgentParams$: { [userId: string]: Observable<AgentParams.Object> } = {}
  getAgentParams(userId: string): Observable<AgentParams.Object> {
    if (typeof userId !== "string") return of(null);
    if (!this.__getAgentParams$[userId]) {
      this.__getAgentParams$[userId] = this.db.collection<AgentParams.DBObject>(
        `users/${userId}/agentParams`,
        ref => ref.orderBy('startAt', 'desc').limit(1)
      ).snapshotChanges().pipe(
        map(snaps => snaps.length && AgentParams.helper.toObject(snaps[0].payload.doc.id, snaps[0].payload.doc.data())),
        shareReplay(1)
      );
    }
    return this.__getAgentParams$[userId];
  }

  getAgentParamsEfficiency$(userId: string, clientId: string): Observable<number> {
    return combineLatest([this.getAgentParams(userId), this.getUserTeamOwnerId(clientId)]).pipe(
      map(([getAgentParams, teamOwnerId]) => {
        return getAgentParams?.efficiency?.[teamOwnerId] || getAgentParams?.efficiency?.DEFAULT || Ticket.TICKET_TIMER_DEFAULT;
      })
    )
  }

  private __getUserAnalytics$: { [userId: string]: Observable<UserAnalytics.Object> } = {}
  getUserAnalytics(userId: string): Observable<UserAnalytics.Object> {
    if (typeof userId !== "string") return of(null);
    if (!this.__getUserAnalytics$[userId]) {
      this.__getUserAnalytics$[userId] = this.db.doc<UserAnalytics.DBObject>(`users/${userId}/details/analytics`).valueChanges().pipe(
        map(userAnalytics => UserAnalytics.helper.toObject(userId, userAnalytics)),
        shareReplay(1));
    }
    return this.__getUserAnalytics$[userId];
  }

  private __getPublicUser$: { [userId: string]: Observable<Partial<User.Object>> } = {}
  getPublicUser(userId: string): Observable<Partial<User.Object>> {
    if (typeof userId !== "string") return of(null);
    if (!this.__getPublicUser$[userId]) {
      this.__getPublicUser$[userId] = this.db.doc<Partial<User.DBObject>>(`users/${userId}/public/profile`).valueChanges().pipe(
        map(user => User.helper.toObject(userId, user)),
        shareReplay(1));
    }
    return this.__getPublicUser$[userId];
  }

  private __getPublicTicket$: { [ticketId: string]: Observable<PublicTicket.Object> } = {}
  getPublicTicket(ticketId: string): Observable<PublicTicket.Object> {
    if (typeof ticketId !== "string") return of(null);
    if (!this.__getPublicTicket$[ticketId]) {
      this.__getPublicTicket$[ticketId] = this.db.doc<PublicTicket.DBObject>(`_publicTickets/${ticketId}`).valueChanges().pipe(
        map(t => PublicTicket.helper.toObject(ticketId, t)),
        shareReplay(1)
      );
    }
    return this.__getPublicTicket$[ticketId];
  }

  currentUserRole(): Observable<UserRoles.Roles | null> {
    return this.currentUser().pipe(map(u => u && u.roles), shareReplay(1));
  }

  async currentUserUid(): Promise<string> {
    const currentUser = await this.afAuth.currentUser;
    return currentUser.uid;
  }

  async refreshToken(): Promise<string> {
    const currentUser = await this.afAuth.currentUser;
    console.info('[refresh user token]');
    return currentUser && currentUser.getIdToken(true);
  }

  currentUserCustomClaims$() {
    return this.afAuth.idTokenResult.pipe(
      map(r => r?.claims)
    )
  }

  currentUserId(): Observable<string | null> {
    return this.afAuth.authState.pipe(
      map(user => user && user.uid),
      distinctUntilChanged()
    );
  }

  private __getMessage$: { [key: string]: Observable<Message.Object | null> } = {};
  getMessage(messageId: string, getAttachments?: boolean): Observable<Message.Object> {
    const key = `${messageId}-${getAttachments || false}`;
    if (this.__getMessage$[key]) return this.__getMessage$[key];
    if (!messageId) { return of(null); }
    const messageDoc = this.db.doc<Message.DBObject>(`/messages/${messageId}`);
    this.__getMessage$[key] = messageDoc.valueChanges().pipe(
      switchMap(m => {
        if (m) {
          if (getAttachments) {
            return messageDoc.collection<Attachment.DBObject>(`attachments`).snapshotChanges().pipe(
              map((snapshots) => snapshots.map(s => Attachment.helper.toObject(s.payload.doc.id, s.payload.doc.data()))),
              map((attachments) => Message.helper.toObject(messageId, m, attachments))
            )
          } else {
            return of(Message.helper.toObject(messageId, m));
          }
        } else {
          return of(null);
        }
      }),
      shareReplay(1)
    );
    return this.__getMessage$[key];
  }


  private __getMsg$: { [key: string]: Observable<Message.Object | null> } = {};
  getMsg(msgId: string): Observable<LightMessageValue.Object> {
    const key = msgId;
    if (this.__getMsg$[key]) return this.__getMsg$[key];
    if (!msgId) { return of(null); }
    const msgDoc = this.db.doc<LightMessageValue.DBObject>(`/msgs/${msgId}`);
    this.__getMsg$[key] = msgDoc.valueChanges().pipe(
      switchMap(m => {
        if (m) return of(LightMessageValue.helper.toObject(msgId, m));
        else {
          return of(null);
        }
      }),
      shareReplay(1)
    );
    return this.__getMsg$[key];
  }

  private __getAlgoliaFilter$: { [userId: string]: Observable<string> } = {};
  getAlgoliaFilter$(userId: string): Observable<string> {
    if (this.__getAlgoliaFilter$[userId]) return this.__getAlgoliaFilter$[userId];
    let filter = ['acl:SYSTEM']
    if (userId) filter.push(`acl:${userId}`);
    this.__getAlgoliaFilter$[userId] = this.getUserTeams(userId).pipe(
      map(teams => {
        const teamId = teams?.find(Boolean)?.computed_id;
        if (teamId) filter.push(`acl:${teamId}`);
        return filter.join(' OR ').concat(' AND isLive=1');
      }),
      shareReplay(1)
    );
    return this.__getAlgoliaFilter$[userId];
  }

}

