import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AlgoliaService, AlgoliaUnifiedModel, PROCEDURES_ALGOLIA_INDEX, VAULT_ALGOLIA_INDEX } from 'src/app/shared/services/algolia.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SearchResponse } from '@algolia/client-search';
import { AlgoliaProcedure, AlgoliaTemplate, AlgoliaTicket, AlgoliaUsers, AlgoliaVault } from 'src/app/models/third-parties/algolia';
import { TICKETS_ALGOLIA_INDEX, USERS_ALGOLIA_INDEX, TEMPLATES_ALGOLIA_INDEX } from 'src/app/shared/services/algolia.service';
import { Router } from '@angular/router';
import { NzAutocompleteComponent } from 'ng-zorro-antd/auto-complete';

export interface AutocompleteOptionGroups {
  title: string;
  desc?: string;
  route: string[];
  children?: AutocompleteOptionGroups[];
}

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  public readonly query$ = new BehaviorSubject<string>('');
  public optionGroups$: Observable<AutocompleteOptionGroups[]>;
  @ViewChild('auto') auto: NzAutocompleteComponent;
  @ViewChild('input') input: ElementRef;

  constructor(private algoliaService: AlgoliaService, private router: Router) { }

  ngOnInit() {
    this.optionGroups$ = this.query$.asObservable().pipe(
      switchMap((query) => query && this.algoliaService.searchMultiple(query) || of(null)),
      map(r => {
        let results = r?.results as Partial<SearchResponse>[];
        if (!results) {
          results = [{ index: TICKETS_ALGOLIA_INDEX }, { index: USERS_ALGOLIA_INDEX }, { index: USERS_ALGOLIA_INDEX }, { index: VAULT_ALGOLIA_INDEX }, { index: TEMPLATES_ALGOLIA_INDEX }];
        }
        return results.map(r => {
          return {
            title: r.index,
            route: this.getSearchRoute(r.index),
            children: r.hits?.map(h => this.getAutocompleteOptionGroups(h, r.index))
          }
        }) || [];
      })
    );
  }

  getSearchRoute(index: string) {
    return ['/', index];
  }

  getAutocompleteOptionGroups(hit: AlgoliaUnifiedModel, index: string): AutocompleteOptionGroups {
    if (index === TICKETS_ALGOLIA_INDEX) {
      const _hit = hit as AlgoliaTicket;
      return { title: _hit.title, desc: _hit.client?.fullName, route: ['/', 'ticket', _hit.objectID] };
    }
    if (index === USERS_ALGOLIA_INDEX) {
      const _hit = hit as AlgoliaUsers;
      return { title: _hit.fullName, desc: _hit.emails?.[0], route: ['/', 'user', _hit.objectID] };
    }
    if (index === PROCEDURES_ALGOLIA_INDEX) {
      const _hit = hit as AlgoliaProcedure;
      return { title: _hit.name, desc: _hit.isLive && 'live', route: ['/', 'procedures', _hit.objectID] };
    }
    if (index === VAULT_ALGOLIA_INDEX) {
      const _hit = hit as AlgoliaVault;
      return { title: _hit.name, desc: _hit.isLive && 'live', route: ['/', 'vault', _hit.objectID] };
    }
    if (index === TEMPLATES_ALGOLIA_INDEX) {
      const _hit = hit as AlgoliaTemplate;
      return { title: _hit.name, desc: _hit.desc, route: ['/', 'templates', _hit.objectID] };
    }
  }

  goto(option: AutocompleteOptionGroups, event: MouseEvent) {
    event.stopPropagation();
    this.input?.nativeElement?.blur?.();
    this.auto.showPanel = false;
    this.router.navigate(option.route);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.input?.nativeElement?.blur?.();
  }

}