import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { VersionComponent } from './version.component';

@NgModule({
  imports: [
    CommonModule,
    NzModalModule,
    NzAlertModule,
    NzNotificationModule,
    NzButtonModule
  ],
  declarations: [
    VersionComponent
  ],
  exports: [
    VersionComponent
  ]
})
export class VersionModule { }
