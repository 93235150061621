export namespace Calendar {

  export interface Slot {
    start: number,
    end: number
  }
  
  export interface Preferences {
    main?: string,
    selected?: { [key: string]: boolean },
    conferencing?: { [key: string]: boolean },
    tz?: string,
    businessHours?: {
      default: PreferencesWeekSlot,
      breakfast: PreferencesWeekSlot,
      coffee: PreferencesWeekSlot,
      lunch: PreferencesWeekSlot,
      dinner: PreferencesWeekSlot,
      drinks: PreferencesWeekSlot,
    },
    meetingDurations?: {
      breakfast: number,
      coffee: number,
      dinner: number,
      drinks: number,
      inPerson: number,
      lunch: number,
      virtual: number
    },
    meetingBreatherTimes?: {
      breakfast: number,
      coffee: number,
      dinner: number,
      drinks: number,
      inPerson: number,
      lunch: number,
      virtual: number
    },
    meetingLocations?: MeetingLocations[]
  }
  
  export interface MeetingLocations {
    address: string,
    instructions: string,
    types: string[],
    name: string
  }
  
  export interface PreferencesWeekSlot {
    sunday: Slot[],
    monday: Slot[],
    tuesday: Slot[],
    wednesday: Slot[],
    thursday: Slot[],
    friday: Slot[],
    saturday: Slot[]
  }
  
  export interface BusinessHoursCollapsable {
    default: boolean,
    breakfast: boolean,
    lunch: boolean,
    coffee: boolean,
    dinner: boolean,
    drinks: boolean,
  }

  export const CALENDAR_DEFAULT_TYPE = 'inPerson';
  export const CALENDAR_DEFAULT_BUSINESS_HOUR_TYPE = 'default';
  export const CALENDAR_DEFAULT: Partial<Preferences> = {
    businessHours: {
      default : {
        sunday: [],
        monday: [{start: 540,end: 1140}],
        tuesday: [{start: 540,end: 1140}],
        wednesday: [{start: 540,end: 1140}],
        thursday: [{start: 540,end: 1140}],
        friday: [{start: 540,end: 1140}],
        saturday: []
      },
      breakfast : {
        sunday: [],
        monday: [{start: 510,end: 570}],
        tuesday: [{start: 510,end: 570}],
        wednesday: [{start: 510,end: 570}],
        thursday: [{start: 510,end: 570}],
        friday: [{start: 510,end: 570}],
        saturday: []
      },
      coffee : {
        sunday: [],
        monday: [{start: 870,end: 870}],
        tuesday: [{start: 870,end: 870}],
        wednesday: [{start: 870,end: 870}],
        thursday: [{start: 870,end: 870}],
        friday: [{start: 870,end: 870}],
        saturday: []
      },
      lunch : {
        sunday: [],
        monday: [{start: 750,end: 810}],
        tuesday: [{start: 750,end: 810}],
        wednesday: [{start: 750,end: 810}],
        thursday: [{start: 750,end: 810}],
        friday: [{start: 750,end: 810}],
        saturday: []
      },
      dinner : {
        sunday: [],
        monday: [{start: 1170,end: 1260}],
        tuesday: [{start: 1170,end: 1260}],
        wednesday: [{start: 1170,end: 1260}],
        thursday: [{start: 1170,end: 1260}],
        friday: [{start: 1170,end: 1260}],
        saturday: []
      },
      drinks : {
        sunday: [],
        monday: [{start: 1170,end: 1260}],
        tuesday: [{start: 1170,end: 1260}],
        wednesday: [{start: 1170,end: 1260}],
        thursday: [{start: 1170,end: 1260}],
        friday: [{start: 1170,end: 1260}],
        saturday: []
      }
    },
    meetingDurations: {
      breakfast: 60,
      coffee: 45,
      dinner: 120,
      drinks: 120,
      inPerson: 45,
      lunch: 90,
      virtual: 30
    },
    meetingBreatherTimes: {
      breakfast: 15,
      coffee: 15,
      dinner: 15,
      drinks: 15,
      inPerson: 15,
      lunch: 15,
      virtual: 15
    }
  }
}
