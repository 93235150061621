import { allDateTypes, dbDateUpdateTypes, dbDateTypes } from "../../common";

export namespace TicketState {

  export interface Common {
    key?: _TICKET_STATE,
    value?: allDateTypes
  }

  export interface DBObject extends Common {
    value?: dbDateTypes
  }

  export interface Object extends Common {
    value?: Date
  }

  export interface DBUpdate extends Common {
    value?: dbDateUpdateTypes
  }

  export const helper = {
    toObject: (dbObject: DBObject): Object => {
      return {
        ...dbObject,
        value: dbObject?.value?.toDate && dbObject?.value?.toDate()
      }
    },
  }

  export enum _TICKET_STATE {
    /** When the ticket is open */
    OPEN = "OPEN",
    /** When a task is scheduled */
    SNOOZED = "SNOOZED",
    /** When the agent waits for the client or third party to come back */
    PENDING = "PENDING",
    /** When the agent needs help */
    HELP_NEEDED = "HELP_NEEDED",
    /** When the ticket is closed */
    CLOSED = "CLOSED",
    /** When the ticket is deleted */
    ARCHIVED = "ARCHIVED"
  }

  export enum TICKET_ALT_STATE {
    /** When a ticket has not been initialized */
    TO_VALIDATE = "TO_VALIDATE",
    /** When the ticket has not been attributed */
    NOT_ASSIGNED = "NOT_ASSIGNED"
  }

  export type TICKET_ALL_STATES = TicketState.TICKET_ALT_STATE | TicketState._TICKET_STATE

}