import { remove as removeDiacritics } from 'diacritics';
import { getEnvironment } from "../../shared";

export namespace UserAssistant {

  export interface Common {
    firstName?: string,
    lastName?: string,
    email?: string,
    /** White label email */
    customEmail?: string,
    organization?: string,
    title?: string,
    avatar?: string,
    emailSignature?: string,
    /** When a team member has different assistant from the rest of the team */
    owner?: ASSISTANT_OWNERSHIP
  }

  export interface DBObject extends Common {
  }

  export interface Object extends Common {
    /** Primary email address */
    computed_fullEmail?: string,
    /** Email the @theassistant.com */
    computed_defaultEmail?: string,
    computed_vcardUrl?: string,
    computed_uniqueEmail?: string,
  }

  export interface DBUpdate extends Common {
  }

  export const helper = {
    toObject: (dbObject: DBObject): Object => {
      return {
        ...dbObject
      }
    },
    getAssistantEmailUserName: (firstName: string, prod: boolean) => {
      return removeDiacritics(firstName).toLowerCase().replace(/[^0-9a-z]+/g, "") || getEnvironment(prod).assistant.email;
    },
    getAssistantDefaultEmail: (firstName: string, prod: boolean) => {
      return `${helper.getAssistantEmailUserName(firstName, prod)}@${getEnvironment(prod).emailDomain}`;
    },
  }

  export enum ASSISTANT_OWNERSHIP {
    TEAM = "TEAM",
    USER = "USER",
  };

  export const ASSISTANT_EMAIL_SIGNATURE = `
  <div class="ta-assistant-footer">
    <div class="wrapper" style="padding: 20px 0px 10px 10px;margin: 20px 20px 20px 0px;color: #000;font-family: Muli, Lucida Grande, Arial, sans-serif;">
      <div class="main" >
        <div class="text" style="display: inline-block;font-size: 9px;line-height: 1.5; padding-left:4px;">
          <h2 style="font-size: 18px; margin: 0px 0px 6px 0px; line-height: 1; font-weight: 900;">{{assistant.firstName}}</h2>
          <span class="subheading" style="color: #666;font-size: 12px; font-weight: 200;">{{assistant.title}}<span> • </span><span>{{assistant.organization}}</span></span><br>
          <span style="color: #666;font-size: 9px; font-weight: 200;"><a href="mailto:{{assistant.computed_fullEmail}}" style="color: #666; text-decoration: none;">{{assistant.computed_fullEmail}}</a></span>
        </div>
      </div>
      <span class="clear" style="clear: both;"></span>
    </div>
  </div>
  `;
}