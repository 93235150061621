import { allDateTypes, dbDateTypes, dbDateUpdateTypes } from "../common";
import { TimelineItemTask } from "../ticket";
import { Template } from "../template";
import { VaultItem } from "../user";
import * as FirebaseFirestore from '@firebase/firestore-types';

export namespace Procedure {

  export type EditorJSData = {
    version?: string;
    time?: number;
    blocks: {
      id?: string;
      type: string;
      data: any;
      tunes?: any
    }[];
  }

  export interface Common {
    name?: string,
    category?: PROCEDURE_CATEGORY,
    status?: PROCEDURE_STATUS,
    desc?: string,
    trigger?: string;
    synonyms?: string,
    icon?: string,
    /* Only live procedures are selectables */
    isLive?: boolean,
    /* default procedures should always be displayed on requests */
    isDefault?: boolean,
    displayInRequests?: boolean, // To delete
    action?: string
    createdAt?: allDateTypes;
    updatedAt?: allDateTypes;
    vaultItems?: FirebaseFirestore.DocumentReference<VaultItem.DBObject>[],
    acl?: string[],
    cron?: string
    htmlBody?: string,
    editorJSBody?: EditorJSData,
    privateNote?: EditorJSData,
    tasks?: {
      task: TimelineItemTask.DBObject,
      template?: Template.DBObject,
      multiple: boolean,
    }[],
    versionId?: string,
    _needToUpdate?: boolean,
    updatedBy?: string,
    files?: {name: string, url: string}[],
    acknowledgementOfReceipt?: boolean,
    averageNumberOfActions?: number,
    migratedToEditorJs?: boolean
  }

  export interface DBObject extends Common {
    createdAt?: dbDateTypes;
    updatedAt?: dbDateTypes;
  }

  export interface DBUpdate extends Common {
    createdAt?: dbDateUpdateTypes;
    updatedAt?: dbDateUpdateTypes;
  }

  export interface Object extends Common {
    computed_id?: string,
    createdAt?: Date;
    updatedAt?: Date;
    computed_ref?: FirebaseFirestore.DocumentReference<Procedure.DBObject>;
  }

  export const helper = {
    toObject: (id: string, dbObject: DBObject, ref?: FirebaseFirestore.DocumentReference<Procedure.DBObject>): Object => {
      const object: Object = {
        ...dbObject,
        createdAt: dbObject?.createdAt?.toDate && dbObject?.createdAt?.toDate(),
        updatedAt: dbObject?.updatedAt?.toDate && dbObject?.updatedAt?.toDate(),
        computed_id: id,
      }
      if (ref) object.computed_ref = ref;
      return object;
    },
  };

  export enum PROCEDURE_CATEGORY {
    ACCOUNTING = 'accounting',
    TRAVEL = 'travel',
    BILLING_PAYMENT = 'billing_payment',
    BILLING_EDITION = 'billing_edition',
    HUMAN_RESOURCES = 'human_resources',
    CUSTOM = 'custom',
  }

  export enum PROCEDURE_STATUS {
    DRAFT = 'draft',
    READY = 'ready',
    LIVE = 'live',
    PAUSED = 'paused',
  }
}

export namespace ProcedureVersion {
  export interface Common {
    editorJsDiff?: Procedure.EditorJSData,
    updatedBy?: string;
  }

  export interface Object extends Common {
    computed_id: string,
    updatedAt: Date;
    performers: { [key: string]: Date | null }
  }

  export interface DBObject extends Common {
    updatedAt: dbDateTypes;
    performers: { [key: string]: dbDateTypes | null }
  }

  export const helper = {
    toObject: (id: string, dbObject: DBObject, ref?: FirebaseFirestore.DocumentReference<Procedure.DBObject>): Object => {
      const performers: { [key: string]: Date | null } = {};
      Object.keys(dbObject.performers).forEach(key => {
        performers[key] = (dbObject.performers[key]?.toDate && dbObject?.performers[key]?.toDate()) || null;
      });


      const object: Object = {
        ...dbObject,
        updatedAt: dbObject?.updatedAt?.toDate && dbObject?.updatedAt?.toDate(),
        computed_id: id,
        performers: performers
      };
      return object;
    },
  };
}
