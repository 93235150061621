
import { Attachment, Message } from "../..";
import { dbDateUpdateTypes, dbDateTypes, allDateTypes } from "../../common"
import { Encoder } from "../../shared";
import { Email } from "../email";

export namespace LightMessageValue {

    export interface Common {
        createdAt?: allDateTypes;
        from?: Email.Common;
        bodyHtml?: string;
        useHtmlBody?: boolean,
        strippedHtml?: string;
        bodyText?: string;
        strippedText?: string;
        subject?: string;
        to?: Email.Common[];
        cc?: Email.Common[];
        bcc?: Email.Common[];
        replyToMessageId?: string; //messageId of the message we reply to
        outboundStatus?: Message.MESSAGE_OUTBOUND_STATUS;
        template?: string;
        sentAt?: allDateTypes;
        sentBy?: string;
        attachments?: Attachment.DBObject[];
        //Added
        direction?: Message.MESSAGE_DIRECTION;
        metadata?: Message.MessageMetadata;
        error?: { code?: number, message?: string}; // To be displayed in the tooltip should the draft sending fail
        emailMessageId?: string; //internet message id
        emailInReplyTo?: string; //emailMessageId of the message we reply to
        replyTo?: string; // sender to reply to
        emailReferences?: string[];
        threadId?: string;
        type?: Message.MESSAGE_TYPE,
        isSpam?: boolean;
        isAd?: boolean;
    }

    export interface DBObject extends Common {
        createdAt?: dbDateTypes;
        sentAt?: dbDateTypes;
    }

    export interface Object extends Common {
        createdAt?: Date,
        sentAt?: Date,
        computed_id?: string
    }

    export interface DBUpdate extends Common {
        createdAt?: dbDateUpdateTypes,
        sentAt?: dbDateUpdateTypes,
    }

    export const helper = {
        toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...dbObject,
                computed_id: id,
                createdAt: dbObject?.createdAt?.toDate && dbObject?.createdAt?.toDate(),
                sentAt: dbObject?.sentAt?.toDate && dbObject?.sentAt?.toDate()
            }
        },
        toDBUpdate: (object: Object): DBUpdate => {
            const dbUpdate: DBUpdate = {};
            if (object.from) dbUpdate.from = object.from;
            if (object.bodyHtml) dbUpdate.bodyHtml = object.bodyHtml;
            if (object.useHtmlBody) dbUpdate.useHtmlBody = object.useHtmlBody;
            if (object.strippedHtml) dbUpdate.strippedHtml = object.strippedHtml;
            if (object.bodyText) dbUpdate.bodyText = object.bodyText;
            if (object.strippedText) dbUpdate.strippedText = object.strippedText;
            if (object.subject) dbUpdate.subject = object.subject;
            if (object.type) dbUpdate.type = object.type;
            if (object.to) dbUpdate.to = object.to;
            if (object.cc) dbUpdate.cc = object.cc;
            if (object.bcc) dbUpdate.bcc = object.bcc;
            if (object.replyToMessageId) dbUpdate.replyToMessageId = object.replyToMessageId;
            if (object.outboundStatus) dbUpdate.outboundStatus = object.outboundStatus;
            if (object.template) dbUpdate.template = object.template;
            if (object.sentBy) dbUpdate.sentBy = object.sentBy;
            if (object.attachments) dbUpdate.attachments = object.attachments;
            if (object.direction) dbUpdate.direction = object.direction;
            if (object.metadata) dbUpdate.metadata = object.metadata;
            if (object.error) dbUpdate.error = object.error;
            if (object.emailMessageId) dbUpdate.emailMessageId = object.emailMessageId;
            if (object.emailInReplyTo) dbUpdate.emailInReplyTo = object.emailInReplyTo;
            if (object.emailReferences) dbUpdate.emailReferences = object.emailReferences;
            if (object.replyTo) dbUpdate.replyTo = object.replyTo;
            if (object.threadId) dbUpdate.threadId = object.threadId;
            if (object.type) dbUpdate.type = object.type;
            if (object.isSpam) dbUpdate.isSpam = object.isSpam;
            if (object.isAd) dbUpdate.isAd = object.isAd;

           //Can't use Firebase conversion functions
            if (object.sentAt) dbUpdate.sentAt = object.sentAt as any;
            if (object.createdAt) dbUpdate.createdAt = dbUpdate.createdAt as any;

            // if (object.sentAt) dbUpdate.sentAt = Timestamp.fromDate(object.sentAt);
            // if (object.createdAt) dbUpdate.createdAt = Timestamp.fromDate(object.createdAt);
            return dbUpdate;
          },



        receivers: (lightMessage: Common): Email.Common[] => {
            return [...(lightMessage?.to || []), ...(lightMessage?.cc || []), ...(lightMessage?.bcc || [])];
        },
        all: (lightMessage: Common): Email.Common[] => {
            const allRecipients = helper.receivers(lightMessage);
            if (lightMessage?.from) allRecipients.push(lightMessage?.from);
            return allRecipients;
        },
        toMessage(lightMessage: LightMessageValue.Object) {
            if (!lightMessage) return null;
            let _fromEmail: string|undefined;
            let _toEmails, _ccEmails, _bccEmails: {
                [key: string]: number
            };
            let _addressesNames: {
                [key: string]: string
            };
            const encodeTempEmail = (emails?: Email.Common[]): { [key: string]: number } => {
                const _email = emails || []
                return _email.reduceRight((a, b, i) => {
                    if (!b || !b.address) { return a; }
                    const key = Encoder.encodeKey(b.address);
                    key && (a[key] = i);
                    return a;
                }, {} as { [key: string]: number });
            };
            const to = lightMessage.to || [];
            const cc = lightMessage.cc || [];
            const bcc = lightMessage.bcc || [];
            _fromEmail = Encoder.encodeKey(lightMessage?.from?.address) || undefined;
            _toEmails = encodeTempEmail(to);
            _ccEmails = encodeTempEmail(cc);
            _bccEmails = encodeTempEmail(bcc);
            _addressesNames = [lightMessage.from, ...to, ...cc, ...bcc].reduceRight((a, b) => {
                if (b && b.name) {
                    const key = Encoder.encodeKey(b.address);
                    key && (a[key] = b.name);
                }
                return a;
            }, {} as { [key: string]: string });
            const attachments = lightMessage.attachments || [];
            const message: Message.Object = { 
                ...lightMessage, 
                direction: lightMessage.direction, 
                outboundStatus: lightMessage.outboundStatus, 
                _fromEmail, 
                _toEmails, 
                _ccEmails, 
                _bccEmails, 
                _addressesNames,
                computed_attachments: attachments,
                attachmentCount: attachments?.length
             };
            return message;
        }
    }
}
