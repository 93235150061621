import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { User, UserPermissions } from 'src/app/models/user';
import { StripeServerService } from './stripe.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Stripe as stripe_server } from 'stripe';

@Injectable({
    providedIn: "root"
})
export class UserPermissionService {

    constructor(private fbs: FirebaseService,
        private permissionsService: NgxPermissionsService,
        private stripeService: StripeServerService) {
    }

    deinit() {
        this.permissionSubs && this.permissionSubs.unsubscribe();
    }

    private permissionSubs: Subscription
    init() {
        return new Promise((resolve, reject) => {
            let initialized = false;
            const currentUser$ = this.fbs.currentUser();
            const loadPermissions$ = combineLatest([currentUser$, this.stripeService.getCurrentUserPack$()]).pipe(
                map(([u, p]) => {
                    //Roles and permissions
                    if (u) {
                        const permissions = this.getUserPermission(u, p);
                        this.permissionsService.loadPermissions(permissions);
                    } else {
                        this.permissionsService.flushPermissions();
                    }
                    if (!initialized) {
                        initialized = true;
                        resolve(true);
                    }
                })
            )
            this.permissionSubs = loadPermissions$.subscribe();
            return this.permissionSubs;
        });
    }

    getUserPermissionByUserId$(userId: string): Observable<UserPermissions.USER_PERMISSIONS[]> {
        return combineLatest([this.fbs.getUser(userId), this.stripeService.getCustomerPack$(userId)]).pipe(
            map(([u, p]) => {
                if (u) {
                    return this.getUserPermission(u, p);
                }
                return null;
            })
        )
    }

    getUserPermission(user: User.Object, pack: stripe_server.Product|stripe_server.DeletedProduct|'NO_PACK'|undefined): UserPermissions.USER_PERMISSIONS[] {
        const packPermission: UserPermissions.Common = {};
        const userPermissions = User.helper.getUserPermissions(user);
        if (pack && pack !== 'NO_PACK' && 'metadata' in pack) {
            const sPackPermissions = pack.metadata?.permissions || '';
            try {
                sPackPermissions.split(",").filter(Boolean).forEach((b) => {
                    packPermission[b] = true;
                });
            } catch (e) {
                console.error(e);
            }
        }
        return Object.keys({...packPermission, ...userPermissions})
            .filter(p => {
                if (userPermissions[p] === true || userPermissions[p] === false) {
                    return userPermissions[p];
                }
                if (packPermission[p] === true || packPermission[p] === false) {
                    return packPermission[p];
                }
                return false;
            }) as UserPermissions.USER_PERMISSIONS[];
    }
}