import { Procedure } from "../procedure";
import { VaultItem } from "../user/vault-item";

export const COLOR_BY_VAULT_OR_PROCEDURE_CATEGORY: Record<VaultItem.VAULT_ITEM_CATEGORY | Procedure.PROCEDURE_CATEGORY, string> = {
  [VaultItem.VAULT_ITEM_CATEGORY.CALENDAR]: 'warning',
  [VaultItem.VAULT_ITEM_CATEGORY.APPLICATION]: 'primary',
  [VaultItem.VAULT_ITEM_CATEGORY.PAYMENT_METHODS]: 'success',
  [VaultItem.VAULT_ITEM_CATEGORY.CONTACT]: 'secondary',
  [VaultItem.VAULT_ITEM_CATEGORY.OTHER]: 'tertiary',
  [VaultItem.VAULT_ITEM_CATEGORY.FILE]: 'medium',
  [Procedure.PROCEDURE_CATEGORY.ACCOUNTING]: 'warning',
  [Procedure.PROCEDURE_CATEGORY.TRAVEL]: 'primary',
  [Procedure.PROCEDURE_CATEGORY.BILLING_PAYMENT]: 'success',
  [Procedure.PROCEDURE_CATEGORY.BILLING_EDITION]: 'tertiary',
  [Procedure.PROCEDURE_CATEGORY.HUMAN_RESOURCES]: 'secondary',
  [Procedure.PROCEDURE_CATEGORY.CUSTOM]: 'medium',
};