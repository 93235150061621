import { VaultItem } from "../..";
import { allDateTypes, dbDateUpdateTypes, dbDateTypes } from "../../common";
import { Procedure } from "../../procedure";
import * as FirebaseFirestore from '@firebase/firestore-types';

export namespace TicketGeneralFields {

  export enum TICKET_GENERAL_FIELDS {
    CLIENT = "client",
    DEADLINE = "deadline",
    OWNER = "owner",
    ATTRIBUTED_TO = "attributedTo",
    TITLE = "title",
    SERVICE = "service",
    VAULT_ITEMS = "vaultItems",
    PROCEDURES = "procedures",
    CATEGORY = "category",
    MAX_ACTIONS = "maxActions",
    ESTIMATED_DURATION = "estimatedDuration",
    URGENT = "urgent",
    TASK_TYPES = "taskTypes"
  };

  export enum TICKET_CATEGORY {
    PRO = "PRO",
    PERSO = "PERSO",
    INTERNAL = "INTERNAL",
  };

  export interface Common {
    [TICKET_GENERAL_FIELDS.CLIENT]?: string,
    [TICKET_GENERAL_FIELDS.DEADLINE]?: allDateTypes,
    [TICKET_GENERAL_FIELDS.OWNER]?: string,
    [TICKET_GENERAL_FIELDS.ATTRIBUTED_TO]?: string,
    [TICKET_GENERAL_FIELDS.TITLE]?: string,
    [TICKET_GENERAL_FIELDS.SERVICE]?: string,
    [TICKET_GENERAL_FIELDS.VAULT_ITEMS]?: FirebaseFirestore.DocumentReference<VaultItem.DBObject>[],
    [TICKET_GENERAL_FIELDS.PROCEDURES]?: FirebaseFirestore.DocumentReference<Procedure.DBObject>[],
    [TICKET_GENERAL_FIELDS.CATEGORY]?: TICKET_CATEGORY,
    [TICKET_GENERAL_FIELDS.MAX_ACTIONS]?: number,
    [TICKET_GENERAL_FIELDS.ESTIMATED_DURATION]?: number,
    [TICKET_GENERAL_FIELDS.URGENT]?: boolean,
    [TICKET_GENERAL_FIELDS.TASK_TYPES]?: string[]
  }

  export interface DBObject extends Common {
    [TICKET_GENERAL_FIELDS.DEADLINE]?: dbDateTypes
  }

  export interface Object extends Common {
    [TICKET_GENERAL_FIELDS.DEADLINE]?: Date
  }

  export interface DBUpdate extends Common {
    [TICKET_GENERAL_FIELDS.DEADLINE]?: dbDateUpdateTypes
  }

  export const helper = {
    toObject: (dbObject: DBObject): Object => {
      const object = {
        ...dbObject,
        [TICKET_GENERAL_FIELDS.DEADLINE]: dbObject?.[TICKET_GENERAL_FIELDS.DEADLINE]?.toDate && dbObject?.[TICKET_GENERAL_FIELDS.DEADLINE]?.toDate()
      };
      if (!dbObject?.[TICKET_GENERAL_FIELDS.DEADLINE]?.toDate) delete object?.[TICKET_GENERAL_FIELDS.DEADLINE];
      return object;
    },
  }
}