import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/models/user';
import { Message } from 'src/app/models/message';
import { Observable, of } from 'rxjs';
import { FirebaseService } from 'src/app/shared/services/firebase.service';
import { environment } from 'src/environments/environment';
import { Team } from '../team';
import { UserAnalytics } from 'ta-models/src/user';
import { PartnerGroupsService } from 'src/app/shared/services/partners-group.service';

@Pipe({
  name: 'userAvatar'
})
export class UserAvatarPipe implements PipeTransform {

  transform(user: User.Common): string {
    return User.helper.getAvatar(user)
  }

}

@Pipe({
  name: 'userFirstName'
})
export class UserFirstNamePipe implements PipeTransform {

  transform(user: User.Common): string {
    return User.helper.getFirstName(user);
  }

}

@Pipe({
  name: 'isAdminOrAgent'
})
export class IsAdminOrAgentPipe implements PipeTransform {

  transform(user: User.Common): boolean {
    return User.helper.isAdminOrAgent(user);
  }

}

@Pipe({
  name: 'isCustomer'
})
export class IsCustomerPipe implements PipeTransform {

  transform(user: User.Common): boolean {
    return User.helper.isCustomer(user);
  }

}

@Pipe({
  name: 'isCustomerOrTeamMember'
})
export class IsCustomerOrTeamMemberPipe implements PipeTransform {

  transform(user: User.Common): boolean {
    return User.helper.isCustomerOrTeamMember(user);
  }

}

@Pipe({
  name: 'userLastName'
})
export class UserLastNamePipe implements PipeTransform {

  transform(user: User.Common): string {
    return User.helper.getLastName(user);
  }

}

@Pipe({
  name: 'userFullName'
})
export class UserFullNamePipe implements PipeTransform {

  transform(user: User.Common): string {
    return User.helper.getFullName(user);
  }

}

@Pipe({
  name: 'userShortFullName'
})
export class UserShortFullNamePipe implements PipeTransform {

  transform(user: User.Common): string {
    if (!user) return null;
    return `${User.helper.getFirstName(user)} ${User.helper.getLastName(user)?.charAt(0)}.`;
  }

}

@Pipe({
  name: 'userEmail'
})
export class UserEmailPipe implements PipeTransform {

  transform(user: User.Common): string {
    return User.helper.getEmail(user);
  }

}

@Pipe({
  name: 'user$'
})
export class UserPipe implements PipeTransform {

  constructor(private fbs: FirebaseService) { }

  transform(userId: string): Observable<User.Object> {
    return this.fbs.getUser(userId);
  }
}

@Pipe({
  name: 'userFromSource$'
})
export class UserFromSourcePipe implements PipeTransform {
  constructor(private fbs: FirebaseService) { };
  transform(source: string): Observable<User.Object> {
    if (!source) return of(null);
    if (Message.helper.getSourceDomain(source) === environment.phoneDomain) {
      const phone = '+' + source.replace(/@.*/, '')
      return this.fbs.userWithPhone(phone);
    } else {
      return this.fbs.userWithEmail(source);
    }
  }
}

@Pipe({
  name: 'publicUser$'
})
export class PublicUserPipe implements PipeTransform {
  constructor(private fbs: FirebaseService) { }
  transform(userId: string): Observable<Partial<User.Object>> {
    return this.fbs.getPublicUser(userId);
  }
}

@Pipe({
  name: 'userAnalytics$'
})
export class UserAnalyticsPipe implements PipeTransform {
  constructor(private fbs: FirebaseService) { }
  transform(userId: string): Observable<UserAnalytics.Object> {
    return this.fbs.getUserAnalytics(userId);
  }
}

@Pipe({
  name: 'userTeams$'
})
export class UserTeamsPipe implements PipeTransform {
  constructor(private fbs: FirebaseService) { }
  transform(userId: string): Observable<Team.Object[]> {
    return this.fbs.getUserTeams(userId);
  }
}

@Pipe({
  name: 'userTeamOwnerId$'
})
export class UserTeamOwnerIdPipe implements PipeTransform {
  constructor(private fbs: FirebaseService) { }
  transform(userId: string): Observable<string> {
    return this.fbs.getUserTeamOwnerId(userId);
  }
}

@Pipe({
  name: 'partnerGroupName$'
})
export class PartnerGroupNamePipe implements PipeTransform {
  constructor(private pgs: PartnerGroupsService) { }
  transform(groupId: string): Observable<string> {
    return this.pgs.getGroupName$(groupId);
  }
}

@Pipe({
  name: 'clientGroupId$'
})
export class ClientGroupIdPipe implements PipeTransform {
  constructor(private pgs: PartnerGroupsService) { }
  transform(userId: string): Observable<string> {
    return this.pgs.getUserClientGroupId(userId);
  }
}

@Pipe({
  name: 'projectManagerId$'
})
export class ProjectManagerIdPipe implements PipeTransform {
  constructor(private pgs: PartnerGroupsService) { }
  transform(userId: string): Observable<string> {
    return this.pgs.getUserProjectManagerId(userId);
  }
}