<nz-modal [nzVisible]="needToUpdate$.value" [nzTitle]="null" [nzClosable]="false" [nzMaskClosable]='false'
  [nzFooter]="null">
  <ng-container *nzModalContent>
    <nz-alert nzBanner nzType="info" i18n-nzMessage="@@version-message" nzMessage="A new version is available"
      [nzDescription]="tplDesc" nzShowIcon>
    </nz-alert>
    <ng-template #tplDesc>
      <span i18n="@@version-description">Click on <b>Command</b>-<b>Shift</b>-<b>R</b> to download this new version if
        you are using MacOs ; <b>Control</b>-<b>Shift</b>-<b>R</b> if you are using Windows. Enjoy!!</span>
    </ng-template>
  </ng-container>
</nz-modal>
<ng-template #template let-notification>
  <div class="ant-notification-notice-content">
    <div>
      <div class="ant-notification-notice-message" i18n="@@version-offline-notification-title">Offline</div>
      <div class="ant-notification-notice-description" i18n="@@version-offline-notification-desc">
        It appears you've been offline, please reload the page as soon as you get online to make sure everything works
        perfectly, Thanks!
      </div>
      <span class="ant-notification-notice-btn">
        <button nz-button [nzLoading]="refreshing" nzType="primary" nzSize="small" (click)="refresh()"><span
            i18n="@@version-offline-notification-button">Refresh</span></button>
      </span>
    </div>
  </div>
</ng-template>