import {dbDateUpdateTypes, dbDateTypes, allDateTypes} from '../../common';
import { General } from '../preferences/general';

export namespace UserAnalytics {
    
    export interface Common {
        updatedAt?: allDateTypes,
        fullName?: string,
        label?: LABEL,
        currentSubscription?: Subscription.Common,
        clientGroupId?: string,
        projectManagerId?: string,
        accountManagerId?: string,
        generalPreferences?: General.Preferences,
        partner?: {
          requests?: {
            open?: number,
            upcoming?: number,
            helpNeeded?: number,
          }
        }
    }

    export interface DBObject extends Common {
        updatedAt?: dbDateTypes,
        currentSubscription?: Subscription.DBObject,
    }

    export interface Object extends Common {
        computed_id: string,
        updatedAt?: Date,
        currentSubscription?: Subscription.Object,
    }

    export interface DBUpdate extends Common {
        updatedAt?: dbDateUpdateTypes,
        currentSubscription?: Subscription.DBUpdate,
    }

    export enum LABEL {
        ADMIN = 'ADMIN',
        ASSISTANT = 'ASSISTANT',
        CUSTOMER = 'CUSTOMER',
        TEAM_MEMBER = 'TEAM_MEMBER',
        AUTHENTIFIED = 'AUTHENTIFIED',
        DORMANT = 'DORMANT',
        NON_RELEVANT = 'NON_RELEVANT',
    }

    export const helper = {
      toObject: (id: string, dbObject: DBObject): Object => {
        return {
          ...dbObject,
          computed_id: id,
          updatedAt: dbObject?.updatedAt?.toDate && dbObject?.updatedAt?.toDate(),
          currentSubscription: dbObject.currentSubscription && Subscription.helper.toObject(dbObject.currentSubscription),
        };
      }
    };
}

export namespace Subscription {
    export interface Common {
        name?: string,
        quantity?: number,
        periodStart?: allDateTypes
        periodEnd?: allDateTypes,
        remainingQuantity?: number,
        currentUsage?: {
            totalQuantity?: number;
            packQuantity?: number;
            defaultQuantity?: number;
        }
    }

    export interface DBObject extends Common {
        periodStart?: dbDateTypes
        periodEnd?: dbDateTypes,
    }

    export interface Object extends Common {
        periodStart?: Date
        periodEnd?: Date,
    }

    export interface DBUpdate extends Common {
        periodStart?: dbDateUpdateTypes
        periodEnd?: dbDateUpdateTypes,
    }

    export const helper = {
      toObject: (dbObject: DBObject): Object => {
        return {
          ...dbObject,
          periodStart: dbObject?.periodStart?.toDate && dbObject?.periodStart?.toDate(),
          periodEnd: dbObject?.periodEnd?.toDate && dbObject?.periodEnd?.toDate(),
        };
      }
    };
}