export * from './notification'
export * from './timeline-item'
export * from "./public-ticket";
export * from "./general-fields";
export * from './state'
import { allDateTypes, dbDateUpdateTypes, dbDateTypes } from '../common';
import { TicketState } from './state';
import { TicketGeneralFields } from './general-fields';

export namespace Ticket {

  export interface Common {
    createdAt?: allDateTypes,
    updatedAt?: allDateTypes,
    acl?: string[],
    state?: TicketState.Common,
    fields?: {
      general?: TicketGeneralFields.Common,
    },
    messages?: string[],
    shortId?: string | 0,
    password?: string,
    allStates?: {
      [key: string]: allDateTypes
    }
    _emailReplyTo?: { [key: string]: number },
    _emails?: { [key: string]: boolean },
    _notificationsCount?: {
      [userId: string]: number
    },
    _toValidateCount?: number, // Number of timeline items to be validated.
    _toValidateSystemCount?: number, // Number of timeline items to be validated and created by the system.
    _runningCount?: number,
    _runningTasks?: allDateTypes[],
    _allStates?: TicketState.TICKET_ALL_STATES[],  //helper field - ease query
    _billed?: boolean,
    _actions?: number,
    _warningDeadline?: allDateTypes
  }

  export interface DBObject extends Common {
    createdAt?: dbDateTypes,
    updatedAt?: dbDateTypes,
    allStates?: {
      [key: string]: dbDateTypes
    }
    state?: TicketState.DBObject,
    fields?: {
      general?: TicketGeneralFields.DBObject,
    }
    _runningTasks?: dbDateTypes[],
    _warningDeadline?: dbDateTypes
  }

  export interface Object extends Common {
    createdAt?: Date,
    updatedAt?: Date,
    computed_id: string,
    state?: TicketState.Object,
    allStates?: {
      [key: string]: Date
    }
    fields?: {
      general?: TicketGeneralFields.Object,
    },
    _runningTasks?: Date[],
    _warningDeadline?: Date
  }

  export interface DBUpdate extends Common {
    createdAt?: dbDateUpdateTypes,
    updatedAt?: dbDateUpdateTypes,
    state?: TicketState.DBUpdate,
    allStates?: {
      [key: string]: dbDateUpdateTypes
    }
    fields?: {
      general?: TicketGeneralFields.DBUpdate,
    },
    _runningTasks?: dbDateUpdateTypes[],
    _warningDeadline?: Date
  }

  export const helper = {
    toObject: (id: string, dbObject: DBObject): Object => {
      return {
        ...dbObject,
        computed_id: id,
        createdAt: dbObject?.createdAt?.toDate && dbObject?.createdAt?.toDate(),
        updatedAt: dbObject?.updatedAt?.toDate && dbObject?.updatedAt?.toDate(),
        state: dbObject?.state && TicketState.helper.toObject(dbObject.state),
        allStates: dbObject?.allStates && Object.keys(dbObject?.allStates).reduce((a,b) => { 
          let _b = b as TicketState.TICKET_ALL_STATES
          const allStateValue = dbObject?.allStates?.[_b];
          if (allStateValue ) {
            a[_b] = allStateValue?.toDate && allStateValue?.toDate();
          }
          return a;
        }, {} as {[key: string]: Date}) ||  {} as {[key: string]: Date},
        fields: {
          general: dbObject?.fields?.general && TicketGeneralFields.helper.toObject(dbObject?.fields?.general)
        },
        _runningTasks: (dbObject?._runningTasks || []).map(_runningTask => _runningTask?.toDate && _runningTask?.toDate()),
        _warningDeadline: dbObject?._warningDeadline?.toDate?.(),
      }
    },
    TICKET_TIMER_FACTOR: (rating?: number) => {
      const _factor = Math.min(Math.max(rating == null ? TICKET_TIMER_DEFAULT : rating, 0), 5);
      return _factor * 0.2;
    },
    /**
     * Converts a duration to quantity
     * @param duration real time (in seconde) the task took
     * @param rating for 0 to 5 (previously start)
     */
    DURATION_TO_QUANTITY: (duration?: number, rating?: number) => {
      const factor = helper.TICKET_TIMER_FACTOR(rating);
      const minutes = Math.max((duration || 0) / 60, 1); // Au moins 1 min.
      return Math.ceil(minutes * factor); // Minutes efficaces.
    },
    getClientId(ticket: Common) {
      return ticket?.fields?.general?.client;
    },
    getTitle(ticket: Common) {
      return ticket?.fields?.general?.title;
    },
    getAttributedTo(ticket: Common) {
      return ticket?.fields?.general?.attributedTo;
    },
    getServiceId(ticket: Common) {
      return ticket?.fields?.general?.service;
    },
    getProcedures(ticket: Common) {
      return ticket?.fields?.general?.procedures || [];
    },
    getVaultItems(ticket: Common) {
      return ticket?.fields?.general?.vaultItems || [];
    },
    getCategory(ticket: Common) {
      return ticket?.fields?.general?.category;
    },
    getOwner(ticket: Common) {
      return ticket?.fields?.general?.owner;
    },
    getDeadline(ticket: Ticket.Object) {
      return ticket?.fields?.general?.deadline
    },
    getMaxActions(ticket: Common) {
      return ticket?.fields?.general?.maxActions;
    },
    getEstimatedDuration(ticket: Common) {
      return ticket?.fields?.general?.estimatedDuration;
    },
    getTaskTypes(ticket: Common) {
      return ticket?.fields?.general?.taskTypes;
    },
    getUrgent(ticket: Common) {
      return ticket?.fields?.general?.urgent;
    },
    isState: (ticket: Common, state: TicketState._TICKET_STATE): boolean => {
      return ticket?.state?.key === state;
    },
    isSnoozedOrPending: (ticket: Common): boolean => {
      return helper.isSnoozed(ticket) || helper.isPending(ticket);
    },
    isArchived: (ticket: Common): boolean => {
      return helper.isState(ticket, TicketState._TICKET_STATE.ARCHIVED);
    },
    isSnoozed: (ticket: Common): boolean => {
      return helper.isState(ticket, TicketState._TICKET_STATE.SNOOZED);
    },
    isOpen: (ticket: Common): boolean => {
      return helper.isState(ticket, TicketState._TICKET_STATE.OPEN);
    },
    isPending: (ticket: Common): boolean => {
      return helper.isState(ticket, TicketState._TICKET_STATE.PENDING);
    },
    isClosed: (ticket: Common): boolean => {
      return helper.isState(ticket, TicketState._TICKET_STATE.CLOSED);
    },
    isHelp: (ticket: Common): boolean => {
      return helper.isState(ticket, TicketState._TICKET_STATE.HELP_NEEDED);
    },
    isClosedOrArchived: (ticket: Common): boolean => {
      return helper.isClosed(ticket) || helper.isArchived(ticket);
    },
  }

  export const NEW_TICKET_TEMP_ID = "NEW_TICKET_TEMP_ID";
  export const TICKET_TIMER_DEFAULT = 4;
}
