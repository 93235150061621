import { dbDateUpdateTypes, dbDateTypes, allDateTypes } from "../../common"

export namespace AgentParams {

    export interface Common {
        startAt?: allDateTypes,
        hourlyRate?: {
            DEFAULT: number,
            [billedUserId: string]: number
        },
        efficiency?: {
            DEFAULT: number,
            [billedUserId: string]: number
        },
        vatRate?: number
}

export interface DBObject extends Common {
    startAt?: dbDateTypes
}

export interface Object extends Common {
    computed_id: string,
    startAt?: Date
}

export interface DBUpdate extends Common {
    startAt?: dbDateUpdateTypes
}

export const helper = {
    toObject: (id: string, dbObject: DBObject): Object => {
        const startAt = dbObject?.startAt?.toDate && dbObject?.startAt?.toDate();
        return {
            ...dbObject,
            computed_id: id,
            startAt
        }
    }
}
}