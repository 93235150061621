import { allDateTypes, dbDateUpdateTypes, dbDateTypes } from '../../../common';

export namespace NotificationStatus {

    export interface Common {
        toSend?: allDateTypes|null,
        sent?: allDateTypes|null,
        toRead?: allDateTypes|null,
        read?: allDateTypes|null,
        inactive?: allDateTypes|null,
    }

    export interface DBObject extends Common {
        toSend: dbDateTypes|null,
        sent: dbDateTypes|null,
        toRead: dbDateTypes|null,
        read: dbDateTypes|null,
        inactive: dbDateTypes|null,
    }

    export interface Object extends Common {
        toSend?: Date|null,
        sent?: Date|null,
        toRead?: Date|null,
        read?: Date|null,
        inactive?: Date|null,
    }

    export interface DBUpdate extends Common {
        toSend: dbDateUpdateTypes|null,
        sent: dbDateUpdateTypes|null,
        toRead: dbDateUpdateTypes|null,
        read: dbDateUpdateTypes|null,
        inactive: dbDateUpdateTypes|null,
    }

    export const helper = {
        toObject: (dbObject: DBObject): Object => {
            return {
                ...dbObject,
                toSend: dbObject?.toSend?.toDate && dbObject?.toSend?.toDate(),
                sent: dbObject?.sent?.toDate && dbObject?.sent?.toDate(),
                toRead: dbObject?.toRead?.toDate && dbObject?.toRead?.toDate(),
                read: dbObject?.read?.toDate && dbObject?.read?.toDate(),
                inactive: dbObject?.inactive?.toDate && dbObject?.inactive?.toDate(),
            }
        },
    }
}