import { Component, OnInit, NgZone, ViewChild, TemplateRef } from '@angular/core';
import * as compareVersions from 'compare-versions';
import { AngularFirestore, } from '@angular/fire/compat/firestore';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { VERSION } from '../../../../environments/version';
import { BehaviorSubject } from 'rxjs';
import { NzNotificationService, NzNotificationRef } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {
  @ViewChild('template', { static: true }) template: TemplateRef<any>;

  currentVersion = VERSION.version;
  needToUpdate$ = new BehaviorSubject<boolean>(false);
  constructor(private db: AngularFirestore, private fireDatabase: AngularFireDatabase, private notification: NzNotificationService, private zone: NgZone) { }
  private hasBeenOnline = false;
  private notificationDataFilled: NzNotificationRef;
  ngOnInit() {
    this.fireDatabase.database.ref('.info/connected').on('value', (snapshot) => {
      if (snapshot.val() && !this.hasBeenOnline) {
        this.hasBeenOnline = true;
      }
      if (snapshot.val() == false && this.hasBeenOnline) {
        if (this.notificationDataFilled && this.notificationDataFilled.messageId) {
          this.notification.remove(this.notificationDataFilled.messageId);
        }
        this.notificationDataFilled = this.notification.template(this.template, { nzDuration: 0 });
        return;
      };
    });
    this.db.doc<{dashboardVersion: string}>('_public/common').snapshotChanges().subscribe(
      (snapshot) => {
        const data = snapshot.payload.data();
        const version = data && data.dashboardVersion;
        if (version) {
          const needToUpdate = compareVersions(this.currentVersion, version) === -1;
          this.needToUpdate$.next(needToUpdate);
        }
      }, (error) => {
        console.log('error', error)
      }
    );
  }

  refreshing = false;
  refresh() {
    this.refreshing = true;
    setTimeout(() => {
      this.zone.runOutsideAngular(async () => {
        // await this.http.get(window.location.href, {
        //   headers: {
        //     "Pragma": "no-cache",
        //     "Expires": "-1",
        //     "Cache-Control": "no-cache"
        //   }
        // }).pipe(take(1)).toPromise();
        window.location.reload();
      });
    }, 1000);
  }
}
