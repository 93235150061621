import { TicketGeneralFields } from '../../../general-fields'

export namespace TimelineItemGeneralFieldValue {

    export interface Common<T extends TicketGeneralFields.TICKET_GENERAL_FIELDS> {
        name?: T,
        value?: TicketGeneralFields.Common[T],
    }

    export interface DBObject<T extends TicketGeneralFields.TICKET_GENERAL_FIELDS> extends Common<T> {
        value?: TicketGeneralFields.DBObject[T],
    }

    export interface Object<T extends TicketGeneralFields.TICKET_GENERAL_FIELDS> extends Common<T> {
        value?: TicketGeneralFields.Object[T],
    }

    export interface DBUpdate<T extends TicketGeneralFields.TICKET_GENERAL_FIELDS> extends Common<T> {
        value?: TicketGeneralFields.DBUpdate[T],
    }

    export const helper = {
        toObject: <T extends TicketGeneralFields.TICKET_GENERAL_FIELDS>(dbObject: DBObject<T>): Object<T> => {
            var a : TicketGeneralFields.DBObject = {
                [dbObject.name as TicketGeneralFields.TICKET_GENERAL_FIELDS]: dbObject.value
            }
            return {
                name: dbObject?.name,
                value: TicketGeneralFields.helper.toObject(a)[dbObject.name as TicketGeneralFields.TICKET_GENERAL_FIELDS]
            } as Object<T>;
        }
    }
}

