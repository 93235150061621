import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FirebaseService } from './shared/services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class IsAgentOrAdminGuardService  {

  constructor(private fbs: FirebaseService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.fbs.currentUserRole().pipe(
      map((role) =>  {
        if (role?.isAgent || role?.isAdmin) return true;
        return false;
      })
    );
  }
}
