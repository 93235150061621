import { environment as prodEnvironment } from './environments/environment.prod';
import { environment as devEnvironment } from './environments/environment';
export const ATTACHMENT_UID = "efb236d5aad14e2395e489db378bb685";
export const HIDDEN_SERVICE = 'CtMo3tdLHt7S8FM6ZYOU';

export type EncodedString = string;

export enum CLOUD_STORAGE_FOLDER {
  ATTACHMENTS = "attachments",
  UPLOADS = "uploads",
  EXPENSES = "expenses"
};

export const getEnvironment = (prod: boolean) => {
  return prod ? prodEnvironment : devEnvironment;
}

export const Encoder = {
  encodeKey: (key?: string): EncodedString|null => {
    return key && encodeURIComponent(key).split('.').join('%2E') || null;
  },
  decodeKey: (key?: EncodedString): string|null => {
    return key && decodeURIComponent(key).split('%2E').join('.') || null;
  },
  arrayToOrderedObjectWithEncodedKey: (array: string[]|undefined) => {
    return (array || []).reduce((a, b, index) => {
      const refKey = Encoder.encodeKey(b);
      if (refKey) a[refKey] = index;
      return a;
    }, {} as { [key: string]: number });
  }
}


