import { dbDateUpdateTypes, dbDateTypes, allDateTypes } from "../../../../common"
import { TimelineItemTask } from ".."

export namespace TimelineItemTaskValue {

    export interface Common {
        name?: string,
        desc?: string,
        duration?: number,
        ownedBy?: string, /** To be reverted with "performedBy" */
        performedBy?: string, /** To be reverted with "ownedBy" */
        /** Deprecated. Used to keep track of the previous quantity billed as DURATION_TO_QUANTITY changed */
        quantity?: number,
        //Custom task
        start?: allDateTypes,
        rating?: number,  /** Naming will be replaced by "efficiency" */
        done?: allDateTypes,
        type?: TimelineItemTask.TICKET_TASK_TYPE,
        billed?: allDateTypes,
        invoiced?: allDateTypes,
        snoozeDate?: allDateTypes,
    }

    export interface DBObject extends Common {
        start?: dbDateTypes,
        done?: dbDateTypes,
        billed?: dbDateTypes,
        invoiced?: dbDateTypes,
        snoozeDate?: dbDateTypes,
    }

    export interface Object extends Common {
        start?: Date,
        done?: Date,
        billed?: Date,
        invoiced?: Date,
        snoozeDate?: Date,
    }

    export interface DBUpdate extends Common {
        start?: dbDateUpdateTypes,
        done?: dbDateUpdateTypes,
        billed?: dbDateUpdateTypes,
        invoiced?: dbDateUpdateTypes,
        snoozeDate?: dbDateUpdateTypes,
    }

    export const helper = {
        toObject: (dbObject: any): Object => {
            const object = {
                ...dbObject,
                start: dbObject?.start?.toDate && dbObject?.start?.toDate(),
                done: dbObject?.done?.toDate && dbObject?.done?.toDate(),
                billed: dbObject?.billed?.toDate && dbObject?.billed?.toDate(),
                invoiced: dbObject?.invoiced?.toDate && dbObject?.invoiced?.toDate(),
                snoozeDate: dbObject?.snoozeDate?.toDate && dbObject?.snoozeDate?.toDate(),
            }
            if (dbObject?.done === true) {
                const defaultDoneDate = new Date("12-01-2018");
                object.done = new Date(defaultDoneDate.setSeconds(defaultDoneDate.getSeconds() + 10));
            }
            if (dbObject?.billed === true) {
                const defaultBilledDate =  new Date("12-01-2018");
                object.billed = new Date(defaultBilledDate.setSeconds(defaultBilledDate.getSeconds() + 20));
            }
            return object;
        }
    }
}