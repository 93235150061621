import { TicketGeneralFields } from "../general-fields";
import { allDateTypes, dbDateUpdateTypes, dbDateTypes } from "../../common";
import { TicketState } from "../state";
import {Schedule} from "../../schedule/index";

export namespace PublicTicket {

    export interface Common {
        createdAt?: allDateTypes,
        updatedAt?: allDateTypes,
        [TicketGeneralFields.TICKET_GENERAL_FIELDS.TITLE]?: string,
        [TicketGeneralFields.TICKET_GENERAL_FIELDS.CLIENT]?: string,
        [TicketGeneralFields.TICKET_GENERAL_FIELDS.DEADLINE]?: allDateTypes,
        state?: TicketState.Common,
        delegate?: boolean,
        [TicketGeneralFields.TICKET_GENERAL_FIELDS.CATEGORY]?: TicketGeneralFields.TICKET_CATEGORY,
        acl?: string[]
    }

    export interface DBObject extends Common {
        createdAt?: dbDateTypes,
        updatedAt?: dbDateTypes,
        deadline?: dbDateTypes,
        state?: TicketState.DBObject,
    }

    export interface Object extends Common {
        computed_id?: string,
        createdAt?: Date,
        updatedAt?: Date,
        deadline?: Date,
        state?: TicketState.Object
    }

    export interface DBUpdate extends Common {
        createdAt?: dbDateUpdateTypes,
        deadline?: dbDateUpdateTypes,
        state?: TicketState.DBUpdate
    }

    export const helper = {
      toObject: (id: string, dbObject: DBObject): Object => {
        const state = dbObject?.state && TicketState.helper.toObject(dbObject.state);
        return {
          ...dbObject,
          computed_id: id,
          createdAt: dbObject?.createdAt?.toDate && dbObject?.createdAt?.toDate(),
          updatedAt: dbObject?.updatedAt?.toDate && dbObject?.updatedAt?.toDate(),
          deadline: dbObject?.deadline?.toDate && dbObject?.deadline?.toDate(),
          state: state,
        };
      },
    };
}