import { allDateTypes, dbDateUpdateTypes, dbDateTypes } from '../../../common';

export namespace TimelineItemCommon {

    export interface Common {
        type?: TIMELINE_ITEM_TYPE,
        value?: any,
        $value?: any, // store linked value is needed (only for message (public-tickets))
        addedAt?: allDateTypes,
        createdBy?: string | 'SYSTEM',
        hidden?: boolean,
        public?: boolean, // Will create a public timeline item id true
        status?: TIMELINE_ITEM_STATUS
        /** Mandatory to perform collection group queries on a specific ticket */
        ticketId?: string,
        /** Computed based on the operation description*/
        statusDescription?: string
        /** Set when a payment need to be updated after a client changed on a ticket */
        needToUpdate?: Date | any,
        /** Used to track operation logs */
        statusUpdatedBy?: string,
        statusUpdatedAt?: allDateTypes
    }

    export interface DBObject extends Common {
        addedAt?: dbDateTypes,
        needToUpdate?: dbDateTypes,
        value?: any,
        statusUpdatedAt?: dbDateTypes
    }

    export interface Object extends Common {
        addedAt?: Date,
        needToUpdate?: Date,
        computed_id: string,
        value?: any,
        statusUpdatedAt?: Date
    }

    export interface DBUpdate extends Common {
        addedAt?: dbDateUpdateTypes,
        needToUpdate?: dbDateUpdateTypes
        value?: any,
        statusUpdatedAt?: dbDateUpdateTypes
    }
    export const helper = {
        __toObject: (id: string, dbObject: DBObject): Object => {
            return {
                ...dbObject,
                computed_id: id,
                addedAt: dbObject?.addedAt?.toDate && dbObject?.addedAt?.toDate(),
                needToUpdate: dbObject?.needToUpdate?.toDate && dbObject?.needToUpdate?.toDate(),
                statusUpdatedAt: dbObject?.statusUpdatedAt?.toDate && dbObject?.statusUpdatedAt?.toDate()
            }
        },
    }

    export enum TIMELINE_ITEM_TYPE {
        MESSAGE = "message",
        MSG = "msg",
        FIELDS = "fields",
        COMMENT = "comment",
        TASK = "task",
        CLIENT_RATING = "client_rating",
        FORK = "fork"
    };

    export enum TIMELINE_ITEM_STATUS {

        DRAFT = "DRAFT",
        TO_VALIDATE = "TO_VALIDATE",
        TO_BE_PROCESSED = "TO_BE_PROCESSED",
        PROCESSING = "PROCESSING",
        ACCEPTED = "ACCEPTED",
        DECLINED = "DECLINED",
        REJECTED = "REJECTED",
    };

    export const SYSTEM = "SYSTEM";
}